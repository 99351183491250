@import 'settings/mixins';
@import 'settings/base';

fieldset {
    padding: 0;
    border: none;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue;
    z-index: 30;
    .svg {
        width: 500px;
        height: 200px;
        max-width: 80%;
    }
    img {
        max-width: 80%;
    }
}

.header {
    background-color: #f1f6ff;
    font-size: 14px;
    z-index: 2;
    position: relative;

    @include media_tablet {
        border-bottom: 3px solid #f1f6ff;
    }
    .address_block {
        cursor: pointer;
    }
    .phones_block {
        padding-bottom: 4px;
    }
    .msMiniCart {
        height: 45px;
        position: fixed;
        top: 90px;
        right: calc(50% - 590px);
        transition: all ease 0.3s;
        @include media_laptop {
            right: 10px;
        }
        @include media_tablet {
            position: static;
        }
        @include media_phone {
            top: 54px;
        }
    }
    &.scroll_loaded {
        .msMiniCart {
            top: 62px;
            .miniCart {
                box-shadow: 0px 0px 1px 3px rgba(255,255,255,1);
            }
        }
    }
    &_left {
        width: 77%;
        @include media_tablet {
            .email_block {
                display: none;
            }
            .phones_block {
                order: 2;
            }
        }
        @include media_phone {
            width: 77%;
            font-size: 10px;
            @supports (display: contents) {
                display: contents;
            }
            .phones_block {
                display: none;
            }
            .address_block {
                display: none;
            }
            .logo {
                width: 94px;
                height: 50px;
                &:before {
                    width: 70px;
                    height: 22px;
                }
            }
        }
    }
    &_right {
        @include media_tablet {
            order: 1;
            margin-right: 14px;
        }
        .standard_btn {
            background-color: $red;
            color: #fff;
            margin-right: 15px;
            @include media_tablet {
                margin-right: 0;
                width: 44px;
                height: 44px;
                border-radius: 50%;
                min-width: auto;
                text-indent: -9999px;
                @include bg(16px 16px);
                background-image: url(../img/phone.svg);
            }
            @include media_phone {
                width: 40px;
                height: 40px;
            }
        }
        .language {
            text-decoration: none;
            color: $blue;
            @include media_tablet {
                display: none;
            }
            &:before {
                content: '';
                margin-right: 6px;
                width: 21px;
                height: 21px;
                @include bg;
                background-image: url(../img/uk.png);
            }
        }
    }
}

.logo {
    background-color: $blue;
    height: 77px;
    width: 180px;
    @include flex;
    @include media_laptop {
        margin-left: -10px;
    }
    @include media_tablet {
        height: 64px;
    }
    &:before {
        content: '';
        display: block;
        width: 125px;
        height: 40px;
        @include bg(100%);
        background-image: url(../img/logo.svg);
        @include media_tablet {
            width: 102px;
            height: 32px;
        }
    }
}

.phones_block {
    padding-top: 12px;
    position: relative;
    @include media_tablet {
        padding-top: 4px;
    }
    .svg_icon {
        margin-top: 6px;
        fill: $blue;
        width: 12px;
        height: 12px;
        margin-right: 8px;
    }
    .phone_link {
        display: block;
        text-decoration: none;
        color: $blue;
        font-size: 18px;
        font-weight: 700;
    }
    .show_phones {
        align-self: flex-end;
        width: 10px;
        height: 10px;
        margin-left: 8px;
        position: relative;
        margin-bottom: 8px;
        cursor: pointer;
        &:before {
            content: '';
            width: 6px;
            height: 6px;
            border-right: 2px solid $blue;
            border-bottom: 2px solid $blue;
            position: absolute;
            transform: rotate(45deg);
            bottom: 3px;
            left: 2px;
        }
    }
    .phones_add {
        position: absolute;
        top: 100%;
        width: 214px !important;
        border-radius: 5px;
        background-color: #ffffff;
        overflow: hidden;
        //padding: 18px 20px !important;
        display: none;
        z-index: 5;
        &_item {
            padding: 10px 20px 0;
            font-size: 12px;
            transition: all 0.4s;
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: #e8edf6;
                margin-top: 10px;
            }
            &:first-child {
                padding-top: 18px;
            }
            &:hover {
                background-color: #f1f6ff;
            }
            .phone_link {
                font-size: 16px;
            }
        }
        .work_time {
            transition: all 0.4s;
            font-size: 14px;
            padding: 10px 20px 18px;
            &:hover {
                background-color: #f1f6ff;
            }
            .svg_icon {
                margin-top: 3px;
            }
        }
    }
}

.address_block {
    width: 186px;
    padding-top: 12px;
    @include media_tablet {
        padding-top: 4px;
    }
    .svg_icon {
        width: 12px;
        min-width: 12px;
        height: 15px;
        margin-top: 4px;
        margin-right: 10px;
    }
}

.email_block {
    padding-top: 12px;
    @include media_tablet {
        padding-top: 4px;
    }
    .svg_icon {
        margin-top: 4px;
        margin-right: 8px;
        width: 15px;
        height: 12px;
    }
}

.header_block {
    &:hover {
        .header_bottom.scroll_loaded {
            transform: translateY(0);
        }
        .header .msMiniCart {
            top: 90px;
            @include media_tablet {
                top: 72px;
            }
            @include media_phone {
                top: 54px;
            }
        }
    }
}

.header_bottom {
    background-color: #fff;
    z-index: 1;
    border: 1px solid #e8edf6;
    transition: all 0.6s;
    .msMiniCart {
        height: 45px;
        position: fixed;
        top: 90px;
        right: calc(50% - 590px);
    }
    &.scroll {
        animation: none;
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        &.animated {
            opacity: 1 !important;
        }
    }
    &.scroll_loaded {
        transform: translateY(-100%);
        .msMiniCart {
            top: 100px;
        }
    }
    @include media_tablet {
        align-items: center;
    }
    &_right {
        position: relative;
        background-color: #fff;
        @include media_tablet {
            padding: 10px 0;
        }
        @include media_phone {
            padding: 5px 0;
            height: 50px;
        }
        .square_btn {
            margin-right: 75px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 13px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 1px;
                background-color: #ffffff;
                -webkit-transition: width 0.5s;
                -o-transition: width 0.5s;
                transition: width 0.5s;
            }
            &:hover:after {
                width: 78%;
            }
            @include media_tablet {
                margin-right: 40px;
            }
            &:first-child {
                margin-right: 10px;
            }
            &:last-child {
                @include media_tablet {
                    margin-right: 200px;
                }
            }
            @include media_phone {
                display: none;
            }
        }
    }
}
.header_bottom {
    @include media_tablet {
        display: none;
    }
    .navigation {
        width: 51.5%;
        @include media_laptop {
            font-size: 14px;
        }
        &_row {
            position: relative;
            margin: 0;
            padding: 10px 0;

            &.mobile_row {
                display: none;
            }

            &:hover {
                >.navigation_block_child {
                    opacity: 1;
                    left: -18px;
                }
                >.navigation_block_link {
                    color: $red;
                }
                &:after {
                    border-color: $red !important;
                }
            }
            &.active {
                & > .navigation_block_link {
                    color: $red;
                }
                &.parent:after {
                    border-right-color: $red;
                    border-bottom-color: $red;
                }
            }
            &.parent:after {
                content: '';
                width: 6px;
                height: 6px;
                border-right: 1px solid #002062;
                border-bottom: 1px solid #002062;
                position: absolute;
                transform: translateY(-60%) rotate(45deg);
                top: 50%;
                right: -14px;
                transition: all 0.5s;
            }
        }
        &_block {
            list-style: none;
            background-color: #fff;
            &_child {
                position: absolute;
                left: -9999px;
                top: 100%;
                width: 260px;
                list-style: none;
                opacity: 0;
                border-radius: 0 0 3px 3px;
                background-color: #ffffff;
                box-shadow: 0px 0px 46px 7px rgba(0, 32, 98, 0.7);
                transition: opacity 0.4s;
                z-index: -1;
                padding: 10px 20px;
                .navigation_row {
                    padding: 8px 0;
                }
                .navigation_block_link {
                    padding: 0;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 3px;
                        left: 0;
                        width: 0;
                        height: 1px;
                        background-color: #ed1111;
                        -webkit-transition: width 0.5s;
                        -o-transition: width 0.5s;
                        transition: width 0.5s;
                    }
                }
            }
            &_link {
                display: inline-block;
                color: $blue;
                text-decoration: none;
                padding: 12px 0;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 14px;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: $red;
                    transition: width 0.5s;
                    @include media_tablet {
                        content: none;
                    }
                }
                &:hover {
                    color: $red;
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.mobile_menu {
    display: none;
    @include media_tablet {
        position: fixed;
        top: 0;
        left: 0;
        overflow: auto;

        display: block;
        width: 100%;
        max-width: 500px;
        height: 100%;
        padding-top: 67px;

        background-color: #f1f6ff;

        transform: translateX(-100%);

        transition: 0.6s;
        &.active {
            transform: translateX(0);
        }
    }

    @include media_phone {
        padding-top: 53px;
    }

    .social {
        max-width: 350px;
        &_item {
            .svg_icon {
                display: block;
                width: 30px;
                height: 30px;

                fill: #002062;
            }
        }
    }

    &_info {
        padding: 24px 10px 20px;

        &_title {
            margin-bottom: 12px;

            font-size: 14px;
            font-weight: 600;
            color: #00143e;
        }

        &_btns {
            margin-bottom: 20px;

            .square_btn {
                width: 48.3%;

                text-align: center;
            }
        }
    }

    .navigation_block {
        display: block;
        padding: 0 10px;

        list-style: none;
        background-color: #fff;

        .navigation_row {
            padding: 15px 0;

            border-bottom: 1px solid #e8edf6;

            &:last-child {
                border-bottom: 0;
            }

            &.active > .navigation_block_link {
                color: #ed1111;
                font-weight: 600;

                .svg_icon {
                    fill: #ed1111;
                }
            }

            .svg_icon {
                margin-right: 12px;
                margin-bottom: 2px;
                width: 14px;
                height: 14px;

                fill: #adbdd9;
            }

            .navigation_block_link {
                display: inline-flex;
                align-items: center;

                font-size: 14px;
                color: #00143e;
                text-decoration: none;
                text-transform: uppercase;
            }

            .open_child {
                position: relative;
                top: -3px;
                left: 8px;

                width: 15px;
                height: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                &:before {
                    content: '';
                    position: relative;
                    top: -2px;

                    width: 7px;
                    height: 7px;

                    border-right: 2px solid #00143e;
                    border-bottom: 2px solid #00143e;

                    transform: rotate(45deg);
                }
            }
        }

        &_child {
            display: none;
            width: 100% !important;
            padding-left: 26px !important;
            margin: 0;

            list-style: none;

            .navigation_row {
                border-bottom: 0;
                padding: 16px 0 0;

                .navigation_block_link {
                    text-transform: none;
                }
            }
        }
    }

    .phones_add {
        top: auto;
        bottom: 100%;
        color: #002062;
    }

    .phones_block, .address_block, .email_block {
        width: 100%;
        margin-bottom: 20px;
        padding-top: 0;

    }

    .email_block {
        .svg_icon {
            @include media_phone {
                margin-top: 2px;
            }
        }
    }

    .address_block {
        b {
            display: block;
        }
    }
}

.msMiniCart {
    height: 100%;

    @include media_tablet {
        margin-left: auto;
        margin-right: 14px;
    }
    &:hover .miniCart_block {
        right: 0;
        opacity: 1;
    }
    &_mobile {
        display: none;
        //@include media_tablet {
        //    display: block;
        //    margin-right: 10px;
        //    text-align: right;
        //    font-size: 14px;
        //}
        //@include media_tablet {
        //    background-color: #ffffff;
        //}
        //@include media_phone {
        //    font-size: 10px;
        //}
        .red {
            color: $red;
        }
    }
}

.miniCart {
    width: 44px;
    height: 44px;
    margin-right: 13px;
    border-radius: 50%;
    position: relative;
    background-color: $blue;
    cursor: pointer;
    @include media_tablet {
        margin-right: 0;
        &_block_wrapper {
            display: none;
        }
    }
    @include media_phone {
        width: 40px;
        height: 40px;
    }
    .svg_icon {
        width: 21px;
        height: 18px;
        fill: #fff;
        margin-right: 2px;
    }
    &_count {
        position: absolute;
        border-radius: 50%;
        background-color: $red;
        color: #fff;
        font-size: 14px;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        top: 0;
        right: -13px;
        @include media_tablet {
            right: -4px;

            width: 20px;
            height: 20px;

            font-size: 12px;
            line-height: 20px;
        }
    }
    &_block {
        position: absolute;
        top: 100%;
        right: 9999px;
        opacity: 0;
        transition: opacity 0.5s;
        background-color: #fff;
        padding: 12px 20px 22px;
        width: 380px;
        border-radius: 0 0 3px 3px;
        z-index: -1;
        font-size: 14px;
        box-shadow: 0 0 29px 4px rgba(0, 0, 0, 0.3);
        &_title {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
        }
        &_total {
            &_text {
                margin-right: 5px;
            }
            &_price {
                color: $red;
                font-weight: 700;
            }
            .catalog_btn {
                width: 190px;
            }
        }
    }
    &_item {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e8edf6;
        &_link {
            width: 54px;
            height: 70px;
            border-radius: 5px;
            border: 1px solid #e8edf6;
            margin-right: 15px;
            flex: 0 0 auto;
        }
        &_remove {
            flex: 0 0 auto;
            .btn_remove {
                border: none;
                height: 20px;
                width: 20px;
                border-radius: 0;
                position: relative;
                padding: 0;
                &:before, &:after {
                    content: '';
                    position: absolute;
                    top: 6px;
                    right: 0;
                    width: 14px;
                    height: 2px;
                    background-color: #abbad5;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
        &_text {
            flex: 1 0 auto;
        }
        &_title {
            font-weight: 700;
            text-decoration: none;
            color: $text_color;
        }
        &_count {
            &.jq-number {
                margin-bottom: 10px;
            }
            .jq-number__field {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                .miniCart_item_count {
                    width: 1px;
                    height: 1px;
                    position: absolute;
                    left: 5px;
                    top: 2px;
                    opacity: 0;
                }
                .buffer {
                    display: inline-block;
                }
            }
            .jq-number__spin {
                width: 14px;
                height: 14px;
                border: 1px solid #e8edf6;
                background-color: #f4f8ff;
                border-radius: 50%;
                @include inline-flex;
                margin-left: 5px;
                vertical-align: middle;
                text-align: center;
                cursor: pointer;
                &:before {
                    font-weight: 700;
                }
                &.minus:before {
                    content: '-';
                }
                &.plus:before {
                    content: '+';
                }
            }
        }
        &_price {
            font-weight: 700;
        }
        &_caption {
            margin-left: 6px;
            font-weight: 400;
        }
    }
}

.slider {
    background-color: $blue;
    overflow: hidden;
    position: relative;
    
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        opacity: 0;
        @include bg(contain, left bottom);
        background-image: url(../img/dots.png);
        opacity: 0;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        opacity: 0;
        @include bg(contain, left bottom);
        background-image: url(../img/dots.png);
        opacity: 0;
        transform: rotate(180deg);
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    &.active {
        &:before, &:after {
            animation-duration: 1s;
            animation-name: dots;
            animation-fill-mode: forwards;
            @include media_tablet {
                animation: none;
            }
        }
        .slider_item {
            &_left {
                animation-duration: 2s;
                animation-name: dots;
                animation-fill-mode: forwards;
                @include media_tablet {
                    animation: none;
                }
            }
            .circle {
                animation-duration: 1s;
                animation-name: circle;
                animation-fill-mode: forwards;
                @include media_tablet {
                    animation: none;
                }
            }
            .picture {
                animation-duration: 2s;
                animation-name: dots;
                animation-fill-mode: forwards;
                @include media_tablet {
                    animation: none;
                }
            }
        }
    }
    &_wrapper {
        position: relative;
        .owl-dots {
            @extend .container;
            position: absolute;
            text-align: center;
            left: 0;
            bottom: 10px;
            @include media_phone {
                position: static;

                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
    &_img_block {
        position: absolute;
        top: -100px;
        left: -100px;
        width: calc(100% + 130px);
        height: calc(100% + 200px);
        z-index: 10;
    }
    &_item {
        height: 460px;
        position: relative;
        @media screen and (max-width: 1024px) {
            height: 350px;
        }
        @include media_phone {
            height: 525px;
            padding: 25px 0 30px;
            flex-direction: column;
            text-align: center;
        }
        &_left {
            width: 60.2%;
            padding-left: 40px;
            opacity: 0;
            position: relative;
            z-index: 5;
            @media screen and (max-width: 1024px) {
                padding-left: 10px;
                width: 61%;
                opacity: 1;
            }
            @include media_phone {
                width: 100%;
                padding: 0;
                margin-bottom: 20px;
                @supports (display: contents) {
                    display: contents;
                    .slider_item_title, .slider_item_desc, .catalog_btn {
                        position: relative;
                        z-index: 4;
                    }
                    .catalog_btn {
                        order: 3;
                    }
                }
            }
        }
        &_title {
            color: #fff;
            font-size: 40px;
            font-weight: 700;
            line-height: 1.15;
            margin-bottom: 30px;
            @media screen and (max-width: 1024px) {
                font-size: 26px;
                margin-bottom: 24px;
            }
            @include media_phone {
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
        &_desc {
            color: #a4c2ff;
            margin-bottom: 36px;
            width: 80%;
            @media screen and (max-width: 1024px) {
                margin-bottom: 26px;
            }
            @include media_phone {
                width: 100%;
                @supports (display: contents) {
                    margin-bottom: 0;
                }
            }
        }
        .circle {
            position: absolute;
            border-radius: 50%;
            border: 200px solid #002c86;
            width: 81.6%;
            z-index: 1;
            bottom: 100%;
            right: -90%;
            @media screen and (max-width: 1024px) {
                width: 126%;
                border-width: 170px;
                bottom: auto;
                top: -112%;
                right: -86%;
            }
            @include media_phone {
                width: 500px;
                height: 500px;
                border-width: 100px;
                top: -312px;
                right: -294px;
            }
            &:before {
                content: "";
                padding-top: 100%;
                float: left;
            }
        }
        .picture {
            padding-right: 69px;
            position: relative;
            z-index: 2;
            opacity: 0;
            @media screen and (max-width: 1024px) {
                padding-right: 30px;
                width: 33%;
                opacity: 1;
            }
            @include media_phone {
                padding: 0;
                width: 162px;
                @supports (display: contents) {
                    order: 1;
                    margin: 34px 0;
                }
            }
        }
    }
}

.vantages_block {
    padding-top: 50px;
    padding-bottom: 20px;
    @include media_tablet {
        padding-top: 35px;
    }
    @include media_phone {
        padding-top: 25px;
        padding-bottom: 0;
    }
}

.vantages {
    flex-wrap: wrap;
    text-align: center;
    &_item {
        margin-bottom: 50px;
        width: 26.1%;
        &:nth-child(3n), &:last-child {
            .vantages_item_top:after {
                content: none;
            }
        }
        @include media_tablet {
            width: 33%;
        }
        @include media_phone {
            margin-bottom: 30px;
            width: 47%;
        }
        .svg_icon {
            fill: #7384a7;
            width: 52px;
            height: 45px;
            margin-right: 22px;
            display: inline-block;
            vertical-align: middle;
            transition: fill 0.4s;
            margin-bottom: 8px;
            @include media_tablet {
                margin-right: 10px;
            }
            @include media_phone {
                width: 32px;
                height: 28px;
                margin-bottom: 6px;
            }
        }
        &_number {
            font-size: 60px;
            font-family: 'Bebas Neue', sans-serif;
            font-weight: 700;
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            transition: color 0.4s;
            @include media_phone {
                font-size: 38px;
            }
        }
        &_top {
            margin-bottom: 24px;
            display: inline-block;
            padding: 0 20px;
            background-color: #fff;
            position: relative;
            width: 240px;
            &:after {
                content: '';
                position: absolute;
                height: 0;
                width: 250px;
                top: 48%;
                left: 100%;
                border-top: 1px dashed $blue;
            }
            @include media_tablet {
                width: auto;
                &:after {
                    content: none;
                }
            }
            @include media_phone {
                margin-bottom: 14px;
                padding: 0;
            }
        }
        &_title {
            font-size: 18px;
            font-weight: 700;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                font-size: 12px;
            }
        }
        &:hover {
            .svg_icon {
                fill: $red;
            }
            .vantages_item_number {
                color: $red;
            }
        }
    }
}

.prefooter {
    color: #fff;
    padding-top: 34px;
    padding-bottom: 34px;
    background-color: #002061;
    font-size: 14px;
    @include media_phone {
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .show_phones:before {
        border-color: #fff;
    }
    .phones_add {
        top: auto;
        bottom: calc(100% - 15px);
        color: $blue;
        .phone_link {
            color: $blue;
        }
        .svg_icon {
            fill: $blue;
        }
    }
    &_left {
        width: 49%;
        @include media_laptop {
            width: 100%;
        }
        @include media_tablet {
            @supports (display: contents) {
                @include flex(space-between);
            }
        }
    }
    &_top {
        padding: 0 0 35px 27px;
        border-bottom: 1px solid #11398b;
        @include media_laptop {
            padding: 0 27px 35px 27px;
            &.flex {
                justify-content: space-between;
                align-items: center;
            }
        }
        @include media_tablet {
            padding: 0;
            border-bottom: none;
            @supports (display: contents) {
                display: contents;
            }
        }
        .logo {
            padding: 0;
            width: 125px;
            height: 40px;
            margin-right: 58px;
            margin-left: 0;
            @include media_tablet {
                margin-right: 0;
            }
        }
    }
    .social {
        @include media_tablet {
            @supports (display: contents) {
                order: 3;
            }
        }
        .svg_icon {
            width: 25px;
            height: 25px;
            display: block;
            @include media_phone {
                width: 20px;
                height: 20px;
            }
        }
        &_item {
            display: block;
            margin-left: 15px;
            @include media_phone {
                margin-left: 10px;
            }
        }
        .text {
            padding-right: 8px;
            @include media_tablet {
                display: none;
            }
        }
    }
    .svg_icon {
        fill: #fff;
    }
    &_bottom {
        padding-top: 12px;
        @include media_tablet {
            padding-top: 20px;
            &.flex {
                align-items: center;
            }
            @supports (display: contents) {
                padding-top: 0;
                width: 55%;
                order: 1;
            }
        }
        @include media_phone {
            display: none;
        }
        .phone_link {
            color: #fff;
        }
        .phones_block, .address_block, .email_block {
            padding-top: 20px;
            @include media_tablet {
                padding-top: 0;
            }
        }
        .mail_link {
            color: #fff;
        }
        @include media_tablet {
            .email_block {
                display: none;
            }
            @supports (display: contents) {
                .address_block {
                    order: -1;
                }
            }
        }
    }
    &_navigation {
        width: 42.5%;
        @include media_laptop {
            display: none;
        }
        &_title {
            margin-bottom: 18px;
            width: 100%;
            font-size: 16px;
        }
        &_right {
            flex-wrap: wrap;
            width: 55%;
        }
        &_ul {
            list-style: none;
        }
        &_row {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &_link {
            color: #a4c2ff;
            text-decoration: none;
        }
    }
}

.footer {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #8997c0;
    @include media_phone {
        font-size: 10px;
        .first {
            display: none;
        }
    }
    .st_lt {
        color: #8997c0;
        text-decoration: none;
        &:before {
            content: '';
            display: inline-block;
            width: 60px;
            height: 50px;
            margin-right: 4px;
            @include bg;
            background-image: url(../img/st-lt.jpg);
            @include media_phone {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.catalog_block {
    background-color: #f1f6ff;
    padding-top: 50px;
    padding-bottom: 40px;
    @include media_tablet {
        padding-top: 34px;
        padding-bottom: 20px;
    }
    @include media_phone {
        padding-top: 25px;
    }
}

.price {
    &_wrapper {
        display: flex;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
    &__aside {
        display: flex;
        flex-direction: column;
        grid-column: 1 / 2;
        gap: 20px;
    }
    
    &__outer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        grid-column: 2 / 4;
    }
    
    &__catalog {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        
        & > * {
            margin: 0 !important;
            width: 100% !important;
        }
    }
}

.grid-sort {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    
    &__list {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 20px;
    }
    
    &__text {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        color: #00143E;
    }
    
    &__item {
        color: #00143E;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        text-decoration: none;
        transition: color 0.3s;
        
        &.active {
            color: #ED1111 !important;
        }
    }
}

.filter {
    --kordion-icon-color-hover: #002062;
    
    display: flex;
    flex-direction: column;
    border: 1px solid #E8EDF6;
    background-color: #fff;
    border-radius: 10px;
    
    &__item {
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        
        &:not(:last-child) {
            border-bottom: 1px solid #E8EDF6;
        }
        
        & .mse2_number_slider {
            width: 95%;
            height: 5px;
            
            border-radius: 0;
            border: none !important;
            
            background-color: #E8EDF6;
            
            cursor: pointer;
            
            & .ui-slider-range {
                background-color: #002062;
            }
            
            & .ui-slider-handle {
                top: -5px;
                width: 15px;
                height: 15px;
                
                border-radius: 50%;
                border: none;
                outline: none;
                
                background-color: #002062;
                
                cursor: pointer;
                transition: background-color 0,3s;
                
                &:hover {
                  background-color: #002062;
                }
            }
        }
    }
    
    .square_btn {
        display: inline-flex !important;
    }
    
    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
    
    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
    }
    
    &__icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        fill: #002062;
    }
    
    &__content {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    
    &-menu {
        display: flex;
        flex-direction: column;
        
        &__item {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        
        &__link {
            padding: 11px 10px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            color: #00143E;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            text-decoration: none;
            transition: color 0.3s, background-color 0.3s;
            
            &:hover {
                background-color: #F5F8FE;
            }
            
            &.active {
                color: #ed1111;
            }
        }
        
        &__nested {
            margin-left: 15px;
        }
    }
    
    &__line {
        width: 100%;
        height: 2px;
        background-color: #002062;
    }
    
    &__price {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-self: stretch;
        justify-content: flex-start;
        gap: 13px;
        
        & .input, & .input_label {
            margin: 0;
        }
    }
    
    &__block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    &-checkbox {
        --checkbox-bg-color: #fff;
        --checkbox-bg-color-active: #002062;
        --checkbox-bg-color-hover: #fff;
        --checkbox-icon-color: #fff;
        --checkbox-icon-size: 20px;
        --checkbox-size: 20px;
        --checkbox-stroke-color: #E8EDF6;
        --checkbox-stroke-color-active: #002062;
        
        position: relative;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        
        cursor: pointer;
        user-select: none;
        
        &:hover {
            & .checkbox__item {
                background-color: var(--checkbox-bg-color-hover);
            }
        }
        
        &__input:checked ~ &__item {
            background-color: var(--checkbox-stroke-color-active);
            border-color: var(--checkbox-stroke-color-active);
        
            &::before {
                background-color: var(--checkbox-icon-color);
            }
        }
        
        &__item {
            position: relative;
        
            width: var(--checkbox-size);
            height: var(--checkbox-size);
            display: block;
            flex-shrink: 0;
        
            background-color: var(--checkbox-bg-color);
            border: 1px solid var(--checkbox-stroke-color);
            border-radius: var(--radius-secondary);
        
            transition: border-color 0.3s, background-color 0.3s;
        
            &::before {
                content: "";
        
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
        
                width: var(--checkbox-icon-size);
                height: var(--checkbox-icon-size);
        
                mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M3.33325 8.66602L5.29281 10.6256C5.68334 11.0161 6.3165 11.0161 6.70703 10.6256L12.6666 4.66602' stroke='white' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                mask-position: center;
                mask-repeat: no-repeat;
                mask-size: contain;
            }
        }
        
        &__text {
            position: relative;
            z-index: 1;
        
            color: #00143E;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
        
            a {
                color: #00143E;
                text-decoration: underline;
                text-decoration-thickness: 1px;
                text-underline-position: under;
        
                transition: color 0.3s;
        
                &:hover {
                    color: #002062;
                }
            }
        }
    }
}

.visually-hidden {
    position: absolute;
    margin: -1px;
    
    padding: 0;
    width: 1px;
    height: 1px;
    
    border: 0;
    overflow: hidden;
    
    clip: rect(0 0 0 0);
}

.catalog {
    flex-wrap: wrap;
    @include media_tablet {
        .link_img {
            height: 200px;
        }
    }
    
    &.owl-carousel {
        .catalog_item {
            margin-bottom: 0;
            img {
                width: auto;
            }
        }
    }
    .catalog_btn {
        width: 200px;
        min-width: auto;
        @include media_laptop {
            width: calc(100% - 140px);
        }
    }
    &_item {
        border-radius: 10px;
        border: 1px solid #e8edf6;
        background-color: #fff;
        width: 32.2%;
        margin-bottom: 20px;
        padding: 20px 20px 28px;
        position: relative;
        @include media_laptop {
            display: block;
        }
        @include media_tablet {
            width: 48.7%;
        }
        @include media_phone {
            width: 100%;
            padding: 12px 20px 20px;
            margin-bottom: 10px;
            &_desc {
                font-size: 10px;
            }
        }
        .big_img {
            display: none;
        }
        &_tag {
            left: 20px;
            top: 20px;
            position: absolute;
            width: 80px;
            border-radius: 5px;
            text-align: center;
            padding: 9px;
            @include media_laptop {
                padding: 8px;
                font-size: 12px;
                width: 60px;
            }
            @include media_tablet {
                left: 10px;
                top: 10px;
            }
            @include media_phone {
                padding: 2px;
            }
            &.popular {
                background-color: #fff0ee;
                color: $red;
                font-weight: 500;
                .svg_icon {
                    fill: $red;
                    position: relative;
                    top: 3px;
                    width: 12px;
                    height: 15px;
                    display: inline;
                    margin-right: 6px;
                    @include media_tablet {
                        margin-right: 4px;
                        width: 11px;
                        height: 13px;
                    }
                }
            }
            &.new {
                background-color: #f3fff5;
                line-height: 1.2;
                color: #44b759;
                b {
                    display: block;
                }
            }
        }
        &_form {
            font-size: 14px;
            flex-direction: column;
            height: 100%;
            .link {
                padding: 20px 0;
                position: relative;
                .product_stamp {
                    width: 60px;
                    height: 60px;
                    top: -17px;
                    right: 15px;
                    transform: rotate(15deg);
                }
            }
        }
        &_title {
            display: block;
            text-decoration: none;
            color: $blue;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 16px;
            border-bottom: 1px solid #e8edf6;
            @include media_tablet {
                font-size: 18px;
                padding-bottom: 12px;
            }
            @include media_phone {
                font-size: 14px;
                padding-bottom: 10px;
            }
            &_add {
                font-size: 14px;
                text-transform: none;
                font-weight: 400;
                @include media_phone {
                    font-size: 12px;
                }
            }
        }
        &_top {
            padding-bottom: 40px;
            @include media_tablet {
                padding-bottom: 14px;
            }
        }
        &_bottom {
            border-top: 1px solid #e8edf6;
            padding-top: 18px;
        }
        &_prices {
            margin-bottom: 12px;
        }
        &_radio {
            position: relative;
            padding-left: 26px;
            color: #8997c0;
            cursor: pointer;
            @include media_tablet {
                font-size: 12px;
            }
            @include media_phone {
                font-size: 10px;
            }
            .checked {
                position: absolute;
                width: 16px;
                height: 16px;
                border-radius: 3px;
                border: 1px solid $text_color;
                left: 0;
                top: 2px;
                transition: all 0.4s;
                @include media_tablet {
                    top: -1px;
                }
                &:before {
                    content: '';
                    width: 6px;
                    height: 8px;
                    transform: rotate(45deg);
                    border-right: 2px solid #fff;
                    border-bottom: 2px solid #fff;
                    position: absolute;
                    bottom: 4px;
                    right: 4px;
                }
            }
            .input_hidden {
                opacity: 0;
                position: absolute;
                width: 2px;
                height: 2px;
                &:checked + .checked {
                    background-color: $text_color;
                }
            }
            .price {
                display: block;
                font-size: 20px;
                font-weight: 700;
                color: $blue;
                @include media_tablet {
                    font-size: 18px;
                }
            }
            .old_price {
                display: inline-block;
                position: relative;
                @include media_tablet {
                    font-size: 14px;
                }
                @include media_phone {
                    font-size: 12px;
                }
                &:before {
                    content: '';
                    background-color: #ed4530;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    top: 50%;
                }
            }
        }
        @media screen and (min-width: 1201px) {
            &.big {
                width: 66.1%;
                padding: 20px 28px 28px;
                .catalog_item_tag {
                    left: 18px;
                    top: 18px;
                }
                .big_img {
                    display: flex;
                }
                .small_img {
                    display: none;
                }
                .catalog_item_form {
                    width: 252px;
                }
                .catalog_item_title {
                    text-align: left;
                    margin-bottom: 24px;
                }
                .link {
                    width: calc(100% - 252px);
                    padding: 0 20px;
                    position: relative;
                    .product_stamp {
                        top: 10px;
                        right: 60px;
                        transform: rotate(15deg);
                    }
                }
                .catalog_item_bottom {
                    border-top: none;
                }
                .catalog_item_prices {
                    display: block;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #e8edf6;
                    margin-bottom: 20px;
                }
                .catalog_item_radio {
                    display: block;
                    margin-bottom: 20px;
                }
                .catalog_item_order {
                    flex-direction: column;
                }
                .catalog_btn {
                    margin-top: 22px;
                }
            }
        }
    }
    &_jump {
        padding: 20px 60px;
        text-decoration: none;
        color: $red;
        font-size: 24px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        @include media_laptop {
            width: 100%;
            padding: 60px;
        }
        @include media_tablet {
            font-size: 16px;
            width: 48.7%;
            padding: 20px 60px;
        }
        @include media_phone {
            width: 100%;
            padding: 50px;
        }
        &_circle {
            width: 100px;
            height: 100px;
            margin-bottom: 20px;
            border-radius: 50%;
            border: 1px solid #ffd7d7;
            transition: all 0.4s;
            @include media_tablet {
                width: 90px;
                height: 90px;
            }
            .svg_icon {
                width: 44px;
                fill: $red;
                height: 20px;
                @include media_tablet {
                    width: 39px;
                    height: 18px;
                }
            }
        }
        &_text {
            text-align: center;
        }
        &_title {
            display: inline;
            transition: all 0.4s;
            border-bottom: 2px solid rgba($red, 0.4);
        }
        &:hover {
            .catalog_jump_circle {
                border: 1px solid $red;
            }
            .catalog_jump_title {
                border-color: transparent;
            }
        }
        &:active {
            .catalog_jump_circle {
                border: 1px solid $red;
                background-color: $red;
                .svg_icon {
                    fill: #fff;
                }
            }
            .catalog_jump_title {
                border-color: transparent;
            }
        }
    }
}

.about_company {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f1f6ff;
    font-size: 18px;
    @include media_laptop {
        padding-top: 40px;
    }
    @include media_phone {
        font-size: 12px;
        padding-bottom: 15px;
    }
    &:not(.home) .text_block {
        position: relative;
        @include media_tablet {
            font-size: 14px;
        }
        @include media_phone {
            flex-wrap: wrap;
        }
        /*&:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: calc(50% - 580px);
            background-color: #fff;
            @include media_laptop {
                width: 10px;
            }
            @include media_phone {
                height: 240px;
            }
        }*/
        .about_company_left {
            background-color: #fff;
            position: relative;
            @include media_phone {
                display: block;
                width: 100%;
                height: 240px;
                margin-bottom: 15px;
            }
        }
        @include media_phone {
            .about_company_img {
                position: absolute;
                bottom: 15px;
                left: 50%;
                transform: translateX(-50%);
                height: 100%;
                width: auto;
            }
        }
    }
    &.home {
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: $blue;
        color: #fff;
        font-size: 16px;
        @include media_tablet {
            font-size: 14px;
            padding-top: 35px;
            padding-bottom: 35px;
        }
        @include media_phone {
            font-size: 10px;
        }
        .text_block {
            margin-bottom: 40px;
        }
    }
    .section_title {
        color: #fff;
        margin-bottom: 45px;
        @include media_tablet {
            margin-bottom: 35px;
        }
        @include media_phone {
            margin-bottom: 25px;
        }
    }
    &_left {
        padding: 30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background-color: #002c86;
        width: 32.2%;
        min-height: 450px;
        position: relative;
        float: left;
        margin-right: 1.5%;
        @include media_tablet {
            min-height: auto;
        }
        @include media_phone {
            display: none;
        }
    }
    &_img {
        width: 53.5%;
    }
    &_right {
        //width: 66.3%;
        display: block;
        @include media_phone {
            width: 100%;
        }
    }
}

.principles {
    text-align: center;
    color: #fff;
    flex-wrap: wrap;
    &.owl-carousel {
        .principles_item {
            width: 100%;
            margin-bottom: 0;
            margin-right: 0;
        }
    }
    &_item {
        margin-bottom: 20px;
        width: 32%;
        margin-right: 2%;
        border-radius: 5px;
        padding: 20px 0;
        @include media_phone {
            padding: 14px 0;
            width: 100%;
            margin-right: 0;
        }
        &.purple {
            background-color: #678bd4;
            .counter {
                background-color: #678bd4;
            }
            .principles_item_counter:after {
                background-color: #5779be;
            }
        }
        &.green {
            background-color: #78bcc4;
            .counter {
                background-color: #78bcc4;
            }
            .principles_item_counter:after {
                background-color: #67a5ac;
            }
        }
        &.blue {
            background-color: #00c2f6;
            margin-right: 0;
            .counter {
                background-color: #00c2f6;
            }
            .principles_item_counter:after {
                background-color: #00a7d4;
            }
        }
        &_text {
            padding: 0 50px;
            @include media_tablet {
                padding: 0 20px;
            }
            @include media_phone {
                font-size: 12px;
            }
        }
        &_counter {
            margin-bottom: 12px;
            position: relative;
            line-height: 1;
            &:before, &:after {
                content: '';
                width: 50%;
                height: 1px;
                bottom: 50%;
                position: absolute;
            }
            &:before {
                left: 0;
                background-color: #fff;
            }
            &:after {
                right: 0;
            }
            .counter {
                font-size: 40px;
                font-weight: 700;
                display: inline-block;
                padding: 0 10px;
                position: relative;
                z-index: 1;
                @include media_tablet {
                    font-size: 34px;
                }
                @include media_phone {
                    font-size: 30px;
                }
            }
        }
    }
}

.owl-dots {
    text-align: center;
    padding-top: 25px;
    @include media_phone {
        padding-top: 20px;
    }
    button.owl-dot {
        width: 10px;
        height: 10px;
        background-color: #ccdcf8;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        @include media_phone {
            width: 8px;
            height: 8px;
            margin-right: 8px;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            background-color: $red;
        }
    }
}

.our_partners_block {
    padding-top: 50px;
    padding-bottom: 60px;
    background-color: #f1f6ff;
    @include media_tablet {
        padding-top: 35px;
        padding-bottom: 40px;
    }
    @include media_phone {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.our_partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 100px;
    position: relative;
    @include media_tablet {
        padding: 0;
    }
    &_item {
        height: 122px;
        padding: 0 20px;
        background-color: #fff;
        @include media_phone {
            height: 80px;
        }
    }
    &_img {
        width: auto !important;
        max-width: 100%;
        transition: all ease 0.4s;
    }
    .owl-nav, .owl-nav.disabled {
        display: flex;
        position: absolute;
        width: 100%;
        height: 0;
        left: 0;
        top: 0;
        @include media_tablet {
            display: none;
        }
        .owl-prev, .owl-next {
            position: absolute;
            top: 0;
            height: 122px;
        }
        .owl-prev {
            left: 0;
        }
        .owl-next {
            right: 0;
        }
    }
}

.owl-nav {
    display: flex;
    justify-content: space-between;
    .owl-prev, .owl-next {
        width: 80px;
        height: 80px;
        border-radius: 3px;
        background-color: #fff !important;
        @include inline-flex;
        transition: all 0.4s;
        @include media_tablet {
            width: 44px;
            height: 44px;
        }
        .svg_icon {
            width: 30px;
            height: 14px;
            fill: #bdc9de;
            transition: all 0.4s;
            @include media_tablet {
                width: 22px;
                height: 10px;
            }
        }
        &:hover, &:focus {
            background-color: $red !important;
            .svg_icon {
                fill: #fff;
            }
        }
        &:focus {
            outline: none;
        }
    }
    .owl-prev {
        .svg_icon {
            transform: rotate(180deg);
        }
    }
}

.articles_main {
    padding-top: 50px;
    padding-bottom: 60px;
    @include media_tablet {
        padding-top: 35px;
        padding-bottom: 40px;
    }
    @include media_phone {
        padding-top: 25px;
        padding-bottom: 20px;
    }
    .section_title {
        margin-bottom: 0;
        @include media_phone {
            margin-bottom: 16px;
        }
    }
    .articles_item {
    }
    &_all {
        text-align: right;
        margin-bottom: 22px;
        @include media_tablet {
            margin-bottom: 16px;
        }
        @include media_phone {
            display: none;
        }
        &.mob {
            display: none;
            @include media_phone {
                display: block;
                margin: 0;
                .link {
                    top: auto;
                    margin: 0 auto;
                    display: block;
                    text-align: center;
                    font-size: 16px;
                    padding: 20px 0;
                }
            }
        }
        .link {
            position: relative;
            top: -25px;
            @include media_tablet {
                top: -20px;
            }
        }
    }
    &.promo_page {
        .articles_item {
            &:nth-child(3n) {
                margin-right: 2%;
                @include media_tablet {
                    margin-right: 0;
                }
                @include media_phone {
                    margin-right: 2%;
                }
            }
        }
    }
    .articles_item {
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
}

.articles {
    flex-wrap: wrap;
    &_item {
        flex-direction: column;
        width: 23.5%;
        margin-right: 2%;
        border-radius: 5px;
        border: 1px solid #dae3f4;
        margin-bottom: 20px;
        background-color: #fff;
        &:nth-child(4n) {
            margin-right: 0;
        }
        @include media_tablet {
            width: 31.5%;
            margin-right: 2.75%;
            &:nth-child(4n) {
                margin-right: 2.75%;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        @include media_phone {
            font-size: 10px;
            width: 49%;
            margin-right: 2%;
            margin-bottom: 10px;
            &:nth-child(3n) {
                margin-right: 2%;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        &.big {
            @include media_tablet {
                .small_img {
                    display: block;
                }
                .big_img {
                    display: none;
                }
            }
            @media screen and (min-width: 1024px) {
                width: 49%;
                position: relative;
                padding-left: 258px;
                .small_img {
                    display: none;
                }
                .articles_item_img_link {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 258px;
                    overflow: hidden;
                    border-radius: 5px 0 0 5px;
                }
                .articles_item_img {
                    border-radius: 0;
                    height: 100%;
                    min-width: 100%;
                    width: auto;
                }
                .articles_item_text {
                    padding: 24px 28px;
                }
                .articles_item_title {
                    margin-bottom: 22px;
                }
            }
        }
        &_img {
            display: block;
            border-radius: 5px 5px 0 0;
            width: 100%;
        }
        &_text {
            padding: 18px 20px;
            flex: 1 0 auto;
            @include media_phone {
                padding: 10px;
            }
        }
        &_title {
            color: $text_color;
            font-size: 18px;
            font-weight: 700;
            text-decoration: none;
            line-height: 1.3;
            margin-bottom: 12px;
            display: inline-block;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                font-size: 12px;
            }
        }
        &_desc {
            font-size: 14px;
            &.big {
                margin-top: 20px;
                @include media_tablet {
                    display: none;
                }
            }
            @include media_phone {
                font-size: 10px;
            }
        }
        &_bottom {
            border-radius: 0 0 5px 5px;
            background-color: #f1f6ff;
            padding: 12px 20px;
            @include media_phone {
                padding: 10px;
            }
        }
        &_date {
            font-size: 14px;
            color: #8997c0;
            @include media_tablet {
                font-size: 12px;
            }
            @include media_phone {
                font-size: 10px;
            }
            .svg_icon {
                height: 12px;
                width: 12px;
                margin-right: 6px;
                fill: #8997c0;
            }
        }
    }
}

.contacts_block {
    position: relative;
    padding-top: 55px;
    padding-bottom: 72px;
    background-color: #f1f6ff;
    @include media_tablet {
        padding-top: 24px;
        padding-bottom: 32px;
    }
    @include media_phone {
        padding-bottom: 215px;
    }
    .map {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 65%;
        @include media_tablet {
            width: 57%;
        }
        @include media_phone {
            height: 370px;
            top: auto;
            bottom: 0;
            width: 100%;
        }
        .cssload-thecube {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: 0;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &.loaded {
            .cssload-thecube .cssload-cube:before {
                animation: none;
            }
        }
    }
}

.contacts {
    position: relative;
    z-index: 1;
    width: 480px;
    @include media_tablet {
        width: 400px;
    }
    @include media_phone {
        width: 100%;
    }
    .text {
        margin-bottom: 8px;
    }
    &_cities {
        margin-bottom: 25px;
        position: relative;
        @include media_tablet {
            margin-bottom: 10px;
        }
        &_choice {
            background-color: #fff;
            border-radius: 5px;
            cursor: pointer;
            padding: 20px 18px 20px 25px;
            z-index: 1;
            position: relative;
            @include media_phone {
                padding: 12px 15px;
            }
            &.active {
                border-radius: 5px 5px 0 0;
            }
            .triangle {
                width: 8px;
                height: 12px;
                position: relative;
                &:before {
                    content: '';
                    width: 9px;
                    height: 9px;
                    border-radius: 2px;
                    border-top: 3px solid $text_color;
                    border-right: 3px solid $text_color;
                    transform: rotate(45deg);
                    position: absolute;
                    right: 1px;
                    top: 1px;
                }
            }
        }
        &_block {
            border-radius: 0 0 5px 5px;
            background-color: #fff;
            padding: 20px 25px !important;
            position: absolute;
            width: 100% !important;
            left: 0;
            top: 100%;
            display: none;
            box-shadow: 0px 0px 46px 10px rgba(0, 32, 98, 0.2);
            &_item {
                margin-bottom: 20px;
                cursor: pointer;
                &:last-child {
                    margin-bottom: 0;
                }
                &.active {
                    color: $red;
                }
                b {
                    font-weight: 400;
                }
            }
        }
    }
    &_tab {
        display: none;
        width: 100%;
        padding: 25px;
        background-color: #fff;
        border-radius: 5px;
        @include media_tablet {
            padding: 18px 14px;
        }
        @include media_phone {
            padding: 15px;
        }
        &.active {
            display: block;
        }
        &_top {
            margin-bottom: 25px;
            border-bottom: 1px solid #f1f6ff;
            flex-wrap: wrap;
        }
        &_item {
            width: 45%;
            margin-right: 10%;
            margin-bottom: 20px;
            &:nth-child(2n + 1) {
                margin-right: 0;
            }
            &.address {
                flex-basis: 100%;
                margin-right: 0;
            }
            &_title {
                margin-bottom: 6px;
                font-weight: 700;
                .svg_icon {
                    margin-bottom: 2px;
                    fill: $text_color;
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
            }
            .text {
                margin-bottom: 0;
                b, strong {
                    font-weight: 400;
                }
            }
            .phone_link {
                color: $text_color;
                text-decoration: none;
                font-size: 20px;
                @include media_tablet {
                    font-size: 14px;
                }
            }
        }
        .schema {
            color: #00bff3;
            .svg_icon {
                fill: #00bff3;
                margin-right: 10px;
                width: 14px;
                height: 14px;
            }
        }
    }
    &_carousel {
        &_bottom {
            border-radius: 0 0 5px 5px;
            background-color: #f1f6ff;
            padding: 10px 15px;
            font-size: 14px;
            @include media_phone {
                font-size: 10px;
            }
            .svg_icon {
                fill: $text_color;
                width: 20px;
                height: 10px;
            }
            .nav_btn {
                border: 0;
                padding: 0;
                height: auto;
                margin-top: 2px;
                @include media_phone {
                    margin-top: 5px;
                }
                &:focus {
                    outline: none;
                }
            }
            .prev {
                margin-left: 15px;
                .svg_icon {
                    transform: rotate(180deg);
                }
            }
            .next {
                margin-left: 10px;
            }
        }
    }
}

.page_header {
    height: 166px;
    flex-direction: column;
    background-color: $blue;
    position: relative;
    overflow: hidden;
    z-index: 2;
    @include media_tablet {
        height: 98px;
    }
    @include media_phone {
        height: 66px;
    }
    &:before {
        content: '';
        position: absolute;
        width: 963px;
        height: 963px;
        border: 288px solid #002c86;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100px;
        border-radius: 50%;
        @include media_phone {
            border: none;
            width: 260px;
            height: 260px;
            background-color: #002c86;
            bottom: -15px;
        }
    }
    .h1 {
        color: #fff;
        position: relative;
        z-index: 1;
        text-align: center;
    }
    .breadcrumb {
        list-style: none;
        margin-bottom: 12px;
        @include flex;
        flex-wrap: wrap;
        font-size: 14px;
        position: relative;
        z-index: 1;
        @include media_tablet {
            font-size: 12px;
        }
        @include media_phone {
            font-size: 10px;
            margin-bottom: 8px;
        }
        &-item {
            color: #3b5894;
            line-height: 1;
            a {
                color: #fff;
                margin-bottom: 1px;
                text-decoration: none;
                display: inline-block;
                border-bottom: 1px solid rgba(#fff, 0.3);
            }
        }
        .separator {
            margin: 0 5px;
            width: 4px;
            height: 6px;
            position: relative;
            &:before {
                content: '';
                width: 4px;
                height: 4px;
                border-top: 1px solid $red;
                border-right: 1px solid $red;
                transform: rotate(45deg);
                position: absolute;
                top: 1px;
                right: 1px;
            }
        }
    }
}
.catalog_categories {
    padding: 80px 0;
    background-color: #F1F6FE;
    @include media_phone {
        padding: 40px 0;
    }
    &_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        &.catalog_categories_odd {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: unset;
            column-gap: 10px;
            row-gap: 20px;
            @include media_tablet {
                display: flex;
            }
            .catalog_category {
                &_item {
                    width: 100%;
                    &:nth-child(2) {
                        grid-column-start: 2;
                        grid-column-end: 3;
                        grid-row-start: 1;
                        grid-row-end: 3;
                        flex-direction: column;
                        @include media_tablet {
                            flex-direction: row;
                        }
                        @media only screen and (max-width: 500px) {
                            flex-direction: column;
                        }
                        .catalog_category_info {
                            width: 100%;
                            @include media_tablet {
                                width: calc(100% - 160px);
                            }
                            @media only screen and (max-width: 500px) {
                                width: 100%;
                            }
                        }
                        .catalog_category_image {
                            width: 50%;
                            align-self: center;
                            @include media_tablet {
                                width: 130px;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
.catalog_category {
    &_item {
        width: 49%;
        display: flex;
        justify-content: space-between;
        padding: 30px 35px;
        background-color: #ffffff;
        border-radius: 10px;
        border: 1px solid #E8EDF5;
        @include media_tablet {
            width: 100%;
        }
        @media only screen and (max-width: 500px) {
            flex-direction: column;
        }
    }
    &_image {
        width: 130px;
        flex-shrink: 0;
        @media only screen and (max-width: 500px) {
            align-self: center;
            margin-bottom: 20px;
        }
    }
    &_info {
        width: calc(100% - 160px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media only screen and (max-width: 500px) {
            width: 100%;
        }
    }
    &_title {
        font-weight: bold;
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: 10px;
        line-height: 1.3;
        width: 90%;
        @media only screen and (max-width: 500px) {
            width: 100%;
            font-size: 20px;
        }
    }
    &_desc {
        font-size: 14px;
        margin-bottom: 15px;
    }
    &_link {
        font-size: 14px;
        color: #ED1112;
        text-underline-offset: 2px;
    }
}
.page_content {
    padding-top: 50px;
    padding-bottom: 60px;
    @include media_tablet {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    @include media_phone {
        padding-top: 30px;
        padding-bottom: 40px;
    }
}


.page_content {
    &_title {
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 35px;
    }
    &_subtitle {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    &_columns {
        display: flex;
        justify-content: space-between;
        @include media_phone {
            flex-wrap: wrap;
        }
    }
    &_two_columns {
        & > *{
            width: 49%;
        }
        &.big_right {
            & > *:first-child {
                width: 300px;
                @include media_tablet {
                    width: 260px;
                }
                @include media_phone {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
            & > *:last-child {
                width: calc(100% - 400px);
                @include media_tablet {
                    width: calc(100% - 300px);
                }
                @include media_phone {
                    width: 100%;
                }
            }
        }
    }
    
}
.check_list {
    list-style-type: none;
    li {
        position: relative;
        padding-left: 30px;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 17px;
        @include media_tablet {
            font-size: 16px;
        }
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 22px;
            height: 22px;
            background-image: url(../img/checked_1.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    &_info {
        font-size: 18px;
        @include media_tablet {
            font-size: 14px;
        }
        p {
            margin-bottom: 10px;
        }
    }
}

.block_underlined {
    padding-bottom: 70px;
    border-bottom: 1px solid #F1F6FF;
    margin-bottom: 30px;
}
.table_wrapper {
    overflow: auto;
}
.styled_table {
    float: none;
    width: 100%;
    th {
        background-color: #F1F6FF;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #F1F6FF;
        padding: 25px;
        &:not(:last-child) {
            border-right: 1px solid #ffffff;
        }
    }
    td {
        border: 1px solid #F1F6FF;
        text-align: center;
        p {
            margin: 0;
        }
        img {
            height: 72px;
        }
        &.flex_col {
            display: flex;
        }
        &.flex_ac {
            align-items: center;
        }
        &.tal {
            text-align: left;
        }
    }
    .maxw {
        max-width: 452px;
    }
}




.pagination {
    padding-top: 10px;
    color: #8997c0;
    font-size: 14px;
    &_ul {
        list-style: none;
        .page-item {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            @include media_phone {
                margin-right: 5px;
            }
            &.control, &.disabled {
                margin-right: 15px;
                @include media_phone {
                    margin-right: 10px;
                }
                a, .control_link {
                    border: 1px solid #dae3f4;
                }
            }
            &.active {
                a {
                    background-color: #002061;
                    color: #fff;
                }
            }
            &:last-child {
                margin-right: 0;
                margin-left: 5px;
            }
            a, .control_link {
                @include flex;
                width: 28px;
                height: 28px;
                border-radius: 5px;
                background-color: #f1f6ff;
                text-decoration: none;
                color: #8997c0;
            }
            .control_link {
                &:before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid #8997c0;
                    border-right: 1px solid #8997c0;
                }
                &.next {
                    padding-right: 2px;
                    &:before {
                        transform: rotate(45deg);
                    }
                }
                &.prev {
                    padding-left: 2px;
                    &:before {
                        transform: rotate(-135deg);
                    }
                }
            }
        }
    }
}

.popup_screen {
    background-color: rgba(#000b22, 0.75);
    position: fixed;
    left: -9999px;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    transition: opacity 0.5s;
    opacity: 0;
    &.active {
        opacity: 1;
        left: 0;
    }
    &.thanks {
        text-align: center;
        .thanks_img {
            width: 70px;
            height: 70px;
            background-color: #1ea86c;
            margin-bottom: 14px;
            display: inline-block;
            border-radius: 50%;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 24px;
                height: 34px;
                transform: rotate(45deg) translateX(-50%);
                top: 23px;
                left: 47%;
                border-right: 8px solid #fff;
                border-bottom: 8px solid #fff;
            }
        }
        .popup {
            padding: 30px 60px;
            &_title {
                margin-bottom: 12px;
            }
        }
    }
    .popup {
        border-radius: 5px;
        background-color: #f1f6ff;
        padding: 24px 30px 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        max-width: 93%;
        @include media_phone {
            padding: 15px 10px;
        }
        &_close {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            &:before, &:after {
                content: '';
                position: absolute;
                top: 6px;
                left: -2px;
                width: 18px;
                height: 2px;
                background-color: #abbad5;
            }
            &:before {
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}

.popup {
    &_form {
        width: 320px;
        max-width: 100%;
        @include media_phone {
            font-size: 10px;
        }
        &_text {
            text-align: center;
            font-size: 14px;
            @include media_phone {
                font-size: 10px;
            }
        }
    }
    &_title {
        font-size: 30px;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 20px;
        text-align: center;
        @include media_phone {
            font-size: 18px;
            margin-bottom: 14px;
        }
    }
    .catalog_btn {
        width: 100%;
        @include media_phone {
            font-size: 12px;
        }
    }
    .catalog_btn_blue {
        &:before {
            background-color: darken(#002062, 20%);
        }
        .catalog_btn_text {
            background-color: #002062;
        }
    }
    .catalog_btn_phone {
        margin-bottom: 0;
        margin-top: 20px;
        display: none;
        @include media_phone {
            display: block;
        }
    }
}

.principles_block {
    padding-top: 35px;
    padding-bottom: 40px;
    @include media_tablet {
        padding-bottom: 20px;
    }
    @include media_phone {
        padding-top: 25px;
    }
    .h2 {
        margin-bottom: 20px;
        text-align: center;
    }
    .text {
        margin-bottom: 30px;
        padding: 0 200px;
        @include media_tablet {
            padding: 0 120px;
        }
        @include media_phone {
            padding: 0;
        }
    }
}

.employees_block {
    padding-top: 50px;
    padding-bottom: 55px;
    background-color: $blue;
    @include media_tablet {
        padding-top: 35px;
        padding-bottom: 40px;
    }
    @include media_phone {
        padding-top: 15px;
        padding-bottom: 20px;
    }
    .h2 {
        text-align: center;
        margin-bottom: 30px;
        color: #fff;
        @include media_phone {
            margin-bottom: 15px;
        }
    }
}

.employees {
    flex-wrap: wrap;
    color: #fefeff;
    @include media_tablet {
        &.owl-carousel {
            .employees_item {
                width: 100%;
                min-height: 420px;
                margin-bottom: 0;
                margin-right: 0;
                &_img {
                    width: auto;
                }
            }
        }
    }
    &_item {
        width: 18.8%;
        min-height: 490px;
        margin-right: 1.5%;
        margin-bottom: 20px;
        border-radius: 3px 20px;
        background-color: #002c86;
        &:nth-child(5n) {
            margin-right: 0;
        }
        @include media_tablet {
            width: 32%;
            margin-right: 2%;
            &:nth-child(5n) {
                margin-right: 2%;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        @include media_phone {
            width: 49%;
            &:nth-child(3n) {
                margin-right: 2%;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        &_img {
            height: 100%;
            display: block;
            max-width: none;
            &_block {
                background-color: #fff;
                border-radius: 3px 20px;
                overflow: hidden;
            }
        }
        &_text {
            padding: 15px 15px 25px;
            @include media_phone {
                padding: 12px 10px;
            }
        }
        &_fio {
            font-weight: 700;
            margin-bottom: 14px;
            @include media_phone {
                margin-bottom: 8px;
            }
        }
        &_contact {
            color: #fefeff;
            text-decoration: none;
            font-size: 14px;
            margin-bottom: 4px;
            &:last-child {
                margin-bottom: 0;
            }
            @include media_phone {
                font-size: 10px;
            }
            .svg_icon {
                width: 12px;
                height: 12px;
                margin-right: 8px;
                fill: $red;
            }
        }
    }
}

.certificate {
    padding-top: 44px;
    padding-bottom: 60px;
    background-color: #f1f6ff;
    @include media_tablet {
        padding-top: 35px;
        padding-bottom: 50px;
    }
    @include media_phone {
        padding-top: 25px;
        padding-bottom: 40px;
    }
    .h2 {
        margin-bottom: 34px;
        @include media_phone {
            margin-bottom: 18px;
        }
    }
}

.gallery {
    position: relative;
    &_item {
        display: block;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: rgba(255, 255, 255, 0.8);
            transition: opacity 0.5s;
        }
        &:after {
            content: '';
            width: 45px;
            height: 45px;
            @include bg;
            background-image: url(../img/zoom.svg);
            position: absolute;
            opacity: 0;
            transition: opacity 0.5s;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &:hover:before, &:hover:after {
            opacity: 1;
        }
        &_img {
            border-radius: 5px;
            display: block;
        }
    }
    .owl-nav {
        position: absolute;
        height: 0;
        width: 100%;
        top: 50%;
        left: 0;
        .owl-prev, .owl-next {
            top: 0;
            transform: translateY(-50%);
        }
        .owl-prev {
            left: 0;
        }
        .owl-next {
            right: 0;
        }
    }
}

.certificate {
    .gallery_item {
        border: 10px solid #fff;
        border-radius: 3px;
        &_img {
            border-radius: 3px;
        }
    }
}

.delivery_block {
    //padding: 0 calc(50% - 410px);
    padding-top: 50px;
    padding-bottom: 130px;
    @media screen and (max-width: 840px) {
        padding: 40px 10px;
    }
    @include media_phone {
        padding: 20px 10px 30px;
    }
}

.delivery {
    flex-wrap: wrap;
    position: relative;
    transition: height 0.4s;
    &.active {
        .delivery_item {
            opacity: 0;
            &.active {
                transition: all 0.5s, opacity 0.2s;
                opacity: 1;
                background-color: #fff;
                z-index: 1;
                position: absolute;
                .delivery_item_btn {
                    color: $red;
                    .svg_icon {
                        fill: $red;
                        transform: rotate(180deg);
                    }
                    .circle {
                        border-color: $red;
                    }
                }
                .delivery_item_desc {
                    opacity: 1;
                }
                .delivery_item_img {
                    opacity: 0;
                }
            }
        }
    }
    &.loaded {
        .delivery_item:not(.active) {
            display: none;
        }
    }
    &_item {
        width: 32.5%;
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid #e8edf6;
        background-color: #f1f6ff;
        position: relative;
        overflow: hidden;
        padding: 36px 30px 30px;
        flex-direction: column;
        @include media_tablet {
            padding: 25px 20px 20px;
        }
        @include media_phone {
            padding: 20px;
            width: 100%;
            margin-bottom: 10px;
        }
        &:hover {
            background-color: #fff;
            .delivery_item_img {
                opacity: 0;
            }
            .delivery_item_desc {
                opacity: 1;
            }
            .delivery_item_btn {
                color: $red;
                .svg_icon {
                    fill: $red;
                }
                .circle {
                    border-color: $red;
                }
            }
        }
        &_title {
            font-size: 18px;
            margin-bottom: 14px;
            font-weight: 700;
            padding-right: 100px;
            flex: 0 0 auto;
            transition: opacity 0.5s;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                font-size: 12px;
            }
        }
        &_desc {
            margin-bottom: 20px;
            opacity: 0;
            flex: 1 0 auto;
            position: relative;
            z-index: 1;
            transition: opacity 0.5s;
            @include media_tablet {
                margin-bottom: 16px;
            }
            @include media_phone {
                margin-bottom: 14px;
            }
        }
        &_text {
            display: none;
            width: 100% !important;
            margin-bottom: 20px;
        }
        &_img {
            position: absolute;
            right: 0;
            bottom: 0;
            max-height: 100%;
            max-width: 100%;
            transition: opacity 0.5s;
            @include media_tablet {
                max-width: 160px;
            }
            @include media_phone {
                max-width: 120px;
            }
        }
        &_btn {
            flex: 0 0 auto;
            cursor: pointer;
            transition: all 0.5s;
            .circle {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                margin-right: 16px;
                border: 1px solid $text_color;
                transition: all 0.5s;
                @include media_phone {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
                .svg_icon {
                    width: 15px;
                    height: 7px;
                    fill: $text_color;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.article {
    @include media_phone {
        flex-wrap: wrap;
    }
    &_img {
        display: block;
        border-radius: 5px;
        border: 1px solid #dae3f4;
        width: 32.2%;
        margin-right: 4.2%;
        margin-bottom: 16px;
        float: left;
        @include media_tablet {
            width: 31.7%;
            margin-right: 2.7%;
        }
        @include media_phone {
            width: 100%;
            margin-bottom: 14px;
        }
    }
    &_text {
        padding-top: 20px;
        font-size: 14px;
        //width: 63.6%;
        @include media_tablet {
            //width: 65.6%;
            padding-top: 6px;
        }
        @include media_phone {
            width: 100%;
            font-size: 10px;
        }
    }
    .articles_item_date {
        margin-bottom: 20px;
        @include media_tablet {
            margin-bottom: 12px;
        }
        @include media_phone {
            margin-bottom: 10px;
            font-size: 12px;
        }
    }
    &_title {
        margin-bottom: 15px;
        font-size: 18px;
        @include media_tablet {
            margin-bottom: 12px;
        }
        @include media_phone {
            margin-bottom: 10px;
            font-size: 12px;
        }
    }
}

.model_block {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #f1f6ff;
    @include media_tablet {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @include media_phone {
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .warning {
        background-color: #f1f6ff;
        &_add {
            background-color: #f1f6ff;
        }
    }
}

.models {
    flex-wrap: wrap;
    padding-bottom: 20px;
    &_item {
        margin-bottom: 20px;
        width: 32.2%;
        padding: 23px 40px 23px 25px;
        background-color: #fff;
        border-radius: 5px;
        text-decoration: none;
        @include media_tablet {
            width: 48.6%;
            font-size: 16px;
            &:last-child {
                width: 100%;
            }
        }
        @include media_phone {
            width: 100%;
            margin-bottom: 5px;
            font-size: 12px;
            padding: 14px;
        }
        &:hover {
            .svg_icon {
                stroke: $red;
            }
            .text {
                color: $red;
                border-color: $red;
            }
        }
        &:focus, &:active {
            .svg_icon {
                stroke: $red;
            }
            .text {
                color: $red;
                border-color: transparent;
            }
        }
        &_img {
            width: 52px;
            min-width: 52px;
            height: 64px;
            margin-right: 25px;
            @include media_phone {
                height: 40px;
                width: 32px;
                min-width: 32px;
                margin-right: 14px;
            }
        }
        .svg_icon {
            width: 100%;
            height: 100%;
            fill: none;
            stroke: $blue;
            transition: all 0.4s;
        }
        &_title {
            .text {
                color: $text_color;
                border-bottom: 1px dashed $text_color;
                transition: all 0.4s;
            }
            b {
                text-transform: uppercase;
            }
        }
    }
}

.model_left {
    margin-bottom: 46px;
    @include media_phone {
        flex-wrap: wrap;
        margin-bottom: 22px;
    }
    &_img {
        display: block;
        border-radius: 5px;
        width: 100%;
        &_block {
            width: 37.3%;
            @include media_tablet {
                width: 31.5%;
            }
            @include media_phone {
                width: 100%;
                margin-bottom: 12px;
            }
        }
    }
    &_text {
        width: 60%;
        @include media_tablet {
            width: 66%;
        }
        @include media_phone {
            width: 100%;
        }
    }
    &_title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 12px;
        width: 420px;
        max-width: 100%;
        @include media_tablet {
            font-size: 14px;
        }
    }
}

.number_block {
    counter-reset: number_block;
    @include media_phone {
        font-size: 10px;
    }
    &_item {
        position: relative;
        padding-left: 40px;
        counter-increment: number_block;
        margin-bottom: 28px;
        @include media_tablet {
            padding-left: 30px;
            margin-bottom: 18px;
        }
        @include media_phone {
            margin-bottom: 12px;
            padding-left: 20px;
        }
        &:before {
            font-family: 'Bebas Neue', sans-serif;
            content: '0' counter(number_block) '.';
            position: absolute;
            font-size: 26px;
            font-weight: 700;
            left: 0;
            top: -4px;
            @include media_tablet {
                font-size: 22px;
            }
            @include media_phone {
                font-size: 16px;
                top: -2px;
            }
        }
    }
    &_add {
        border-radius: 3px;
        text-align: center;
        background-color: #fff;
        padding: 20px;
        border: 1px solid #e8edf6;
        cursor: pointer;
        width: 100% !important;
        @include media_tablet {
            padding: 12px;
        }
        &_text {
            font-size: 18px;
            font-weight: 700;
            display: inline;
            border-bottom: 1px dashed $text_color;
            @include media_tablet {
                font-size: 14px;
            }
            @include media_phone {
                font-size: 12px;
            }
        }
    }
    &_hide {
        display: none;
        width: 100% !important;
    }
}

.design_container {
    padding: 44px calc(50% - 430px) 60px;
    background-color: $blue;
    @include bg(cover, top center);
    background-image: url(../img/design_bg.png);
    @media screen and (max-width: 880px) {
        padding: 35px 10px 50px;
    }
    @include media_phone {
        padding: 16px 10px 40px;
    }
    .section_title {
        margin-bottom: 45px;
        color: #fff;
        @include media_tablet {
            margin-bottom: 30px;
        }
        @include media_phone {
            margin-bottom: 16px;
        }
    }
}

.design {
    &_title {
        padding: 20px;
        width: 50%;
        text-align: center;
        background-color: rgba(#446fc4, 0.4);
        color: #fff;
        cursor: pointer;
        font-size: 18px;
        transition: all 0.3s;
        &.active {
            font-weight: 700;
            background-color: #fff;
            color: $text_color;
        }
        &:first-child {
            border-radius: 5px 0 0;
        }
        &:last-child {
            border-radius: 0 5px 0 0;
        }
        @include media_tablet {
            padding: 14px;
            font-size: 16px;
        }
        @include media_phone {
            font-size: 12px;
        }
    }
    &_block {
        padding: 40px;
        background-color: #fff;
        border-radius: 0 0 5px 5px;
        transition: height 0.3s;
        @include media_tablet {
            padding: 20px;
        }
        @include media_phone {
            padding: 15px 10px;
            td:first-child, th:first-child {
                display: none;
            }
            td:nth-child(2n), th:nth-child(2n) {
                border-left: 1px solid #e8edf6;
                text-align: left;
            }
        }
        &_item {
            display: none;
            opacity: 0;
            transition: opacity 0.6s;
            &.active {
                opacity: 1;
            }
        }
    }
    td:last-child {
        width: 170px;
        font-size: 16px;
        @include media_phone {
            font-size: 10px;
            width: 70px;
        }
    }
}

.portfolio {
    &_content {
        margin-bottom: 25px;
    }
    &_filter {
        margin-bottom: 20px;
    }
}

.select {
    position: relative;
    width: 354px;
    max-width: 100%;
    &_title {
        height: 44px;
        width: 100%;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #dae3f4;
        padding: 0 20px;
        cursor: pointer;
        position: relative;
        z-index: 5;
        &:after {
            content: '';
            width: 7px;
            height: 7px;
            border-right: 1px solid $text_color;
            border-bottom: 1px solid $text_color;
            transform: rotate(45deg);
            margin-left: 10px;
            margin-bottom: 2px;
        }
        &.active {
            border-radius: 5px 5px 0 0;
        }
    }
    &_block {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100% !important;
        height: 114px;
        overflow: hidden;
        z-index: 4;
        font-size: 14px;
        border-radius: 0 0 3px 3px;
        background-color: #fff;
        padding: 4px 14px 4px 10px !important;
        display: none;
        box-shadow: 0px 0px 46px 7px rgba(#070707, 0.2);
    }
    &_item {
        display: block;
        color: $text_color;
        transition: color 0.4s;
        text-decoration: none;
        padding: 8px 0;
        &:hover {
            color: $red;
        }
    }
}

.portfolio_filter {
    background-color: #f1f6ff;
    border-radius: 5px;
    padding: 10px;
    @include media_tablet {
        justify-content: space-between;
    }
    @include media_phone {
        flex-wrap: wrap;
    }
    .select {
        margin-right: 20px;
        &_title {
            width: 100%;
        }
        @include media_tablet {
            margin-right: 0;
            width: 48.6%;
        }
        @include media_phone {
            width: 100%;
            margin-bottom: 5px;
        }
        &:last-child {
            margin-right: 0;
            @include media_phone {
                margin-bottom: 0;
            }
        }
    }
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_draggerRail {
    width: 4px;
}

.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #e8edf6;
}

.mCSB_scrollTools {
    opacity: 1;
    width: 4px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover, .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: $red;
}


.catalog_page {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #f1f6ff;
    @include media_tablet {
        padding-top: 40px;
        padding-bottom: 20px;
    }
    @include media_phone {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .catalog.active {
        .catalog_item {
            display: none;
            &.active {
                display: block;
                &.flex {
                    display: flex;
                    @include media_laptop {
                        display: block;
                    }
                }
            }
        }
    }
}

.catalog_filter {
    margin-bottom: 20px;
    @include media_tablet {
        flex-wrap: wrap;
        margin-bottom: 10px;
        &.flex {
            justify-content: space-between;
        }
    }
    &_item {
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
        @include media_tablet {
            margin-right: 0;
            width: 31.5%;
            text-align: center;
            margin-bottom: 10px;
        }
        @include media_phone {
            width: 100%;
        }
        &_active {
            display: none;
        }
    }
    &_radio {
        position: absolute;
        width: 2px;
        height: 2px;
        left: 10px;
        top: 10px;
        opacity: 0;
        &:checked + .catalog_filter_text {
            background-color: $text_color;
            color: #fff;
        }
    }
    &_text {
        display: block;
        padding: 12px 40px;
        background-color: #dce7fb;
        border-radius: 22px;
        position: relative;
        z-index: 1;
        transition: all 0.5s;
        @include media_laptop {
            padding: 12px 30px;
        }
    }
}

.accessories {
    background-color: #f1f6ff;
    padding-top: 50px;
    padding-bottom: 40px;
    @include media_phone {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    flex-wrap: wrap;
    &_item {
        border-radius: 5px;
        border: 1px solid #e8edf6;
        background-color: #ffffff;
        margin-bottom: 20px;
        padding: 20px 30px 30px 20px;
        width: 49%;
        flex-direction: column;
        @include media_tablet {
            padding: 10px 10px 18px;
        }
        @include media_phone {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px;
        }
        &_text {
            margin-bottom: 14px;
            @include media_laptop {
                min-height: 150px;
                width: calc(100% - 115px);
            }
            @include media_phone {
                width: calc(100% - 70px);
                min-height: 90px;
            }
        }
        &_block_img {
            margin-right: 20px;
            @include media_laptop {
                margin-right: 0;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        &_img {
            display: block;
            border-radius: 5px;
            min-width: 130px;
            width: 130px;
            @include media_laptop {
                width: 100px;
                min-width: auto;
            }
            @include media_phone {
                width: 60px;
            }
        }
        &_right {
            padding-top: 12px;
            flex-direction: column;
            width: 100%;
            @include media_laptop {
                padding-top: 0;
                &.flex {
                    align-items: flex-end;
                }
            }
        }
        &_title {
            display: block;
            text-decoration: none;
            font-weight: 700;
            color: $text_color;
            margin-bottom: 30px;
            @include media_tablet {
                margin-bottom: 12px;
                font-size: 16px;
            }
            @include media_phone {
                font-size: 12px;
                margin-bottom: 8px;
            }
        }
        &_desc {
            font-size: 14px;
            @include media_phone {
                font-size: 10px;
            }
        }
        &_products {
            margin-top: auto;
            @include media_laptop {
                width: 100%;
            }
        }
        &_select {
            @include media_tablet {
                &.jqselect {
                    width: calc(100% - 130px);
                }
            }
            @include media_phone {
                font-size: 10px;
            }
            &.opened {
                .jq-selectbox__select {
                    border-radius: 5px 5px 0 0;
                }
            }
            &.dropup {
                .jq-selectbox__select {
                    border-radius: 0 0 5px 5px;
                }
                .jq-selectbox__dropdown {
                    border-radius: 3px 3px 0 0;
                }
            }
            .jq-selectbox__select {
                width: 240px;
                max-width: 100%;
                border-radius: 5px;
                border: 1px solid #dae3f4;
                height: 44px;
                padding: 0 16px;
                position: relative;
                z-index: 5;
                background-color: #fff;
                @include flex(space-between, center);
                @include media_tablet {
                    width: 100%;
                }
                @include media_phone {
                    padding: 0 14px;
                }
                .jq-selectbox__trigger {
                    width: 7px;
                    height: 7px;
                    border-right: 1px solid $text_color;
                    border-bottom: 1px solid $text_color;
                    transform: rotate(45deg);
                    margin-left: 10px;
                    margin-bottom: 2px;
                }
                .jq-selectbox__select-text {
                    width: auto !important;
                }
            }
            .jq-selectbox__dropdown {
                border-radius: 0 0 3px 3px;
                background-color: #fff;
                width: 100%;
                max-height: 114px;
                overflow: hidden;
                z-index: 4;
                font-size: 14px;
                padding: 4px 14px 4px 10px;
                box-shadow: 0px 0px 46px 7px rgba(#070707, 0.2);
                @include media_phone {
                    font-size: 10px;
                    max-height: 98px;
                }
                ul {
                    list-style: none;
                }
                li {
                    padding: 8px 0 !important;
                    margin: 0 !important;
                    &:before {
                        content: none !important;
                    }
                    &.disabled {
                        display: none;
                    }
                }
            }
        }
        &_top {
            margin-bottom: 22px;
            position: relative;
        }
        &_price {
            font-size: 20px;
            font-weight: 700;
            margin-right: 20px;
            @include media_phone {
                font-size: 14px;
            }
        }
    }
}

.jq-selectbox__select {
    cursor: pointer;
}

.jq-selectbox__dropdown li {
    cursor: pointer;
}

.product {
    &_neighbors {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 0;
        &_item {
            position: relative;
            top: -28px;
            width: 50px;
            height: 30px;
            @include media_tablet {
                top: -18px;
            }
            @include media_phone {
                top: -13px;
                width: 30px;
                height: 20px;
            }
            .svg_icon {
                display: block;
                width: 100%;
                height: 100%;
                
                fill: #ed1111;
            }
            &.link_prev {
                right: 66px;
                @include media_phone {
                    right: 40px;
                }
                .svg_icon {
                    transform: rotate(180deg);
                }
            }
            &.link_next {
                left: 66px;
                @include media_phone {
                    left: 40px;
                }
            }
        }
    }
    &_img {
        max-height: 100%;
        max-width: 83%;
        @include media_tablet {
            max-width: 228px;
            max-height: 390px;
        }
        @include media_phone {
            max-width: 200px;
            max-height: 248px;
        }
    }
    &_top {
        background-color: #f1f6ff;
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;
        @include media_tablet {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @include media_phone {
            padding-top: 20px;
            padding-bottom: 30px;
        }
    }
    &_left {
        min-height: 578px;
        position: relative;
        min-width: 90px;
        margin-right: 10px;
        @include media_tablet {
            width: 60px;
            min-width: 60px;
            min-height: 454px;
        }
        @include media_phone {
            min-height: auto;
            position: absolute;
            left: 10px;
            top: 20px;
        }
    }
    &_right {
        width: 100%;
        background-color: #fff;
        border-radius: 5px;
        padding: 30px 50px 50px 30px;
        @include media_tablet {
            padding: 15px 15px 28px 10px;
        }
        @include media_phone {
            flex-wrap: wrap;
            background-color: transparent;
            padding: 0;
        }
    }
    &_owl {
        width: 478px;
        transform-origin: left;
        transform: rotate(90deg);
        position: absolute;
        left: 45px;
        top: 5px;
        @include media_tablet {
            width: 350px;
            left: 32px;
            top: 18px;
        }
        @include media_phone {
            left: 30px;
            top: 10px;
            width: 260px;
        }
        &.mini {
            top: -49px;
            @include media_tablet {
                top: -36px;
            }
            @include media_phone {
                top: -34px;
            }
        }
        .owl-stage-outer {
            z-index: 2;
        }
        &_link {
            width: 112px;
            height: 90px;
            border-radius: 3px;
            background-color: #fff;
            @include media_tablet {
                height: 64px;
                width: 80px;
            }
            @include media_phone {
                height: 60px;
                width: 78px;
            }
            &.active {
                border: 1px solid $text_color;
            }
        }
        &_img {
            transform: rotate(-90deg);
            width: auto !important;
            display: block;
            border-radius: 3px;
            @include media_tablet {
                width: 54px !important;
            }
            @include media_phone {
                width: 50px !important;
            }
        }
        .owl-item {
            @include flex;
        }
        .owl-nav {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            .svg_icon {
                width: 20px;
                height: 10px;
            }
            .owl-next, .owl-prev {
                width: 50px;
                height: 100%;
                position: absolute;
                top: 0;
                @include media_phone {
                    width: 40px;
                }
            }
            .owl-prev {
                right: 100%;
            }
            .owl-next {
                left: 100%;
            }
        }
    }
    &_image_block {
        width: 42%;
        height: 100%;
        position: relative;
        @include media_phone {
            width: calc(100% - 65px);
            margin-left: 65px;
            background-color: #fff;
            border-radius: 3px;
            height: 340px;
            margin-bottom: 5px;
        }
        .catalog_item_tag {
            left: -22px;
            top: -22px;
            @include media_tablet {
                top: -5px;
            }
            @include media_phone {
                left: 10px;
                top: 10px;
            }
        }
        .product_stamp {
            transform: rotate(15deg);
        }
    }
    &_stamp {
        width: 90px;
        height: 60px;
        @include bg;
        background-image: url(../img/stamp.svg);
        position: absolute;
        top: -18px;
        right: 0;
        @include media_tablet {
            top: -5px;
            width: 67px;
            height: 45px;
        }
        @include media_phone {
            display: none;
        }
    }
    &_info {
        width: 53%;
        @include media_tablet {
            width: 54%;
        }
        @include media_phone {
            width: 100%;
            background-color: #fff;
            border-radius: 3px;
            padding: 15px 10px 20px;
        }
        &_title {
            font-size: 24px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e8edf6;
            margin-bottom: 28px;
            @include media_tablet {
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
        &_feature {
            font-size: 14px;
            margin-bottom: 40px;
            @include media_tablet {
                margin-bottom: 25px;
                font-size: 12px;
            }
            @include media_phone {
                font-size: 10px;
            }
            &_item {
                position: relative;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: 13px;
                    left: 0;
                    width: 100%;
                    height: 0;
                    border-bottom: 2px dotted #95b0e7;
                    @include media_tablet {
                        top: 11px;
                    }
                    @include media_phone {
                        top: 9px;
                    }
                }
            }
            &_left {
                margin-right: 10px;
                font-weight: 700;
            }
            &_right {
                text-align: right;
            }
            &_left, &_right {
                background-color: #fff;
                position: relative;
                z-index: 1;
            }
        }
        .catalog_item_radio {
            width: 50%;
            @include media_tablet {
                font-size: 14px;
                .checked {
                    top: 1px;
                }
            }
            @include media_phone {
                font-size: 12px;
                .checked {
                    top: -1px;
                }
            }
        }
        &_prices {
            margin-bottom: 25px;
            @include media_tablet {
                margin-bottom: 20px;
            }
        }
        .catalog_btn {
            margin-left: 30px;
            @include media_phone {
                margin-left: 10px;
                width: calc(100% - 130px);
                min-width: auto;
            }
        }
        .old_price {
            font-size: 18px;
            @include media_tablet {
                font-size: 14px;
            }
            @include media_phone {
                font-size: 12px;
            }
        }
        .price {
            font-size: 30px;
            @include media_tablet {
                font-size: 20px;
            }
            @include media_phone {
                font-size: 18px;
            }
        }
    }
    &_middle {
        padding-top: 34px;
        padding-bottom: 44px;
        @include media_phone {
            padding-top: 15px;
            padding-bottom: 20px;
            flex-wrap: wrap;
        }
        &_right {
            width: 28.5%;
            @include media_tablet {
                width: 32%;
            }
            @include media_phone {
                width: 100%;
            }
        }
        &_block {
            margin-bottom: 20px;
            border-radius: 5px;
            background-color: #f1f6ff;
            padding: 25px 28px 35px;
            &:last-child {
                margin-bottom: 0;
            }
            @include media_tablet {
                padding: 15px 20px 20px;
                margin-bottom: 10px;
            }
            &_title {
                text-align: center;
                margin-bottom: 24px;
                font-size: 20px;
                font-weight: 700;
                @include media_tablet {
                    font-size: 16px;
                }
            }
            &_link {
                color: #12aadc;
                margin-bottom: 20px;
                &:active {
                    text-decoration: underline;
                }
                &:focus {
                    text-decoration: underline;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:before {
                    content: '';
                    width: 14px;
                    height: 18px;
                    margin-right: 15px;
                    @include bg;
                    background-image: url(../img/pdf.svg);
                }
                &.calculator:before {
                    background-image: url(../img/calculator.svg);
                }
            }
        }
    }
    &_content {
        width: 66.1%;
        @include media_phone {
            width: 100%;
            font-size: 10px;
            margin-bottom: 20px;
        }
        h5 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
            color: $red;
            @include media_tablet {
                font-size: 16px;
                margin-bottom: 14px;
            }
            @include media_phone {
                font-size: 10px;
            }
        }
    }
    &_gallery_item {
        &_img {
            display: block;
            border-radius: 5px;
        }
    }
    &_accessories_block {
        background-color: #f1f6ff;
        padding-top: 34px;
        padding-bottom: 60px;
        @include media_tablet {
            padding-bottom: 40px;
        }
        @include media_phone {
            padding-top: 25px;
        }
        .section_title {
            font-size: 30px;
            text-align: left;
            margin-bottom: 28px;
            @include media_tablet {
                font-size: 26px;
            }
            @include media_phone {
                text-align: center;
                font-size: 18px;
                margin-bottom: 15px;
            }
        }
    }
    &_accessories {
        padding: 0 100px;
        @include media_tablet {
            padding: 0;
        }
        .product_accessories_item {
            max-width: 49%;
        }
        &.owl-carousel .product_accessories_item {
            max-width: unset;
        }
        &_bottom {
            padding-right: 10px;
            @include media_tablet {
                padding-right: 0;
                &.flex {
                    align-items: flex-end;
                }
            }
        }
        &_item {
            padding: 20px;
            background-color: #fff;
            border-radius: 3px;
            position: relative;
            height: 100%;
            @include media_tablet {
                padding: 10px;
            }
            &_img {
                display: block;
                width: 90px !important;
                min-width: 90px;
                margin-right: 20px;
                border-radius: 3px;
                @include media_phone {
                    width: 60px !important;
                    min-width: 60px;
                    margin-right: 10px;
                }
            }
            &_right {
                width: 100%;
                flex-direction: column;
                padding: 5px 0;
                @include media_tablet {
                    padding: 0;
                }
            }
            &_price {
                font-size: 20px;
                font-weight: 700;
                @include media_tablet {
                    font-size: 18px;
                }
                @include media_phone {
                    font-size: 16px;
                }
            }
            &_btn {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                background-color: $red;
                padding: 0;
                box-shadow: 1px 3px 0 0 #af0404;
                @include media_phone {
                    width: 40px;
                    height: 40px;
                    box-shadow: 0 2px 0 0 #af0404;
                }
                .svg_icon {
                    width: 20px;
                    height: 17px;
                    fill: #fff;
                }
            }
        }
        .owl-nav {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            .owl-prev, .owl-next {
                position: absolute;
                top: 0;
                height: 100%;
            }
            .owl-prev {
                left: 0;
            }
            .owl-next {
                right: 0;
            }
        }
        .owl-stage-outer {
            z-index: 2;
        }
        .owl-stage {
            display: flex;
        }
    }
    &_option {
        width: auto!important;
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        &:not(:last-child) {
            margin-right: 10px;
        }
        &_title {
            font-size: 20px;
            margin-bottom: 6px;
        }
    }
}

.cart {
    padding-top: 50px;
    padding-bottom: 30px;
    @include media_tablet {
        padding-top: 40px;
    }
}

.msCart {
    &_header {
        padding: 14px 30px;
        background-color: #002061;
        color: #fff;
        border-radius: 3px;
        margin-bottom: 10px;
        @include media_tablet {
            padding: 15px;
        }
        @include media_phone {
            display: none;
        }
    }
    .text_center {
        text-align: center;
    }
    &_number {
        width: 6%;
        @include media_tablet {
            display: none;
        }
    }
    &_title {
        width: 36%;
        @include media_tablet {
            text-align: left !important;
            width: 42%;
        }
    }
    &_price {
        width: 23%;
        @include media_tablet {
            width: 21%;
        }
    }
    &_count {
        width: 15%;
        @include media_tablet {
            width: 17%;
        }
    }
    &_total {
        width: 20%;
    }
    &_item {
        border-radius: 5px;
        border: 1px solid #e8edf6;
        background-color: #fff;
        padding: 0 30px;
        margin-bottom: 20px;
        @include media_tablet {
            padding: 0 15px;
            margin-bottom: 10px;
        }
        @include media_phone {
            padding: 0 10px;
            position: relative;
        }
        &_top {
            padding: 15px 0;
            border-bottom: 1px solid #e8edf6;
            &.accessory {
                border: none;
            }
            @include media_phone {
                padding: 10px 0;
                flex-wrap: wrap;
            }
        }
        &_bottom {
            padding: 15px 0;
            @include media_phone {
                padding: 12px 0;
            }
            .link {
                color: #00bff3;
                font-size: 14px;
                @include media_phone {
                    font-size: 12px;
                }
                .plus {
                    position: relative;
                    width: 12px;
                    height: 12px;
                    margin-right: 8px;
                    &:before, &:after {
                        content: '';
                        position: absolute;
                        width: 12px;
                        height: 3px;
                        background-color: #00bff3;
                        transform: translate(-50%, -50%);
                        top: 50%;
                        left: 50%;
                    }
                    &:after {
                        height: 12px;
                        width: 3px;
                    }
                }
            }
        }
        &_link {
            border: 1px solid #e8edf6;
            border-radius: 5px;
            padding: 12px 20px;
            width: 9%;
            margin-right: 2.2%;
            height: 136px;
            @include media_tablet {
                padding: 10px;
                width: 12%;
            }
            @include media_phone {
                width: 21.5%;
                margin-right: 3.5%;
            }
        }
        &_img {
            max-height: 100%;
        }
        &_info {
            width: 47.8%;
            @include media_tablet {
                width: 50.8%;
            }
            @include media_phone {
                width: 75%;
                font-size: 10px;
                &.flex {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                }
            }
            &_left {
                width: 54%;
                @include media_phone {
                    width: 100%;
                }
            }
            &_right {
                width: 46%;
                @include media_phone {
                    width: 100%;
                }
                @include media_phone {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    padding-top: 4px;
                }
            }
        }
        &_right {
            width: 35%;
            @include media_phone {
                width: 100%;
                padding-top: 15px;
                justify-content: space-between;
            }
        }
        &_count {
            width: 43%;
            @include media_phone {
                width: auto;
            }
        }
        &_total {
            width: 55.5%;
            font-weight: 700;
            font-size: 20px;
            color: $red;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                width: auto;
                font-size: 12px;
            }
        }
        &_remove {
            width: 1.5%;
            @include media_phone {
                position: absolute;
                width: auto;
                top: 10px;
                right: 10px;
            }
            .remove_btn {
                position: relative;
                width: 15px;
                height: 15px;
                padding: 0;
                border: 0;
                display: block;
                border-radius: 0;
                &:before, &:after {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 2px;
                    background-color: #b9c6de;
                    top: 50%;
                    left: 50%;
                    transform-origin: center;
                    @include media_phone {
                        width: 12px;
                    }
                }
                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &:after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
        &_title {
            font-weight: 700;
            margin-bottom: 12px;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                font-size: 12px;
                margin-bottom: 0;
            }
        }
        &_price {
            font-weight: 700;
            font-size: 20px;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                font-size: 12px;
            }
        }
    }
    &_footer {
        @include media_phone {
            flex-direction: column;
        }
        .catalog_btn {
            margin-left: 10px;
            @include media_phone {
                margin-left: 0;
                width: 100%;
                margin-bottom: 8px;
            }
        }
        .add_btn {
            margin-left: 20px;
            @include media_phone {
                width: 100%;
                margin-left: 0;
            }
        }
        &_total {
            font-size: 18px;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                margin-bottom: 10px;
            }
        }
        &_count {
            font-weight: 700;
            color: $red;
        }
        &_price {
            font-size: 20px;
            font-weight: 700;
            color: $red;
            @include media_tablet {
                font-size: 16px;
            }
        }
    }
    &_clean {
        text-align: right;
        padding-right: 32px;
        margin-bottom: 20px;
        @include media_tablet {
            padding-top: 10px;
        }
        @include media_phone {
            padding-top: 0;
            padding-right: 10px;
            margin-bottom: 12px;
        }
        .btn_clean {
            height: auto;
            padding: 0;
            color: $red;
            text-decoration: underline;
            border: none;
            border-radius: 0;
            font-size: 14px;
            @include media_phone {
                font-size: 12px;
            }
            &:focus {
                outline: none;
            }
            &_add {
                width: 10px;
                height: 10px;
                position: relative;
                margin-left: 6px;
                &:before, &:after {
                    content: '';
                    position: absolute;
                    width: 14px;
                    height: 1px;
                    background-color: $red;
                    top: 50%;
                    left: 50%;
                    transform-origin: center;
                }
                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &:after {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }
        }
    }
}

.cart_accessories {
    width: 100%;
    max-height: 656px;
    height: auto;
    padding: 0;
    background-color: #e8edf6;
    @include media_laptop {
        max-height: 500px;
    }
    @include media_phone {
        max-height: 400px;
    }
    &_wrapper {
        max-width: 90%;
        width: 800px;
        padding: 0;
        overflow: hidden;
        border-radius: 5px;
        background-color: #fff;
        [data-fancybox-close] {
            display: none;
        }
    }
    .accessories {
        &_item {
            width: 100%;
            margin: 0;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #e8edf6;
            position: relative;
            flex-direction: row;
            &_bottom {
                width: 100%;
                @include media_phone {
                    flex-wrap: wrap;
                    .catalog_btn {
                        width: 100%;
                        margin-top: 12px;
                        margin-left: 0 !important;
                    }
                }
            }
            &_title {
                margin-bottom: 16px;
                @include media_phone {
                    margin-bottom: 2px;
                }
            }
            &_desc {
                flex: 1 0 auto;
            }
            &_block_img {
                @include media_laptop {
                    left: 10px;
                    top: 10px;
                    margin-right: 15px;
                }
            }
            .catalog_btn {
                margin-left: 20px;
            }
        }
    }
    .mCSB_container {
        margin-right: 20px;
    }
    .mCSB_scrollTools {
        width: 20px;
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_draggerRail {
        width: 6px;
    }
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover, .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, 
    .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
        background-color: #002061;
    }
}

#msOrder {
    margin-top: 0;
}

.order {
    padding-top: 50px;
    padding-bottom: 60px;
    counter-reset: order;
    @include media_tablet {
        padding-top: 40px;
        padding-bottom: 50px;
        &_form {
            flex-wrap: wrap;
        }
    }
    @include media_phone {
        padding-top: 24px;
    }
    &_left {
        width: 66.1%;
        @include media_tablet {
            width: 100%;
        }
    }
    &_title {
        padding: 12px 20px;
        background-color: #f1f6ff;
        border-radius: 5px;
        margin-bottom: 25px;
        font-weight: 700;
        counter-increment: order;
        @include media_tablet {
            font-size: 16px;
        }
        @include media_phone {
            font-size: 12px;
            margin-bottom: 10px;
            padding: 9px 15px;
        }
        &:before {
            content: '0' counter(order) '.';
            font-family: 'Bebas Neue', sans-serif;
            font-weight: 700;
            font-size: 26px;
            margin-right: 15px;
            line-height: 1;
            margin-top: 5px;
            @include media_phone {
                font-size: 16px;
            }
            @include media_phone {
                margin-right: 8px;
                margin-top: 2px;
            }
        }
    }
    &_block {
        padding: 0 20px 15px;
        @include media_phone {
            padding: 0 0 10px;
        }
        flex-wrap: wrap;
        .input_label {
            margin-bottom: 15px;
            width: 48.5%;
            @include media_phone {
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
    .input_label select {
        margin-top: 0;
    }
    .accessories_item_select {
        .jq-selectbox__select {
            width: 100%;
            border: 1px solid #b1bfda;
        }
    }
    .input.jq-selectbox {
        height: auto;
        border-radius: 0;
        border: none;
        padding: 0;
        background-color: transparent;
        width: 100%;
    }
    .required-star {
        color: $red !important;
    }
    &_right {
        width: 32.2%;
        background-color: #f1f6ff;
        border-radius: 5px;
        flex-direction: column;
        padding: 16px 20px 32px;
        @include media_tablet {
            width: 100%;
        }
        @include media_phone {
            padding: 16px 10px;
        }
        &_bottom {
            @include media_tablet {
                text-align: right;
            }
            .order_total_block {
                font-size: 22px;
                font-weight: 700;
                margin-bottom: 20px;
                @include media_tablet {
                    font-size: 18px;
                }
                &:before {
                    bottom: 9px;
                    @include media_tablet {
                        bottom: 7px;
                    }
                }
                &_item {
                    &.left {
                        text-transform: uppercase;
                    }
                    &.red {
                        color: $red;
                    }
                }
            }
            .catalog_btn {
                width: 100%;
                @include media_tablet {
                    width: 236px;
                }
                @include media_phone {
                    width: 100%;
                }
            }
        }
    }
    &_cart {
        &_title {
            font-weight: 700;
            margin-bottom: 24px;
            @include media_tablet {
                font-size: 16px;
            }
            @include media_phone {
                margin-bottom: 10px;
            }
        }
        &_count {
            margin-bottom: 10px;
            @include media_phone {
                font-size: 10px;
            }
        }
        .msCart {
            font-size: 14px;
            @include media_phone {
                font-size: 12px;
            }
        }
        .miniCart_item {
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid #dae3f4;
            @include media_phone {
                margin-bottom: 10px;
                padding-bottom: 10px;
                &.flex {
                    align-items: center;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            &_link {
                background-color: #fff;
                @include media_phone {
                    margin-right: 10px;
                }
            }
        }
    }
    &_total {
        padding: 22px 0;
        border-bottom: 1px solid #dae3f4;
        margin-bottom: 25px;
        @include media_phone {
            padding: 12px 0;
            margin-bottom: 12px;
        }
        &_block {
            position: relative;
            margin-bottom: 10px;
            @include media_tablet {
                b {
                    font-size: 16px;
                }
            }
            @include media_phone {
                font-size: 10px;
                margin-bottom: 4px;
                b {
                    font-size: 12px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:before {
                content: '';
                width: 100%;
                height: 0;
                left: 0;
                bottom: 6px;
                position: absolute;
                border-bottom: 2px dotted #a3b7db;
                @include media_tablet {
                    bottom: 5px;
                }
                @include media_phone {
                    bottom: 4px;
                }
            }
            &_item {
                background-color: #f1f6ff;
                position: relative;
                z-index: 1;
            }
        }
    }
    &_get {
        text-align: center;
        &:before {
            content: '';
            display: inline-block;
            width: 80px;
            height: 80px;
            @include bg;
            margin-bottom: 34px;
            background-image: url(../img/checked.svg);
        }
        &_title {
            margin-bottom: 24px;
            font-size: 30px;
            font-weight: 700;
            .red {
                color: $red;
            }
        }
        &_desc {
            margin-bottom: 50px;
        }
        .link {
            color: #00bff3;
            .svg_icon {
                width: 21px;
                height: 10px;
                fill: #00bff3;
                margin-left: 10px;
            }
        }
    }
}

@include media_phone {
    .catalog_filter {
        &_wrapper {
            position: relative;
        }
        &_item_active {
            @include flex;
            border-radius: 20px;
            background-color: $text_color;
            height: 40px;
            color: #fff;
            cursor: pointer;
            margin-bottom: 10px;
            &:after {
                content: '';
                width: 6px;
                height: 6px;
                margin-left: 4px;
                border-top: 2px solid #fff;
                border-right: 2px solid #fff;
                transform: rotate(45deg);
            }
        }
        &_block {
            display: none;
            position: absolute;
            left: 0;
            top: 45px;
            width: 100% !important;
            border-radius: 10px;
            background-color: #dce7fb;
            z-index: 5;
            padding: 5px 0;
            .catalog_filter {
                &_item {
                    margin-bottom: 0;
                }
                &_text {
                    background-color: transparent !important;
                    color: $text_color !important;
                    padding: 10px 0;
                }
            }
        }
    }
}

.content_text {
    padding: 50px 0;
    background-color: #f1f6ff;
    @include media_tablet {
        padding: 30px 0 40px;
    }
    @include media_phone {
        padding: 20px 0 30px;
    }
}

.error_page {
    padding: 40px 0;
    text-align: center;
    @include media_tablet {
        padding: 20px 0;
    }
    &:before {
        content: '';
        display: inline-block;
        width: 530px;
        height: 188px;
        max-width: 90%;
        margin-bottom: 40px;
        @include bg;
        background-image: url(../img/404.png);
        @include media_phone {
            height: 140px;
            margin-bottom: 20px;
        }
    }
    .h2 {
        margin-bottom: 18px;
    }
    .text {
        margin-bottom: 24px;
    }
    .link {
        color: #00bff3;
        font-size: 14px;
    }
}

.error_file {
    color: $red;
    padding-top: 6px;
}

.conf {
    &_block {
        text-align: left;
        font-size: 14px;
        margin-bottom: 14px;
        position: relative;
        padding-left: 24px;
        display: block;
        cursor: pointer;
    }
    &_checkbox {
        position: absolute;
        width: 2px;
        height: 2px;
        top: 5px;
        left: 5px;
        opacity: 0;
        text-align: center;
        &:checked + .conf_check:before {
            content: '\2714';
            color: $text_color;
            font: 16px/15px Arial;
        }
    }
    &_check {
        width: 18px;
        height: 18px;
        border-radius: 3px;
        border: 2px solid $text_color;
        position: absolute;
        left: 0;
        top: 0;
        padding-top: 1px;
        &:before {
            content: '';
        }
    }
}

.mCSB_container ul li:hover {
    color: $red;
}

.ms2_delivery_cost.no_currency + span {
    display: none;
}

.normalize-styles {
    ol {
        padding-left: 16px;
    }
}

