@font-face {
  font-family: 'ProximaNova';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/ProximaNova-Regular.eot");
  src: local("ProximaNova"), url("../fonts/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'ProximaNova';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/ProximaNova-Bold.eot");
  src: local("ProximaNova"), url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'ProximaNova';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/ProximaNova-Bold.eot");
  src: local("ProximaNova"), url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Bebas Neue';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Bebas Neue Bold"), local("BebasNeueBold"), url("../fonts/bebasneuebold.woff2") format("woff2"), url("../fonts/bebasneuebold.woff") format("woff"), url("../fonts/bebasneuebold.ttf") format("truetype"); }

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html,
body {
  height: 100%; }

.main {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 143px; }
  @media screen and (max-width: 1200px) {
    .main {
      padding-top: 141px; } }
  @media screen and (max-width: 1023px) {
    .main {
      padding-top: 67px; } }
  @media screen and (max-width: 767px) {
    .main {
      padding-top: 53px; } }

body.active .mainPage .op {
  opacity: 0; }

.animated {
  animation-duration: 0.7s; }

.header_block {
  flex: 0 0 auto;
  z-index: 15;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }

.content {
  flex: 1 0 auto; }

.footer_block {
  flex: 0 0 auto; }

body {
  font-family: 'ProximaNova', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  color: #00143e; }
  @media screen and (max-width: 1023px) {
    body {
      font-size: 14px; } }
  @media screen and (max-width: 767px) {
    body {
      font-size: 12px; } }

.container, .slider_wrapper .owl-dots {
  display: block;
  width: 100%;
  max-width: none;
  padding: 0 calc(50% - 590px); }
  @media screen and (max-width: 1200px) {
    .container, .slider_wrapper .owl-dots {
      padding: 0 10px; } }

.flex {
  display: flex; }
  .flex.jsb {
    justify-content: space-between; }
  .flex.jfs {
    justify-content: flex-start; }
  .flex.jfe {
    justify-content: flex-end; }
  .flex.jc {
    justify-content: center; }
  .flex.ac {
    align-items: center; }
  .flex.afs {
    align-items: flex-start; }
  .flex.afe {
    align-items: flex-end; }

.inline_flex {
  display: inline-flex; }
  .inline_flex.jsb {
    justify-content: space-between; }
  .inline_flex.jfs {
    justify-content: flex-start; }
  .inline_flex.jfe {
    justify-content: flex-end; }
  .inline_flex.jc {
    justify-content: center; }
  .inline_flex.ac {
    align-items: center; }
  .inline_flex.afs {
    align-items: flex-start; }
  .inline_flex.afe {
    align-items: flex-end; }

p, ul:not([class]), ul:not([class]) li:not([class]) {
  margin-bottom: 20px; }
  p:last-child, ul:not([class]):last-child, ul:not([class]) li:not([class]):last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 1023px) {
    p, ul:not([class]), ul:not([class]) li:not([class]) {
      margin-bottom: 16px; } }
  @media screen and (max-width: 767px) {
    p, ul:not([class]), ul:not([class]) li:not([class]) {
      margin-bottom: 14px; } }

ul:not([class]) {
  list-style: none;
  padding: 0; }
  ul:not([class]) li:not([class]) {
    padding-left: 16px;
    position: relative; }
    ul:not([class]) li:not([class]):before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: absolute;
      background-color: #00143e;
      left: 0;
      top: 8px; }
      @media screen and (max-width: 1023px) {
        ul:not([class]) li:not([class]):before {
          top: 7px; } }
      @media screen and (max-width: 767px) {
        ul:not([class]) li:not([class]):before {
          top: 4px; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .section_title {
  font-weight: 700;
  line-height: 1.4;
  color: #00143e; }

img {
  max-width: 100%; }

.section_title {
  margin-bottom: 35px;
  line-height: 1.15;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .section_title {
      margin-bottom: 20px; } }

h1, .h1, .section_title {
  font-size: 40px; }
  @media screen and (max-width: 1023px) {
    h1, .h1, .section_title {
      font-size: 26px; } }
  @media screen and (max-width: 767px) {
    h1, .h1, .section_title {
      font-size: 18px; } }

.h1, h1 {
  margin: 0; }

h2, .h2 {
  font-size: 30px; }
  @media screen and (max-width: 1023px) {
    h2, .h2 {
      font-size: 26px; } }
  @media screen and (max-width: 767px) {
    h2, .h2 {
      font-size: 18px; } }

h3, .h3 {
  font-size: 24px;
  text-transform: uppercase; }
  @media screen and (max-width: 1023px) {
    h3, .h3 {
      font-size: 22px; } }

h4, .h4 {
  font-size: 18px; }
  @media screen and (max-width: 1023px) {
    h4, .h4 {
      font-size: 16px; } }

h5, h6,
.h5, .h6 {
  font-size: 16px; }
  @media screen and (max-width: 1023px) {
    h5, h6,
    .h5, .h6 {
      font-size: 14px; } }

.text_center {
  text-align: center; }

button {
  cursor: pointer;
  text-align: center;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #ed1111;
  display: inline-block;
  padding: 0 20px;
  background-color: transparent; }

.jGrowl-close {
  height: auto;
  width: auto;
  padding: 0; }

.standard_btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #ed1111;
  min-width: 158px;
  max-width: 100%;
  font-weight: 400;
  color: #ed1111;
  background-color: transparent;
  transition: all 0.4s;
  padding: 0 14px; }
  .standard_btn:hover, .standard_btn:focus, .standard_btn:active {
    background-color: #ed1111;
    color: #fff; }
  .standard_btn:focus, .standard_btn:active {
    box-shadow: inset 0 2px 7px rgba(111, 13, 13, 0.77);
    outline: none; }

a.standard_btn {
  text-decoration: none; }

.catalog_btn, .add_btn {
  position: relative;
  background-color: transparent;
  border: none;
  border-radius: 22px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.4s;
  padding: 0;
  min-width: 200px;
  max-width: 100%; }
  @media screen and (max-width: 767px) {
    .catalog_btn, .add_btn {
      min-width: 160px; } }
  .catalog_btn:before, .add_btn:before {
    content: '';
    position: absolute;
    width: calc(100% - 2px);
    height: 100%;
    top: 3px;
    left: 1px;
    background-color: #af0404;
    border-radius: 22px;
    transition: all 0.4s; }
  .catalog_btn_text {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background-color: #ed1111;
    z-index: 1;
    width: 100%;
    height: 44px;
    border-radius: 22px;
    color: #fff;
    font-weight: 700;
    transition: all 0.4s; }
  .catalog_btn:hover:before, .add_btn:hover:before, .catalog_btn:active:before, .add_btn:active:before, .catalog_btn:focus:before, .add_btn:focus:before {
    background-color: #cf2f10; }
  .catalog_btn:hover .catalog_btn_text, .add_btn:hover .catalog_btn_text, .catalog_btn:active .catalog_btn_text, .add_btn:active .catalog_btn_text, .catalog_btn:focus .catalog_btn_text, .add_btn:focus .catalog_btn_text {
    background-color: #ff5534; }
  .catalog_btn:active, .add_btn:active, .catalog_btn:focus, .add_btn:focus {
    outline: none; }
    .catalog_btn:active .catalog_btn_text, .add_btn:active .catalog_btn_text, .catalog_btn:focus .catalog_btn_text, .add_btn:focus .catalog_btn_text {
      box-shadow: inset 0 2px 7px rgba(152, 43, 22, 0.77); }

.square_btn {
  border-radius: 5px;
  border: 1px solid #002062;
  padding: 0 20px;
  height: 44px;
  color: #002062;
  min-width: 190px;
  max-width: 100%;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s; }
  @media screen and (max-width: 1023px) {
    .square_btn {
      min-width: auto; } }
  .square_btn:hover, .square_btn:focus, .square_btn:active {
    background-color: #002062;
    color: #fff;
    outline: none; }

.add_btn {
  min-width: 230px;
  max-width: 100%; }
  .add_btn:before {
    background-color: #e8edf6; }
  .add_btn_text {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background-color: #f4f8ff;
    z-index: 1;
    width: 100%;
    height: 44px;
    border-radius: 22px;
    color: #00143e;
    font-weight: 700;
    transition: all 0.4s;
    border: 1px solid #e8edf6; }
  .add_btn:hover:before, .add_btn:active:before, .add_btn:focus:before {
    background-color: #bfcee3; }
  .add_btn:hover .add_btn_text, .add_btn:active .add_btn_text, .add_btn:focus .add_btn_text {
    background-color: #e5edf9;
    border: 1px solid #dae2ef; }
  .add_btn:active, .add_btn:focus {
    outline: none; }
    .add_btn:active .add_btn_text, .add_btn:focus .add_btn_text {
      box-shadow: inset 0 2px 7px rgba(189, 189, 214, 0.65); }

a {
  text-decoration: underline;
  color: #ed1111;
  text-decoration: underline;
  transition: all 0.4s; }
  a:hover, a:active, a:focus {
    text-decoration: none; }

.input {
  height: 44px;
  border-radius: 5px;
  border: 1px solid #dae3f4;
  background-color: #fff;
  width: 100%;
  padding: 0 20px;
  color: #00143e;
  transition: all 0.4s; }
  @media screen and (max-width: 767px) {
    .input {
      padding: 0 15px;
      height: 40px; } }
  .input::placeholder {
    color: #8a99ba; }
  .input:focus, .input:valid {
    border: 1px solid #002061;
    outline: none; }

.input_label {
  font-size: 14px;
  font-weight: 400;
  display: block;
  margin-bottom: 16px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .input_label {
      font-size: 10px; } }
  .input_label .done {
    position: absolute;
    width: 9px;
    height: 5px;
    transform: rotate(-45deg);
    border-bottom: 2px solid #0dac50;
    border-left: 2px solid #0dac50;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    transition: opacity 0.4s; }
  .input_label .input {
    margin-top: 6px; }
    @media screen and (max-width: 767px) {
      .input_label .input {
        font-size: 12px; } }
    .input_label .input:valid + .done {
      opacity: 1; }
  .input_label.done {
    position: relative; }
    .input_label.done:after {
      content: '';
      position: absolute;
      width: 9px;
      height: 5px;
      transform: rotate(-45deg);
      border-bottom: 2px solid #0dac50;
      border-left: 2px solid #0dac50;
      bottom: 20px;
      right: 20px; }

.jq-file.input_file {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #002061;
  background-color: #ebf2ff;
  height: 44px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 6px; }
  .jq-file.input_file:before {
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/plus_circle.svg);
    width: 13px;
    height: 13px;
    margin-right: 10px; }
  .jq-file.input_file .input_file {
    cursor: pointer; }
  .jq-file.input_file .jq-file__browse {
    display: none; }
  .jq-file.input_file .jq-file__name {
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px; }

.close_x {
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-block;
  cursor: pointer; }
  .close_x:before, .close_x:after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #b9c6de;
    transition: all 0.4s;
    position: absolute;
    top: 6px;
    left: -2px;
    transform: rotate(45deg); }
  .close_x:before {
    transform: rotate(-45deg); }
  .close_x:hover:before, .close_x:hover:after, .close_x:focus:before, .close_x:focus:after {
    background-color: #ed1111; }

.warning {
  border: 1px solid #c2d0ea;
  border-radius: 5px;
  padding: 44px 50px 40px;
  background-color: #fff;
  position: relative; }
  @media screen and (max-width: 1023px) {
    .warning {
      padding: 40px 18px 20px; } }
  @media screen and (max-width: 767px) {
    .warning {
      padding: 20px 10px 10px; } }
  .warning_add {
    background-color: #fff;
    color: #ed1111;
    padding: 0 14px;
    font-weight: 700;
    position: absolute;
    top: -16px;
    left: 50px; }
    @media screen and (max-width: 767px) {
      .warning_add {
        top: -10px;
        padding: 0 7px;
        left: 42px; } }
    .warning_add .svg_img {
      width: 40px;
      height: 40px;
      margin-right: 15px; }
      @media screen and (max-width: 767px) {
        .warning_add .svg_img {
          width: 25px;
          height: 25px; } }
    .warning_add_text {
      padding-bottom: 8px; }
      @media screen and (max-width: 767px) {
        .warning_add_text {
          padding-bottom: 3px; } }

.jq-number:not(.miniCart_item_count) {
  display: inline-flex;
  position: relative; }
  .jq-number:not(.miniCart_item_count):before {
    content: '';
    position: absolute;
    top: 3px;
    left: 1px;
    height: 100%;
    width: calc(100% - 2px);
    background-color: #e8edf6;
    border-radius: 22px; }
  .jq-number:not(.miniCart_item_count) .input_number {
    width: 48px;
    height: 44px;
    border: none;
    border-top: 1px solid #e8edf6;
    border-bottom: 1px solid #e8edf6;
    text-align: center;
    font-weight: 700;
    -moz-appearance: textfield;
    position: relative;
    transition: all 0.4s;
    font-size: 16px; }
    @media screen and (max-width: 767px) {
      .jq-number:not(.miniCart_item_count) .input_number {
        font-size: 14px; } }
    .jq-number:not(.miniCart_item_count) .input_number::-webkit-inner-spin-button, .jq-number:not(.miniCart_item_count) .input_number::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none; }
    .jq-number:not(.miniCart_item_count) .input_number:focus {
      outline: none; }
  .jq-number:not(.miniCart_item_count) .jq-number__spin {
    border: 1px solid #e8edf6;
    background-color: #f4f8ff;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    transition: all 0.4s; }
    .jq-number:not(.miniCart_item_count) .jq-number__spin:before {
      color: #00143e;
      font-size: 16px;
      font-weight: 700; }
    .jq-number:not(.miniCart_item_count) .jq-number__spin.minus {
      order: -1;
      border-radius: 22px 0 0 22px; }
      .jq-number:not(.miniCart_item_count) .jq-number__spin.minus:before {
        content: '-'; }
    .jq-number:not(.miniCart_item_count) .jq-number__spin.plus {
      border-radius: 0 22px 22px 0; }
      .jq-number:not(.miniCart_item_count) .jq-number__spin.plus:before {
        content: '+'; }
    .jq-number:not(.miniCart_item_count) .jq-number__spin:hover {
      background-color: #ffffff; }

table {
  border-spacing: 0;
  font-size: 14px; }
  table th {
    padding: 16px;
    background-color: #f1f6ff;
    border: 1px solid #e8edf6;
    border-left: 0; }
    @media screen and (max-width: 1023px) {
      table th {
        padding: 13px; } }
    table th:first-child {
      border-left: 1px solid #e8edf6; }
    table th:last-child {
      text-align: left; }
  table td {
    border-right: 1px solid #e8edf6;
    border-bottom: 1px solid #e8edf6;
    text-align: left;
    padding: 12px 16px; }
    table td:first-child {
      border-left: 1px solid #e8edf6;
      text-align: center; }
    table td:last-child {
      text-align: center; }
    @media screen and (max-width: 767px) {
      table td {
        font-size: 10px;
        padding: 10px; } }

@keyframes burgerSpan {
  0% {
    background-color: #002062; }
  99% {
    background-color: #002062; }
  100% {
    background-color: transparent; } }

@keyframes burgerSpanBefore {
  0% {
    top: -12px;
    transform: rotate(0); }
  50% {
    top: 0;
    transform: rotate(0); }
  100% {
    top: 0;
    transform: rotate(45deg); } }

@keyframes burgerSpanAfter {
  0% {
    bottom: -12px;
    transform: rotate(0); }
  50% {
    bottom: 0;
    transform: rotate(0); }
  100% {
    bottom: 0;
    transform: rotate(-45deg); } }

@keyframes closeBurgerSpan {
  0% {
    background-color: transparent; }
  99% {
    background-color: #002062; }
  100% {
    background-color: #002062; } }

@keyframes closeBurgerSpanBefore {
  0% {
    top: 0;
    transform: rotate(45deg); }
  50% {
    top: 0;
    transform: rotate(0); }
  100% {
    top: -12px;
    transform: rotate(0); } }

@keyframes closeBurgerSpanAfter {
  0% {
    bottom: 0;
    transform: rotate(-45deg); }
  50% {
    bottom: 0;
    transform: rotate(0); }
  100% {
    bottom: -12px;
    transform: rotate(0); } }

.burger_btn {
  display: none;
  width: 44px;
  height: 27px;
  position: relative; }
  @media screen and (max-width: 1023px) {
    .burger_btn {
      order: 2;
      display: flex; } }
  @media screen and (max-width: 767px) {
    .burger_btn {
      width: 34px;
      height: 25px; } }
  .burger_btn .span {
    position: relative;
    width: 100%;
    height: 3px;
    background-color: #002062;
    transition: 0.5s; }
    .burger_btn .span:before, .burger_btn .span:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #002062;
      left: 0;
      transition: 0.5s; }
    .burger_btn .span:before {
      top: -12px; }
    .burger_btn .span:after {
      bottom: -12px; }
  .burger_btn.active .span {
    animation: burgerSpan 0.2s forwards; }
    .burger_btn.active .span:before {
      animation: burgerSpanBefore 0.4s forwards; }
    .burger_btn.active .span:after {
      animation: burgerSpanAfter 0.4s forwards; }
  .burger_btn.closeMenu .span {
    animation: closeBurgerSpan 0.2s forwards; }
    .burger_btn.closeMenu .span:before {
      animation: closeBurgerSpanBefore 0.4s forwards; }
    .burger_btn.closeMenu .span:after {
      animation: closeBurgerSpanAfter 0.4s forwards; }

@keyframes dots {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes circle {
  0% {
    bottom: 100%;
    right: -90%; }
  100% {
    right: -50.8%;
    bottom: -25.2%; } }

.cssload-thecube {
  width: 73px;
  height: 73px;
  margin: 0 auto;
  margin-top: 49px;
  position: relative;
  transform: rotateZ(45deg); }

.cssload-thecube .cssload-cube {
  position: relative;
  transform: rotateZ(45deg); }

.cssload-thecube .cssload-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1); }

.cssload-thecube .cssload-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ed1111;
  animation: cssload-fold-thecube 2.76s infinite linear both;
  transform-origin: 100% 100%; }

.cssload-thecube .cssload-c2 {
  transform: scale(1.1) rotateZ(90deg); }

.cssload-thecube .cssload-c3 {
  transform: scale(1.1) rotateZ(180deg); }

.cssload-thecube .cssload-c4 {
  transform: scale(1.1) rotateZ(270deg); }

.cssload-thecube .cssload-c2:before {
  animation-delay: 0.35s; }

.cssload-thecube .cssload-c3:before {
  animation-delay: 0.69s; }

.cssload-thecube .cssload-c4:before {
  animation-delay: 1.04s; }

input[name="clear"], input[name="second_name"] {
  display: none; }

@keyframes cssload-fold-thecube {
  0%, 10% {
    transform: perspective(136px) rotateX(-180deg);
    opacity: 0; }
  25%,
  75% {
    transform: perspective(136px) rotateX(0deg);
    opacity: 1; }
  90%,
  100% {
    transform: perspective(136px) rotateY(180deg);
    opacity: 0; } }

fieldset {
  padding: 0;
  border: none; }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #002062;
  z-index: 30; }
  .preloader .svg {
    width: 500px;
    height: 200px;
    max-width: 80%; }
  .preloader img {
    max-width: 80%; }

.header {
  background-color: #f1f6ff;
  font-size: 14px;
  z-index: 2;
  position: relative; }
  @media screen and (max-width: 1023px) {
    .header {
      border-bottom: 3px solid #f1f6ff; } }
  .header .address_block {
    cursor: pointer; }
  .header .phones_block {
    padding-bottom: 4px; }
  .header .msMiniCart {
    height: 45px;
    position: fixed;
    top: 90px;
    right: calc(50% - 590px);
    transition: all ease 0.3s; }
    @media screen and (max-width: 1200px) {
      .header .msMiniCart {
        right: 10px; } }
    @media screen and (max-width: 1023px) {
      .header .msMiniCart {
        position: static; } }
    @media screen and (max-width: 767px) {
      .header .msMiniCart {
        top: 54px; } }
  .header.scroll_loaded .msMiniCart {
    top: 62px; }
    .header.scroll_loaded .msMiniCart .miniCart {
      box-shadow: 0px 0px 1px 3px white; }
  .header_left {
    width: 77%; }
    @media screen and (max-width: 1023px) {
      .header_left .email_block {
        display: none; }
      .header_left .phones_block {
        order: 2; } }
    @media screen and (max-width: 767px) {
      .header_left {
        width: 77%;
        font-size: 10px; }
        @supports (display: contents) {
          .header_left {
            display: contents; } }
        .header_left .phones_block {
          display: none; }
        .header_left .address_block {
          display: none; }
        .header_left .logo {
          width: 94px;
          height: 50px; }
          .header_left .logo:before {
            width: 70px;
            height: 22px; } }
  @media screen and (max-width: 1023px) {
    .header_right {
      order: 1;
      margin-right: 14px; } }
  .header_right .standard_btn {
    background-color: #ed1111;
    color: #fff;
    margin-right: 15px; }
    @media screen and (max-width: 1023px) {
      .header_right .standard_btn {
        margin-right: 0;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        min-width: auto;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px 16px;
        background-image: url(../img/phone.svg); } }
    @media screen and (max-width: 767px) {
      .header_right .standard_btn {
        width: 40px;
        height: 40px; } }
  .header_right .language {
    text-decoration: none;
    color: #002062; }
    @media screen and (max-width: 1023px) {
      .header_right .language {
        display: none; } }
    .header_right .language:before {
      content: '';
      margin-right: 6px;
      width: 21px;
      height: 21px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../img/uk.png); }

.logo {
  background-color: #002062;
  height: 77px;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .logo {
      margin-left: -10px; } }
  @media screen and (max-width: 1023px) {
    .logo {
      height: 64px; } }
  .logo:before {
    content: '';
    display: block;
    width: 125px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url(../img/logo.svg); }
    @media screen and (max-width: 1023px) {
      .logo:before {
        width: 102px;
        height: 32px; } }

.phones_block {
  padding-top: 12px;
  position: relative; }
  @media screen and (max-width: 1023px) {
    .phones_block {
      padding-top: 4px; } }
  .phones_block .svg_icon {
    margin-top: 6px;
    fill: #002062;
    width: 12px;
    height: 12px;
    margin-right: 8px; }
  .phones_block .phone_link {
    display: block;
    text-decoration: none;
    color: #002062;
    font-size: 18px;
    font-weight: 700; }
  .phones_block .show_phones {
    align-self: flex-end;
    width: 10px;
    height: 10px;
    margin-left: 8px;
    position: relative;
    margin-bottom: 8px;
    cursor: pointer; }
    .phones_block .show_phones:before {
      content: '';
      width: 6px;
      height: 6px;
      border-right: 2px solid #002062;
      border-bottom: 2px solid #002062;
      position: absolute;
      transform: rotate(45deg);
      bottom: 3px;
      left: 2px; }
  .phones_block .phones_add {
    position: absolute;
    top: 100%;
    width: 214px !important;
    border-radius: 5px;
    background-color: #ffffff;
    overflow: hidden;
    display: none;
    z-index: 5; }
    .phones_block .phones_add_item {
      padding: 10px 20px 0;
      font-size: 12px;
      transition: all 0.4s; }
      .phones_block .phones_add_item:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e8edf6;
        margin-top: 10px; }
      .phones_block .phones_add_item:first-child {
        padding-top: 18px; }
      .phones_block .phones_add_item:hover {
        background-color: #f1f6ff; }
      .phones_block .phones_add_item .phone_link {
        font-size: 16px; }
    .phones_block .phones_add .work_time {
      transition: all 0.4s;
      font-size: 14px;
      padding: 10px 20px 18px; }
      .phones_block .phones_add .work_time:hover {
        background-color: #f1f6ff; }
      .phones_block .phones_add .work_time .svg_icon {
        margin-top: 3px; }

.address_block {
  width: 186px;
  padding-top: 12px; }
  @media screen and (max-width: 1023px) {
    .address_block {
      padding-top: 4px; } }
  .address_block .svg_icon {
    width: 12px;
    min-width: 12px;
    height: 15px;
    margin-top: 4px;
    margin-right: 10px; }

.email_block {
  padding-top: 12px; }
  @media screen and (max-width: 1023px) {
    .email_block {
      padding-top: 4px; } }
  .email_block .svg_icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 15px;
    height: 12px; }

.header_block:hover .header_bottom.scroll_loaded {
  transform: translateY(0); }

.header_block:hover .header .msMiniCart {
  top: 90px; }
  @media screen and (max-width: 1023px) {
    .header_block:hover .header .msMiniCart {
      top: 72px; } }
  @media screen and (max-width: 767px) {
    .header_block:hover .header .msMiniCart {
      top: 54px; } }

.header_bottom {
  background-color: #fff;
  z-index: 1;
  border: 1px solid #e8edf6;
  transition: all 0.6s; }
  .header_bottom .msMiniCart {
    height: 45px;
    position: fixed;
    top: 90px;
    right: calc(50% - 590px); }
  .header_bottom.scroll {
    animation: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%; }
    .header_bottom.scroll.animated {
      opacity: 1 !important; }
  .header_bottom.scroll_loaded {
    transform: translateY(-100%); }
    .header_bottom.scroll_loaded .msMiniCart {
      top: 100px; }
  @media screen and (max-width: 1023px) {
    .header_bottom {
      align-items: center; } }
  .header_bottom_right {
    position: relative;
    background-color: #fff; }
    @media screen and (max-width: 1023px) {
      .header_bottom_right {
        padding: 10px 0; } }
    @media screen and (max-width: 767px) {
      .header_bottom_right {
        padding: 5px 0;
        height: 50px; } }
    .header_bottom_right .square_btn {
      margin-right: 75px;
      position: relative; }
      .header_bottom_right .square_btn:after {
        content: '';
        position: absolute;
        bottom: 13px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 1px;
        background-color: #ffffff;
        -webkit-transition: width 0.5s;
        -o-transition: width 0.5s;
        transition: width 0.5s; }
      .header_bottom_right .square_btn:hover:after {
        width: 78%; }
      @media screen and (max-width: 1023px) {
        .header_bottom_right .square_btn {
          margin-right: 40px; } }
      .header_bottom_right .square_btn:first-child {
        margin-right: 10px; }
      @media screen and (max-width: 1023px) {
        .header_bottom_right .square_btn:last-child {
          margin-right: 200px; } }
      @media screen and (max-width: 767px) {
        .header_bottom_right .square_btn {
          display: none; } }

@media screen and (max-width: 1023px) {
  .header_bottom {
    display: none; } }

.header_bottom .navigation {
  width: 51.5%; }
  @media screen and (max-width: 1200px) {
    .header_bottom .navigation {
      font-size: 14px; } }
  .header_bottom .navigation_row {
    position: relative;
    margin: 0;
    padding: 10px 0; }
    .header_bottom .navigation_row.mobile_row {
      display: none; }
    .header_bottom .navigation_row:hover > .navigation_block_child {
      opacity: 1;
      left: -18px; }
    .header_bottom .navigation_row:hover > .navigation_block_link {
      color: #ed1111; }
    .header_bottom .navigation_row:hover:after {
      border-color: #ed1111 !important; }
    .header_bottom .navigation_row.active > .navigation_block_link {
      color: #ed1111; }
    .header_bottom .navigation_row.active.parent:after {
      border-right-color: #ed1111;
      border-bottom-color: #ed1111; }
    .header_bottom .navigation_row.parent:after {
      content: '';
      width: 6px;
      height: 6px;
      border-right: 1px solid #002062;
      border-bottom: 1px solid #002062;
      position: absolute;
      transform: translateY(-60%) rotate(45deg);
      top: 50%;
      right: -14px;
      transition: all 0.5s; }
  .header_bottom .navigation_block {
    list-style: none;
    background-color: #fff; }
    .header_bottom .navigation_block_child {
      position: absolute;
      left: -9999px;
      top: 100%;
      width: 260px;
      list-style: none;
      opacity: 0;
      border-radius: 0 0 3px 3px;
      background-color: #ffffff;
      box-shadow: 0px 0px 46px 7px rgba(0, 32, 98, 0.7);
      transition: opacity 0.4s;
      z-index: -1;
      padding: 10px 20px; }
      .header_bottom .navigation_block_child .navigation_row {
        padding: 8px 0; }
      .header_bottom .navigation_block_child .navigation_block_link {
        padding: 0; }
        .header_bottom .navigation_block_child .navigation_block_link:after {
          content: '';
          position: absolute;
          bottom: 3px;
          left: 0;
          width: 0;
          height: 1px;
          background-color: #ed1111;
          -webkit-transition: width 0.5s;
          -o-transition: width 0.5s;
          transition: width 0.5s; }
    .header_bottom .navigation_block_link {
      display: inline-block;
      color: #002062;
      text-decoration: none;
      padding: 12px 0;
      position: relative; }
      .header_bottom .navigation_block_link:after {
        content: '';
        position: absolute;
        bottom: 14px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #ed1111;
        transition: width 0.5s; }
        @media screen and (max-width: 1023px) {
          .header_bottom .navigation_block_link:after {
            content: none; } }
      .header_bottom .navigation_block_link:hover {
        color: #ed1111; }
        .header_bottom .navigation_block_link:hover:after {
          width: 100%; }

.mobile_menu {
  display: none; }
  @media screen and (max-width: 1023px) {
    .mobile_menu {
      position: fixed;
      top: 0;
      left: 0;
      overflow: auto;
      display: block;
      width: 100%;
      max-width: 500px;
      height: 100%;
      padding-top: 67px;
      background-color: #f1f6ff;
      transform: translateX(-100%);
      transition: 0.6s; }
      .mobile_menu.active {
        transform: translateX(0); } }
  @media screen and (max-width: 767px) {
    .mobile_menu {
      padding-top: 53px; } }
  .mobile_menu .social {
    max-width: 350px; }
    .mobile_menu .social_item .svg_icon {
      display: block;
      width: 30px;
      height: 30px;
      fill: #002062; }
  .mobile_menu_info {
    padding: 24px 10px 20px; }
    .mobile_menu_info_title {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 600;
      color: #00143e; }
    .mobile_menu_info_btns {
      margin-bottom: 20px; }
      .mobile_menu_info_btns .square_btn {
        width: 48.3%;
        text-align: center; }
  .mobile_menu .navigation_block {
    display: block;
    padding: 0 10px;
    list-style: none;
    background-color: #fff; }
    .mobile_menu .navigation_block .navigation_row {
      padding: 15px 0;
      border-bottom: 1px solid #e8edf6; }
      .mobile_menu .navigation_block .navigation_row:last-child {
        border-bottom: 0; }
      .mobile_menu .navigation_block .navigation_row.active > .navigation_block_link {
        color: #ed1111;
        font-weight: 600; }
        .mobile_menu .navigation_block .navigation_row.active > .navigation_block_link .svg_icon {
          fill: #ed1111; }
      .mobile_menu .navigation_block .navigation_row .svg_icon {
        margin-right: 12px;
        margin-bottom: 2px;
        width: 14px;
        height: 14px;
        fill: #adbdd9; }
      .mobile_menu .navigation_block .navigation_row .navigation_block_link {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        color: #00143e;
        text-decoration: none;
        text-transform: uppercase; }
      .mobile_menu .navigation_block .navigation_row .open_child {
        position: relative;
        top: -3px;
        left: 8px;
        width: 15px;
        height: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center; }
        .mobile_menu .navigation_block .navigation_row .open_child:before {
          content: '';
          position: relative;
          top: -2px;
          width: 7px;
          height: 7px;
          border-right: 2px solid #00143e;
          border-bottom: 2px solid #00143e;
          transform: rotate(45deg); }
    .mobile_menu .navigation_block_child {
      display: none;
      width: 100% !important;
      padding-left: 26px !important;
      margin: 0;
      list-style: none; }
      .mobile_menu .navigation_block_child .navigation_row {
        border-bottom: 0;
        padding: 16px 0 0; }
        .mobile_menu .navigation_block_child .navigation_row .navigation_block_link {
          text-transform: none; }
  .mobile_menu .phones_add {
    top: auto;
    bottom: 100%;
    color: #002062; }
  .mobile_menu .phones_block, .mobile_menu .address_block, .mobile_menu .email_block {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 0; }
  @media screen and (max-width: 767px) {
    .mobile_menu .email_block .svg_icon {
      margin-top: 2px; } }
  .mobile_menu .address_block b {
    display: block; }

.msMiniCart {
  height: 100%; }
  @media screen and (max-width: 1023px) {
    .msMiniCart {
      margin-left: auto;
      margin-right: 14px; } }
  .msMiniCart:hover .miniCart_block {
    right: 0;
    opacity: 1; }
  .msMiniCart_mobile {
    display: none; }
    .msMiniCart_mobile .red {
      color: #ed1111; }

.miniCart {
  width: 44px;
  height: 44px;
  margin-right: 13px;
  border-radius: 50%;
  position: relative;
  background-color: #002062;
  cursor: pointer; }
  @media screen and (max-width: 1023px) {
    .miniCart {
      margin-right: 0; }
      .miniCart_block_wrapper {
        display: none; } }
  @media screen and (max-width: 767px) {
    .miniCart {
      width: 40px;
      height: 40px; } }
  .miniCart .svg_icon {
    width: 21px;
    height: 18px;
    fill: #fff;
    margin-right: 2px; }
  .miniCart_count {
    position: absolute;
    border-radius: 50%;
    background-color: #ed1111;
    color: #fff;
    font-size: 14px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    top: 0;
    right: -13px; }
    @media screen and (max-width: 1023px) {
      .miniCart_count {
        right: -4px;
        width: 20px;
        height: 20px;
        font-size: 12px;
        line-height: 20px; } }
  .miniCart_block {
    position: absolute;
    top: 100%;
    right: 9999px;
    opacity: 0;
    transition: opacity 0.5s;
    background-color: #fff;
    padding: 12px 20px 22px;
    width: 380px;
    border-radius: 0 0 3px 3px;
    z-index: -1;
    font-size: 14px;
    box-shadow: 0 0 29px 4px rgba(0, 0, 0, 0.3); }
    .miniCart_block_title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px; }
    .miniCart_block_total_text {
      margin-right: 5px; }
    .miniCart_block_total_price {
      color: #ed1111;
      font-weight: 700; }
    .miniCart_block_total .catalog_btn, .miniCart_block_total .add_btn {
      width: 190px; }
  .miniCart_item {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8edf6; }
    .miniCart_item_link {
      width: 54px;
      height: 70px;
      border-radius: 5px;
      border: 1px solid #e8edf6;
      margin-right: 15px;
      flex: 0 0 auto; }
    .miniCart_item_remove {
      flex: 0 0 auto; }
      .miniCart_item_remove .btn_remove {
        border: none;
        height: 20px;
        width: 20px;
        border-radius: 0;
        position: relative;
        padding: 0; }
        .miniCart_item_remove .btn_remove:before, .miniCart_item_remove .btn_remove:after {
          content: '';
          position: absolute;
          top: 6px;
          right: 0;
          width: 14px;
          height: 2px;
          background-color: #abbad5; }
        .miniCart_item_remove .btn_remove:before {
          transform: rotate(45deg); }
        .miniCart_item_remove .btn_remove:after {
          transform: rotate(-45deg); }
    .miniCart_item_text {
      flex: 1 0 auto; }
    .miniCart_item_title {
      font-weight: 700;
      text-decoration: none;
      color: #00143e; }
    .miniCart_item_count.jq-number {
      margin-bottom: 10px; }
    .miniCart_item_count .jq-number__field {
      position: relative;
      display: inline-block;
      vertical-align: middle; }
      .miniCart_item_count .jq-number__field .miniCart_item_count {
        width: 1px;
        height: 1px;
        position: absolute;
        left: 5px;
        top: 2px;
        opacity: 0; }
      .miniCart_item_count .jq-number__field .buffer {
        display: inline-block; }
    .miniCart_item_count .jq-number__spin {
      width: 14px;
      height: 14px;
      border: 1px solid #e8edf6;
      background-color: #f4f8ff;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      vertical-align: middle;
      text-align: center;
      cursor: pointer; }
      .miniCart_item_count .jq-number__spin:before {
        font-weight: 700; }
      .miniCart_item_count .jq-number__spin.minus:before {
        content: '-'; }
      .miniCart_item_count .jq-number__spin.plus:before {
        content: '+'; }
    .miniCart_item_price {
      font-weight: 700; }
    .miniCart_item_caption {
      margin-left: 6px;
      font-weight: 400; }

.slider {
  background-color: #002062;
  overflow: hidden;
  position: relative; }
  .slider:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;
    background-image: url(../img/dots.png);
    opacity: 0; }
    @media screen and (max-width: 1024px) {
      .slider:before {
        display: none; } }
  .slider:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: contain;
    background-image: url(../img/dots.png);
    opacity: 0;
    transform: rotate(180deg); }
    @media screen and (max-width: 1024px) {
      .slider:after {
        display: none; } }
  .slider.active:before, .slider.active:after {
    animation-duration: 1s;
    animation-name: dots;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 1023px) {
      .slider.active:before, .slider.active:after {
        animation: none; } }
  .slider.active .slider_item_left {
    animation-duration: 2s;
    animation-name: dots;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 1023px) {
      .slider.active .slider_item_left {
        animation: none; } }
  .slider.active .slider_item .circle {
    animation-duration: 1s;
    animation-name: circle;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 1023px) {
      .slider.active .slider_item .circle {
        animation: none; } }
  .slider.active .slider_item .picture {
    animation-duration: 2s;
    animation-name: dots;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 1023px) {
      .slider.active .slider_item .picture {
        animation: none; } }
  .slider_wrapper {
    position: relative; }
    .slider_wrapper .owl-dots {
      position: absolute;
      text-align: center;
      left: 0;
      bottom: 10px; }
      @media screen and (max-width: 767px) {
        .slider_wrapper .owl-dots {
          position: static;
          padding-top: 10px;
          padding-bottom: 10px; } }
  .slider_img_block {
    position: absolute;
    top: -100px;
    left: -100px;
    width: calc(100% + 130px);
    height: calc(100% + 200px);
    z-index: 10; }
  .slider_item {
    height: 460px;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .slider_item {
        height: 350px; } }
    @media screen and (max-width: 767px) {
      .slider_item {
        height: 525px;
        padding: 25px 0 30px;
        flex-direction: column;
        text-align: center; } }
    .slider_item_left {
      width: 60.2%;
      padding-left: 40px;
      opacity: 0;
      position: relative;
      z-index: 5; }
      @media screen and (max-width: 1024px) {
        .slider_item_left {
          padding-left: 10px;
          width: 61%;
          opacity: 1; } }
      @media screen and (max-width: 767px) {
        .slider_item_left {
          width: 100%;
          padding: 0;
          margin-bottom: 20px; }
          @supports (display: contents) {
            .slider_item_left {
              display: contents; }
              .slider_item_left .slider_item_title, .slider_item_left .slider_item_desc, .slider_item_left .catalog_btn, .slider_item_left .add_btn {
                position: relative;
                z-index: 4; }
              .slider_item_left .catalog_btn, .slider_item_left .add_btn {
                order: 3; } } }
    .slider_item_title {
      color: #fff;
      font-size: 40px;
      font-weight: 700;
      line-height: 1.15;
      margin-bottom: 30px; }
      @media screen and (max-width: 1024px) {
        .slider_item_title {
          font-size: 26px;
          margin-bottom: 24px; } }
      @media screen and (max-width: 767px) {
        .slider_item_title {
          font-size: 18px;
          margin-bottom: 20px; } }
    .slider_item_desc {
      color: #a4c2ff;
      margin-bottom: 36px;
      width: 80%; }
      @media screen and (max-width: 1024px) {
        .slider_item_desc {
          margin-bottom: 26px; } }
      @media screen and (max-width: 767px) {
        .slider_item_desc {
          width: 100%; }
          @supports (display: contents) {
            .slider_item_desc {
              margin-bottom: 0; } } }
    .slider_item .circle {
      position: absolute;
      border-radius: 50%;
      border: 200px solid #002c86;
      width: 81.6%;
      z-index: 1;
      bottom: 100%;
      right: -90%; }
      @media screen and (max-width: 1024px) {
        .slider_item .circle {
          width: 126%;
          border-width: 170px;
          bottom: auto;
          top: -112%;
          right: -86%; } }
      @media screen and (max-width: 767px) {
        .slider_item .circle {
          width: 500px;
          height: 500px;
          border-width: 100px;
          top: -312px;
          right: -294px; } }
      .slider_item .circle:before {
        content: "";
        padding-top: 100%;
        float: left; }
    .slider_item .picture {
      padding-right: 69px;
      position: relative;
      z-index: 2;
      opacity: 0; }
      @media screen and (max-width: 1024px) {
        .slider_item .picture {
          padding-right: 30px;
          width: 33%;
          opacity: 1; } }
      @media screen and (max-width: 767px) {
        .slider_item .picture {
          padding: 0;
          width: 162px; }
          @supports (display: contents) {
            .slider_item .picture {
              order: 1;
              margin: 34px 0; } } }

.vantages_block {
  padding-top: 50px;
  padding-bottom: 20px; }
  @media screen and (max-width: 1023px) {
    .vantages_block {
      padding-top: 35px; } }
  @media screen and (max-width: 767px) {
    .vantages_block {
      padding-top: 25px;
      padding-bottom: 0; } }

.vantages {
  flex-wrap: wrap;
  text-align: center; }
  .vantages_item {
    margin-bottom: 50px;
    width: 26.1%; }
    .vantages_item:nth-child(3n) .vantages_item_top:after, .vantages_item:last-child .vantages_item_top:after {
      content: none; }
    @media screen and (max-width: 1023px) {
      .vantages_item {
        width: 33%; } }
    @media screen and (max-width: 767px) {
      .vantages_item {
        margin-bottom: 30px;
        width: 47%; } }
    .vantages_item .svg_icon {
      fill: #7384a7;
      width: 52px;
      height: 45px;
      margin-right: 22px;
      display: inline-block;
      vertical-align: middle;
      transition: fill 0.4s;
      margin-bottom: 8px; }
      @media screen and (max-width: 1023px) {
        .vantages_item .svg_icon {
          margin-right: 10px; } }
      @media screen and (max-width: 767px) {
        .vantages_item .svg_icon {
          width: 32px;
          height: 28px;
          margin-bottom: 6px; } }
    .vantages_item_number {
      font-size: 60px;
      font-family: 'Bebas Neue', sans-serif;
      font-weight: 700;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      transition: color 0.4s; }
      @media screen and (max-width: 767px) {
        .vantages_item_number {
          font-size: 38px; } }
    .vantages_item_top {
      margin-bottom: 24px;
      display: inline-block;
      padding: 0 20px;
      background-color: #fff;
      position: relative;
      width: 240px; }
      .vantages_item_top:after {
        content: '';
        position: absolute;
        height: 0;
        width: 250px;
        top: 48%;
        left: 100%;
        border-top: 1px dashed #002062; }
      @media screen and (max-width: 1023px) {
        .vantages_item_top {
          width: auto; }
          .vantages_item_top:after {
            content: none; } }
      @media screen and (max-width: 767px) {
        .vantages_item_top {
          margin-bottom: 14px;
          padding: 0; } }
    .vantages_item_title {
      font-size: 18px;
      font-weight: 700; }
      @media screen and (max-width: 1023px) {
        .vantages_item_title {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .vantages_item_title {
          font-size: 12px; } }
    .vantages_item:hover .svg_icon {
      fill: #ed1111; }
    .vantages_item:hover .vantages_item_number {
      color: #ed1111; }

.prefooter {
  color: #fff;
  padding-top: 34px;
  padding-bottom: 34px;
  background-color: #002061;
  font-size: 14px; }
  @media screen and (max-width: 767px) {
    .prefooter {
      padding-top: 18px;
      padding-bottom: 18px; } }
  .prefooter .show_phones:before {
    border-color: #fff; }
  .prefooter .phones_add {
    top: auto;
    bottom: calc(100% - 15px);
    color: #002062; }
    .prefooter .phones_add .phone_link {
      color: #002062; }
    .prefooter .phones_add .svg_icon {
      fill: #002062; }
  .prefooter_left {
    width: 49%; }
    @media screen and (max-width: 1200px) {
      .prefooter_left {
        width: 100%; } }
    @media screen and (max-width: 1023px) {
      @supports (display: contents) {
        .prefooter_left {
          display: flex;
          justify-content: space-between;
          align-items: center; } } }
  .prefooter_top {
    padding: 0 0 35px 27px;
    border-bottom: 1px solid #11398b; }
    @media screen and (max-width: 1200px) {
      .prefooter_top {
        padding: 0 27px 35px 27px; }
        .prefooter_top.flex {
          justify-content: space-between;
          align-items: center; } }
    @media screen and (max-width: 1023px) {
      .prefooter_top {
        padding: 0;
        border-bottom: none; }
        @supports (display: contents) {
          .prefooter_top {
            display: contents; } } }
    .prefooter_top .logo {
      padding: 0;
      width: 125px;
      height: 40px;
      margin-right: 58px;
      margin-left: 0; }
      @media screen and (max-width: 1023px) {
        .prefooter_top .logo {
          margin-right: 0; } }
  @media screen and (max-width: 1023px) {
    @supports (display: contents) {
      .prefooter .social {
        order: 3; } } }
  .prefooter .social .svg_icon {
    width: 25px;
    height: 25px;
    display: block; }
    @media screen and (max-width: 767px) {
      .prefooter .social .svg_icon {
        width: 20px;
        height: 20px; } }
  .prefooter .social_item {
    display: block;
    margin-left: 15px; }
    @media screen and (max-width: 767px) {
      .prefooter .social_item {
        margin-left: 10px; } }
  .prefooter .social .text {
    padding-right: 8px; }
    @media screen and (max-width: 1023px) {
      .prefooter .social .text {
        display: none; } }
  .prefooter .svg_icon {
    fill: #fff; }
  .prefooter_bottom {
    padding-top: 12px; }
    @media screen and (max-width: 1023px) {
      .prefooter_bottom {
        padding-top: 20px; }
        .prefooter_bottom.flex {
          align-items: center; }
        @supports (display: contents) {
          .prefooter_bottom {
            padding-top: 0;
            width: 55%;
            order: 1; } } }
    @media screen and (max-width: 767px) {
      .prefooter_bottom {
        display: none; } }
    .prefooter_bottom .phone_link {
      color: #fff; }
    .prefooter_bottom .phones_block, .prefooter_bottom .address_block, .prefooter_bottom .email_block {
      padding-top: 20px; }
      @media screen and (max-width: 1023px) {
        .prefooter_bottom .phones_block, .prefooter_bottom .address_block, .prefooter_bottom .email_block {
          padding-top: 0; } }
    .prefooter_bottom .mail_link {
      color: #fff; }
    @media screen and (max-width: 1023px) {
      .prefooter_bottom .email_block {
        display: none; }
      @supports (display: contents) {
        .prefooter_bottom .address_block {
          order: -1; } } }
  .prefooter_navigation {
    width: 42.5%; }
    @media screen and (max-width: 1200px) {
      .prefooter_navigation {
        display: none; } }
    .prefooter_navigation_title {
      margin-bottom: 18px;
      width: 100%;
      font-size: 16px; }
    .prefooter_navigation_right {
      flex-wrap: wrap;
      width: 55%; }
    .prefooter_navigation_ul {
      list-style: none; }
    .prefooter_navigation_row {
      margin-bottom: 10px; }
      .prefooter_navigation_row:last-child {
        margin-bottom: 0; }
    .prefooter_navigation_link {
      color: #a4c2ff;
      text-decoration: none; }

.footer {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8997c0; }
  @media screen and (max-width: 767px) {
    .footer {
      font-size: 10px; }
      .footer .first {
        display: none; } }
  .footer .st_lt {
    color: #8997c0;
    text-decoration: none; }
    .footer .st_lt:before {
      content: '';
      display: inline-block;
      width: 60px;
      height: 50px;
      margin-right: 4px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../img/st-lt.jpg); }
      @media screen and (max-width: 767px) {
        .footer .st_lt:before {
          width: 30px;
          height: 30px; } }

.catalog_block {
  background-color: #f1f6ff;
  padding-top: 50px;
  padding-bottom: 40px; }
  @media screen and (max-width: 1023px) {
    .catalog_block {
      padding-top: 34px;
      padding-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .catalog_block {
      padding-top: 25px; } }

.price_wrapper {
  display: flex; }

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; }
  .grid__aside {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 2;
    gap: 20px; }
  .grid__outer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-column: 2 / 4; }
  .grid__catalog {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; }
    .grid__catalog > * {
      margin: 0 !important;
      width: 100% !important; }

.grid-sort {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px; }
  .grid-sort__list {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px; }
  .grid-sort__text {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    color: #00143E; }
  .grid-sort__item {
    color: #00143E;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    text-decoration: none;
    transition: color 0.3s; }
    .grid-sort__item.active {
      color: #ED1111 !important; }

.filter {
  --kordion-icon-color-hover: #002062;
  display: flex;
  flex-direction: column;
  border: 1px solid #E8EDF6;
  background-color: #fff;
  border-radius: 10px; }
  .filter__item {
    padding: 30px 20px;
    display: flex;
    flex-direction: column; }
    .filter__item:not(:last-child) {
      border-bottom: 1px solid #E8EDF6; }
    .filter__item .mse2_number_slider {
      width: 95%;
      height: 5px;
      border-radius: 0;
      border: none !important;
      background-color: #E8EDF6;
      cursor: pointer; }
      .filter__item .mse2_number_slider .ui-slider-range {
        background-color: #002062; }
      .filter__item .mse2_number_slider .ui-slider-handle {
        top: -5px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: none;
        outline: none;
        background-color: #002062;
        cursor: pointer;
        transition: background-color 0,3s; }
        .filter__item .mse2_number_slider .ui-slider-handle:hover {
          background-color: #002062; }
  .filter .square_btn {
    display: inline-flex !important; }
  .filter__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px; }
  .filter__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 150%; }
  .filter__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #002062; }
  .filter__content {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px; }
  .filter-menu {
    display: flex;
    flex-direction: column; }
    .filter-menu__item {
      display: flex;
      flex-direction: column;
      gap: 10px; }
    .filter-menu__link {
      padding: 11px 10px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      color: #00143E;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      text-decoration: none;
      transition: color 0.3s, background-color 0.3s; }
      .filter-menu__link:hover {
        background-color: #F5F8FE; }
      .filter-menu__link.active {
        color: #ed1111; }
    .filter-menu__nested {
      margin-left: 15px; }
  .filter__line {
    width: 100%;
    height: 2px;
    background-color: #002062; }
  .filter__price {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    justify-content: flex-start;
    gap: 13px; }
    .filter__price .input, .filter__price .input_label {
      margin: 0; }
  .filter__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px; }
  .filter-checkbox {
    --checkbox-bg-color: #fff;
    --checkbox-bg-color-active: #002062;
    --checkbox-bg-color-hover: #fff;
    --checkbox-icon-color: #fff;
    --checkbox-icon-size: 20px;
    --checkbox-size: 20px;
    --checkbox-stroke-color: #E8EDF6;
    --checkbox-stroke-color-active: #002062;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    cursor: pointer;
    user-select: none; }
    .filter-checkbox:hover .checkbox__item {
      background-color: var(--checkbox-bg-color-hover); }
    .filter-checkbox__input:checked ~ .filter-checkbox__item {
      background-color: var(--checkbox-stroke-color-active);
      border-color: var(--checkbox-stroke-color-active); }
      .filter-checkbox__input:checked ~ .filter-checkbox__item::before {
        background-color: var(--checkbox-icon-color); }
    .filter-checkbox__item {
      position: relative;
      width: var(--checkbox-size);
      height: var(--checkbox-size);
      display: block;
      flex-shrink: 0;
      background-color: var(--checkbox-bg-color);
      border: 1px solid var(--checkbox-stroke-color);
      border-radius: var(--radius-secondary);
      transition: border-color 0.3s, background-color 0.3s; }
      .filter-checkbox__item::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: var(--checkbox-icon-size);
        height: var(--checkbox-icon-size);
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M3.33325 8.66602L5.29281 10.6256C5.68334 11.0161 6.3165 11.0161 6.70703 10.6256L12.6666 4.66602' stroke='white' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain; }
    .filter-checkbox__text {
      position: relative;
      z-index: 1;
      color: #00143E;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%; }
      .filter-checkbox__text a {
        color: #00143E;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-position: under;
        transition: color 0.3s; }
        .filter-checkbox__text a:hover {
          color: #002062; }

.visually-hidden {
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0); }

.catalog {
  flex-wrap: wrap; }
  @media screen and (max-width: 1023px) {
    .catalog .link_img {
      height: 200px; } }
  .catalog.owl-carousel .catalog_item {
    margin-bottom: 0; }
    .catalog.owl-carousel .catalog_item img {
      width: auto; }
  .catalog .catalog_btn, .catalog .add_btn {
    width: 200px;
    min-width: auto; }
    @media screen and (max-width: 1200px) {
      .catalog .catalog_btn, .catalog .add_btn {
        width: calc(100% - 140px); } }
  .catalog_item {
    border-radius: 10px;
    border: 1px solid #e8edf6;
    background-color: #fff;
    width: 32.2%;
    margin-bottom: 20px;
    padding: 20px 20px 28px;
    position: relative; }
    @media screen and (max-width: 1200px) {
      .catalog_item {
        display: block; } }
    @media screen and (max-width: 1023px) {
      .catalog_item {
        width: 48.7%; } }
    @media screen and (max-width: 767px) {
      .catalog_item {
        width: 100%;
        padding: 12px 20px 20px;
        margin-bottom: 10px; }
        .catalog_item_desc {
          font-size: 10px; } }
    .catalog_item .big_img {
      display: none; }
    .catalog_item_tag {
      left: 20px;
      top: 20px;
      position: absolute;
      width: 80px;
      border-radius: 5px;
      text-align: center;
      padding: 9px; }
      @media screen and (max-width: 1200px) {
        .catalog_item_tag {
          padding: 8px;
          font-size: 12px;
          width: 60px; } }
      @media screen and (max-width: 1023px) {
        .catalog_item_tag {
          left: 10px;
          top: 10px; } }
      @media screen and (max-width: 767px) {
        .catalog_item_tag {
          padding: 2px; } }
      .catalog_item_tag.popular {
        background-color: #fff0ee;
        color: #ed1111;
        font-weight: 500; }
        .catalog_item_tag.popular .svg_icon {
          fill: #ed1111;
          position: relative;
          top: 3px;
          width: 12px;
          height: 15px;
          display: inline;
          margin-right: 6px; }
          @media screen and (max-width: 1023px) {
            .catalog_item_tag.popular .svg_icon {
              margin-right: 4px;
              width: 11px;
              height: 13px; } }
      .catalog_item_tag.new {
        background-color: #f3fff5;
        line-height: 1.2;
        color: #44b759; }
        .catalog_item_tag.new b {
          display: block; }
    .catalog_item_form {
      font-size: 14px;
      flex-direction: column;
      height: 100%; }
      .catalog_item_form .link {
        padding: 20px 0;
        position: relative; }
        .catalog_item_form .link .product_stamp {
          width: 60px;
          height: 60px;
          top: -17px;
          right: 15px;
          transform: rotate(15deg); }
    .catalog_item_title {
      display: block;
      text-decoration: none;
      color: #002062;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom: 16px;
      border-bottom: 1px solid #e8edf6; }
      @media screen and (max-width: 1023px) {
        .catalog_item_title {
          font-size: 18px;
          padding-bottom: 12px; } }
      @media screen and (max-width: 767px) {
        .catalog_item_title {
          font-size: 14px;
          padding-bottom: 10px; } }
      .catalog_item_title_add {
        font-size: 14px;
        text-transform: none;
        font-weight: 400; }
        @media screen and (max-width: 767px) {
          .catalog_item_title_add {
            font-size: 12px; } }
    .catalog_item_top {
      padding-bottom: 40px; }
      @media screen and (max-width: 1023px) {
        .catalog_item_top {
          padding-bottom: 14px; } }
    .catalog_item_bottom {
      border-top: 1px solid #e8edf6;
      padding-top: 18px; }
    .catalog_item_prices {
      margin-bottom: 12px; }
    .catalog_item_radio {
      position: relative;
      padding-left: 26px;
      color: #8997c0;
      cursor: pointer; }
      @media screen and (max-width: 1023px) {
        .catalog_item_radio {
          font-size: 12px; } }
      @media screen and (max-width: 767px) {
        .catalog_item_radio {
          font-size: 10px; } }
      .catalog_item_radio .checked {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        border: 1px solid #00143e;
        left: 0;
        top: 2px;
        transition: all 0.4s; }
        @media screen and (max-width: 1023px) {
          .catalog_item_radio .checked {
            top: -1px; } }
        .catalog_item_radio .checked:before {
          content: '';
          width: 6px;
          height: 8px;
          transform: rotate(45deg);
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          position: absolute;
          bottom: 4px;
          right: 4px; }
      .catalog_item_radio .input_hidden {
        opacity: 0;
        position: absolute;
        width: 2px;
        height: 2px; }
        .catalog_item_radio .input_hidden:checked + .checked {
          background-color: #00143e; }
      .catalog_item_radio .price {
        display: block;
        font-size: 20px;
        font-weight: 700;
        color: #002062; }
        @media screen and (max-width: 1023px) {
          .catalog_item_radio .price {
            font-size: 18px; } }
      .catalog_item_radio .old_price {
        display: inline-block;
        position: relative; }
        @media screen and (max-width: 1023px) {
          .catalog_item_radio .old_price {
            font-size: 14px; } }
        @media screen and (max-width: 767px) {
          .catalog_item_radio .old_price {
            font-size: 12px; } }
        .catalog_item_radio .old_price:before {
          content: '';
          background-color: #ed4530;
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          top: 50%; }
    @media screen and (min-width: 1201px) {
      .catalog_item.big {
        width: 66.1%;
        padding: 20px 28px 28px; }
        .catalog_item.big .catalog_item_tag {
          left: 18px;
          top: 18px; }
        .catalog_item.big .big_img {
          display: flex; }
        .catalog_item.big .small_img {
          display: none; }
        .catalog_item.big .catalog_item_form {
          width: 252px; }
        .catalog_item.big .catalog_item_title {
          text-align: left;
          margin-bottom: 24px; }
        .catalog_item.big .link {
          width: calc(100% - 252px);
          padding: 0 20px;
          position: relative; }
          .catalog_item.big .link .product_stamp {
            top: 10px;
            right: 60px;
            transform: rotate(15deg); }
        .catalog_item.big .catalog_item_bottom {
          border-top: none; }
        .catalog_item.big .catalog_item_prices {
          display: block;
          padding-bottom: 10px;
          border-bottom: 1px solid #e8edf6;
          margin-bottom: 20px; }
        .catalog_item.big .catalog_item_radio {
          display: block;
          margin-bottom: 20px; }
        .catalog_item.big .catalog_item_order {
          flex-direction: column; }
        .catalog_item.big .catalog_btn, .catalog_item.big .add_btn {
          margin-top: 22px; } }
  .catalog_jump {
    padding: 20px 60px;
    text-decoration: none;
    color: #ed1111;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 1200px) {
      .catalog_jump {
        width: 100%;
        padding: 60px; } }
    @media screen and (max-width: 1023px) {
      .catalog_jump {
        font-size: 16px;
        width: 48.7%;
        padding: 20px 60px; } }
    @media screen and (max-width: 767px) {
      .catalog_jump {
        width: 100%;
        padding: 50px; } }
    .catalog_jump_circle {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
      border-radius: 50%;
      border: 1px solid #ffd7d7;
      transition: all 0.4s; }
      @media screen and (max-width: 1023px) {
        .catalog_jump_circle {
          width: 90px;
          height: 90px; } }
      .catalog_jump_circle .svg_icon {
        width: 44px;
        fill: #ed1111;
        height: 20px; }
        @media screen and (max-width: 1023px) {
          .catalog_jump_circle .svg_icon {
            width: 39px;
            height: 18px; } }
    .catalog_jump_text {
      text-align: center; }
    .catalog_jump_title {
      display: inline;
      transition: all 0.4s;
      border-bottom: 2px solid rgba(237, 17, 17, 0.4); }
    .catalog_jump:hover .catalog_jump_circle {
      border: 1px solid #ed1111; }
    .catalog_jump:hover .catalog_jump_title {
      border-color: transparent; }
    .catalog_jump:active .catalog_jump_circle {
      border: 1px solid #ed1111;
      background-color: #ed1111; }
      .catalog_jump:active .catalog_jump_circle .svg_icon {
        fill: #fff; }
    .catalog_jump:active .catalog_jump_title {
      border-color: transparent; }

.about_company {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f1f6ff;
  font-size: 18px; }
  @media screen and (max-width: 1200px) {
    .about_company {
      padding-top: 40px; } }
  @media screen and (max-width: 767px) {
    .about_company {
      font-size: 12px;
      padding-bottom: 15px; } }
  .about_company:not(.home) .text_block {
    position: relative;
    /*&:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: calc(50% - 580px);
            background-color: #fff;
            @include media_laptop {
                width: 10px;
            }
            @include media_phone {
                height: 240px;
            }
        }*/ }
    @media screen and (max-width: 1023px) {
      .about_company:not(.home) .text_block {
        font-size: 14px; } }
    @media screen and (max-width: 767px) {
      .about_company:not(.home) .text_block {
        flex-wrap: wrap; } }
    .about_company:not(.home) .text_block .about_company_left {
      background-color: #fff;
      position: relative; }
      @media screen and (max-width: 767px) {
        .about_company:not(.home) .text_block .about_company_left {
          display: block;
          width: 100%;
          height: 240px;
          margin-bottom: 15px; } }
    @media screen and (max-width: 767px) {
      .about_company:not(.home) .text_block .about_company_img {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: auto; } }
  .about_company.home {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #002062;
    color: #fff;
    font-size: 16px; }
    @media screen and (max-width: 1023px) {
      .about_company.home {
        font-size: 14px;
        padding-top: 35px;
        padding-bottom: 35px; } }
    @media screen and (max-width: 767px) {
      .about_company.home {
        font-size: 10px; } }
    .about_company.home .text_block {
      margin-bottom: 40px; }
  .about_company .section_title {
    color: #fff;
    margin-bottom: 45px; }
    @media screen and (max-width: 1023px) {
      .about_company .section_title {
        margin-bottom: 35px; } }
    @media screen and (max-width: 767px) {
      .about_company .section_title {
        margin-bottom: 25px; } }
  .about_company_left {
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #002c86;
    width: 32.2%;
    min-height: 450px;
    position: relative;
    float: left;
    margin-right: 1.5%; }
    @media screen and (max-width: 1023px) {
      .about_company_left {
        min-height: auto; } }
    @media screen and (max-width: 767px) {
      .about_company_left {
        display: none; } }
  .about_company_img {
    width: 53.5%; }
  .about_company_right {
    display: block; }
    @media screen and (max-width: 767px) {
      .about_company_right {
        width: 100%; } }

.principles {
  text-align: center;
  color: #fff;
  flex-wrap: wrap; }
  .principles.owl-carousel .principles_item {
    width: 100%;
    margin-bottom: 0;
    margin-right: 0; }
  .principles_item {
    margin-bottom: 20px;
    width: 32%;
    margin-right: 2%;
    border-radius: 5px;
    padding: 20px 0; }
    @media screen and (max-width: 767px) {
      .principles_item {
        padding: 14px 0;
        width: 100%;
        margin-right: 0; } }
    .principles_item.purple {
      background-color: #678bd4; }
      .principles_item.purple .counter {
        background-color: #678bd4; }
      .principles_item.purple .principles_item_counter:after {
        background-color: #5779be; }
    .principles_item.green {
      background-color: #78bcc4; }
      .principles_item.green .counter {
        background-color: #78bcc4; }
      .principles_item.green .principles_item_counter:after {
        background-color: #67a5ac; }
    .principles_item.blue {
      background-color: #00c2f6;
      margin-right: 0; }
      .principles_item.blue .counter {
        background-color: #00c2f6; }
      .principles_item.blue .principles_item_counter:after {
        background-color: #00a7d4; }
    .principles_item_text {
      padding: 0 50px; }
      @media screen and (max-width: 1023px) {
        .principles_item_text {
          padding: 0 20px; } }
      @media screen and (max-width: 767px) {
        .principles_item_text {
          font-size: 12px; } }
    .principles_item_counter {
      margin-bottom: 12px;
      position: relative;
      line-height: 1; }
      .principles_item_counter:before, .principles_item_counter:after {
        content: '';
        width: 50%;
        height: 1px;
        bottom: 50%;
        position: absolute; }
      .principles_item_counter:before {
        left: 0;
        background-color: #fff; }
      .principles_item_counter:after {
        right: 0; }
      .principles_item_counter .counter {
        font-size: 40px;
        font-weight: 700;
        display: inline-block;
        padding: 0 10px;
        position: relative;
        z-index: 1; }
        @media screen and (max-width: 1023px) {
          .principles_item_counter .counter {
            font-size: 34px; } }
        @media screen and (max-width: 767px) {
          .principles_item_counter .counter {
            font-size: 30px; } }

.owl-dots {
  text-align: center;
  padding-top: 25px; }
  @media screen and (max-width: 767px) {
    .owl-dots {
      padding-top: 20px; } }
  .owl-dots button.owl-dot {
    width: 10px;
    height: 10px;
    background-color: #ccdcf8;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px; }
    @media screen and (max-width: 767px) {
      .owl-dots button.owl-dot {
        width: 8px;
        height: 8px;
        margin-right: 8px; } }
    .owl-dots button.owl-dot:last-child {
      margin-right: 0; }
    .owl-dots button.owl-dot.active {
      background-color: #ed1111; }

.our_partners_block {
  padding-top: 50px;
  padding-bottom: 60px;
  background-color: #f1f6ff; }
  @media screen and (max-width: 1023px) {
    .our_partners_block {
      padding-top: 35px;
      padding-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    .our_partners_block {
      padding-top: 30px;
      padding-bottom: 30px; } }

.our_partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 100px;
  position: relative; }
  @media screen and (max-width: 1023px) {
    .our_partners {
      padding: 0; } }
  .our_partners_item {
    height: 122px;
    padding: 0 20px;
    background-color: #fff; }
    @media screen and (max-width: 767px) {
      .our_partners_item {
        height: 80px; } }
  .our_partners_img {
    width: auto !important;
    max-width: 100%;
    transition: all ease 0.4s; }
  .our_partners .owl-nav, .our_partners .owl-nav.disabled {
    display: flex;
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    top: 0; }
    @media screen and (max-width: 1023px) {
      .our_partners .owl-nav, .our_partners .owl-nav.disabled {
        display: none; } }
    .our_partners .owl-nav .owl-prev, .our_partners .owl-nav .owl-next, .our_partners .owl-nav.disabled .owl-prev, .our_partners .owl-nav.disabled .owl-next {
      position: absolute;
      top: 0;
      height: 122px; }
    .our_partners .owl-nav .owl-prev, .our_partners .owl-nav.disabled .owl-prev {
      left: 0; }
    .our_partners .owl-nav .owl-next, .our_partners .owl-nav.disabled .owl-next {
      right: 0; }

.owl-nav {
  display: flex;
  justify-content: space-between; }
  .owl-nav .owl-prev, .owl-nav .owl-next {
    width: 80px;
    height: 80px;
    border-radius: 3px;
    background-color: #fff !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s; }
    @media screen and (max-width: 1023px) {
      .owl-nav .owl-prev, .owl-nav .owl-next {
        width: 44px;
        height: 44px; } }
    .owl-nav .owl-prev .svg_icon, .owl-nav .owl-next .svg_icon {
      width: 30px;
      height: 14px;
      fill: #bdc9de;
      transition: all 0.4s; }
      @media screen and (max-width: 1023px) {
        .owl-nav .owl-prev .svg_icon, .owl-nav .owl-next .svg_icon {
          width: 22px;
          height: 10px; } }
    .owl-nav .owl-prev:hover, .owl-nav .owl-prev:focus, .owl-nav .owl-next:hover, .owl-nav .owl-next:focus {
      background-color: #ed1111 !important; }
      .owl-nav .owl-prev:hover .svg_icon, .owl-nav .owl-prev:focus .svg_icon, .owl-nav .owl-next:hover .svg_icon, .owl-nav .owl-next:focus .svg_icon {
        fill: #fff; }
    .owl-nav .owl-prev:focus, .owl-nav .owl-next:focus {
      outline: none; }
  .owl-nav .owl-prev .svg_icon {
    transform: rotate(180deg); }

.articles_main {
  padding-top: 50px;
  padding-bottom: 60px; }
  @media screen and (max-width: 1023px) {
    .articles_main {
      padding-top: 35px;
      padding-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    .articles_main {
      padding-top: 25px;
      padding-bottom: 20px; } }
  .articles_main .section_title {
    margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      .articles_main .section_title {
        margin-bottom: 16px; } }
  .articles_main_all {
    text-align: right;
    margin-bottom: 22px; }
    @media screen and (max-width: 1023px) {
      .articles_main_all {
        margin-bottom: 16px; } }
    @media screen and (max-width: 767px) {
      .articles_main_all {
        display: none; } }
    .articles_main_all.mob {
      display: none; }
      @media screen and (max-width: 767px) {
        .articles_main_all.mob {
          display: block;
          margin: 0; }
          .articles_main_all.mob .link {
            top: auto;
            margin: 0 auto;
            display: block;
            text-align: center;
            font-size: 16px;
            padding: 20px 0; } }
    .articles_main_all .link {
      position: relative;
      top: -25px; }
      @media screen and (max-width: 1023px) {
        .articles_main_all .link {
          top: -20px; } }
  .articles_main.promo_page .articles_item:nth-child(3n) {
    margin-right: 2%; }
    @media screen and (max-width: 1023px) {
      .articles_main.promo_page .articles_item:nth-child(3n) {
        margin-right: 0; } }
    @media screen and (max-width: 767px) {
      .articles_main.promo_page .articles_item:nth-child(3n) {
        margin-right: 2%; } }
  .articles_main .articles_item:nth-child(4n) {
    margin-right: 0; }

.articles {
  flex-wrap: wrap; }
  .articles_item {
    flex-direction: column;
    width: 23.5%;
    margin-right: 2%;
    border-radius: 5px;
    border: 1px solid #dae3f4;
    margin-bottom: 20px;
    background-color: #fff; }
    .articles_item:nth-child(4n) {
      margin-right: 0; }
    @media screen and (max-width: 1023px) {
      .articles_item {
        width: 31.5%;
        margin-right: 2.75%; }
        .articles_item:nth-child(4n) {
          margin-right: 2.75%; }
        .articles_item:nth-child(3n) {
          margin-right: 0; } }
    @media screen and (max-width: 767px) {
      .articles_item {
        font-size: 10px;
        width: 49%;
        margin-right: 2%;
        margin-bottom: 10px; }
        .articles_item:nth-child(3n) {
          margin-right: 2%; }
        .articles_item:nth-child(2n) {
          margin-right: 0; } }
    @media screen and (max-width: 1023px) {
      .articles_item.big .small_img {
        display: block; }
      .articles_item.big .big_img {
        display: none; } }
    @media screen and (min-width: 1024px) {
      .articles_item.big {
        width: 49%;
        position: relative;
        padding-left: 258px; }
        .articles_item.big .small_img {
          display: none; }
        .articles_item.big .articles_item_img_link {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 258px;
          overflow: hidden;
          border-radius: 5px 0 0 5px; }
        .articles_item.big .articles_item_img {
          border-radius: 0;
          height: 100%;
          min-width: 100%;
          width: auto; }
        .articles_item.big .articles_item_text {
          padding: 24px 28px; }
        .articles_item.big .articles_item_title {
          margin-bottom: 22px; } }
    .articles_item_img {
      display: block;
      border-radius: 5px 5px 0 0;
      width: 100%; }
    .articles_item_text {
      padding: 18px 20px;
      flex: 1 0 auto; }
      @media screen and (max-width: 767px) {
        .articles_item_text {
          padding: 10px; } }
    .articles_item_title {
      color: #00143e;
      font-size: 18px;
      font-weight: 700;
      text-decoration: none;
      line-height: 1.3;
      margin-bottom: 12px;
      display: inline-block; }
      @media screen and (max-width: 1023px) {
        .articles_item_title {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .articles_item_title {
          font-size: 12px; } }
    .articles_item_desc {
      font-size: 14px; }
      .articles_item_desc.big {
        margin-top: 20px; }
        @media screen and (max-width: 1023px) {
          .articles_item_desc.big {
            display: none; } }
      @media screen and (max-width: 767px) {
        .articles_item_desc {
          font-size: 10px; } }
    .articles_item_bottom {
      border-radius: 0 0 5px 5px;
      background-color: #f1f6ff;
      padding: 12px 20px; }
      @media screen and (max-width: 767px) {
        .articles_item_bottom {
          padding: 10px; } }
    .articles_item_date {
      font-size: 14px;
      color: #8997c0; }
      @media screen and (max-width: 1023px) {
        .articles_item_date {
          font-size: 12px; } }
      @media screen and (max-width: 767px) {
        .articles_item_date {
          font-size: 10px; } }
      .articles_item_date .svg_icon {
        height: 12px;
        width: 12px;
        margin-right: 6px;
        fill: #8997c0; }

.contacts_block {
  position: relative;
  padding-top: 55px;
  padding-bottom: 72px;
  background-color: #f1f6ff; }
  @media screen and (max-width: 1023px) {
    .contacts_block {
      padding-top: 24px;
      padding-bottom: 32px; } }
  @media screen and (max-width: 767px) {
    .contacts_block {
      padding-bottom: 215px; } }
  .contacts_block .map {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 65%; }
    @media screen and (max-width: 1023px) {
      .contacts_block .map {
        width: 57%; } }
    @media screen and (max-width: 767px) {
      .contacts_block .map {
        height: 370px;
        top: auto;
        bottom: 0;
        width: 100%; } }
    .contacts_block .map .cssload-thecube {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: 0;
      transform: translate(-50%, -50%) rotate(45deg); }
    .contacts_block .map.loaded .cssload-thecube .cssload-cube:before {
      animation: none; }

.contacts {
  position: relative;
  z-index: 1;
  width: 480px; }
  @media screen and (max-width: 1023px) {
    .contacts {
      width: 400px; } }
  @media screen and (max-width: 767px) {
    .contacts {
      width: 100%; } }
  .contacts .text {
    margin-bottom: 8px; }
  .contacts_cities {
    margin-bottom: 25px;
    position: relative; }
    @media screen and (max-width: 1023px) {
      .contacts_cities {
        margin-bottom: 10px; } }
    .contacts_cities_choice {
      background-color: #fff;
      border-radius: 5px;
      cursor: pointer;
      padding: 20px 18px 20px 25px;
      z-index: 1;
      position: relative; }
      @media screen and (max-width: 767px) {
        .contacts_cities_choice {
          padding: 12px 15px; } }
      .contacts_cities_choice.active {
        border-radius: 5px 5px 0 0; }
      .contacts_cities_choice .triangle {
        width: 8px;
        height: 12px;
        position: relative; }
        .contacts_cities_choice .triangle:before {
          content: '';
          width: 9px;
          height: 9px;
          border-radius: 2px;
          border-top: 3px solid #00143e;
          border-right: 3px solid #00143e;
          transform: rotate(45deg);
          position: absolute;
          right: 1px;
          top: 1px; }
    .contacts_cities_block {
      border-radius: 0 0 5px 5px;
      background-color: #fff;
      padding: 20px 25px !important;
      position: absolute;
      width: 100% !important;
      left: 0;
      top: 100%;
      display: none;
      box-shadow: 0px 0px 46px 10px rgba(0, 32, 98, 0.2); }
      .contacts_cities_block_item {
        margin-bottom: 20px;
        cursor: pointer; }
        .contacts_cities_block_item:last-child {
          margin-bottom: 0; }
        .contacts_cities_block_item.active {
          color: #ed1111; }
        .contacts_cities_block_item b {
          font-weight: 400; }
  .contacts_tab {
    display: none;
    width: 100%;
    padding: 25px;
    background-color: #fff;
    border-radius: 5px; }
    @media screen and (max-width: 1023px) {
      .contacts_tab {
        padding: 18px 14px; } }
    @media screen and (max-width: 767px) {
      .contacts_tab {
        padding: 15px; } }
    .contacts_tab.active {
      display: block; }
    .contacts_tab_top {
      margin-bottom: 25px;
      border-bottom: 1px solid #f1f6ff;
      flex-wrap: wrap; }
    .contacts_tab_item {
      width: 45%;
      margin-right: 10%;
      margin-bottom: 20px; }
      .contacts_tab_item:nth-child(2n + 1) {
        margin-right: 0; }
      .contacts_tab_item.address {
        flex-basis: 100%;
        margin-right: 0; }
      .contacts_tab_item_title {
        margin-bottom: 6px;
        font-weight: 700; }
        .contacts_tab_item_title .svg_icon {
          margin-bottom: 2px;
          fill: #00143e;
          width: 15px;
          height: 15px;
          margin-right: 10px; }
      .contacts_tab_item .text {
        margin-bottom: 0; }
        .contacts_tab_item .text b, .contacts_tab_item .text strong {
          font-weight: 400; }
      .contacts_tab_item .phone_link {
        color: #00143e;
        text-decoration: none;
        font-size: 20px; }
        @media screen and (max-width: 1023px) {
          .contacts_tab_item .phone_link {
            font-size: 14px; } }
    .contacts_tab .schema {
      color: #00bff3; }
      .contacts_tab .schema .svg_icon {
        fill: #00bff3;
        margin-right: 10px;
        width: 14px;
        height: 14px; }
  .contacts_carousel_bottom {
    border-radius: 0 0 5px 5px;
    background-color: #f1f6ff;
    padding: 10px 15px;
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .contacts_carousel_bottom {
        font-size: 10px; } }
    .contacts_carousel_bottom .svg_icon {
      fill: #00143e;
      width: 20px;
      height: 10px; }
    .contacts_carousel_bottom .nav_btn {
      border: 0;
      padding: 0;
      height: auto;
      margin-top: 2px; }
      @media screen and (max-width: 767px) {
        .contacts_carousel_bottom .nav_btn {
          margin-top: 5px; } }
      .contacts_carousel_bottom .nav_btn:focus {
        outline: none; }
    .contacts_carousel_bottom .prev {
      margin-left: 15px; }
      .contacts_carousel_bottom .prev .svg_icon {
        transform: rotate(180deg); }
    .contacts_carousel_bottom .next {
      margin-left: 10px; }

.page_header {
  height: 166px;
  flex-direction: column;
  background-color: #002062;
  position: relative;
  overflow: hidden;
  z-index: 2; }
  @media screen and (max-width: 1023px) {
    .page_header {
      height: 98px; } }
  @media screen and (max-width: 767px) {
    .page_header {
      height: 66px; } }
  .page_header:before {
    content: '';
    position: absolute;
    width: 963px;
    height: 963px;
    border: 288px solid #002c86;
    left: 50%;
    transform: translateX(-50%);
    bottom: -100px;
    border-radius: 50%; }
    @media screen and (max-width: 767px) {
      .page_header:before {
        border: none;
        width: 260px;
        height: 260px;
        background-color: #002c86;
        bottom: -15px; } }
  .page_header .h1 {
    color: #fff;
    position: relative;
    z-index: 1;
    text-align: center; }
  .page_header .breadcrumb {
    list-style: none;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    position: relative;
    z-index: 1; }
    @media screen and (max-width: 1023px) {
      .page_header .breadcrumb {
        font-size: 12px; } }
    @media screen and (max-width: 767px) {
      .page_header .breadcrumb {
        font-size: 10px;
        margin-bottom: 8px; } }
    .page_header .breadcrumb-item {
      color: #3b5894;
      line-height: 1; }
      .page_header .breadcrumb-item a {
        color: #fff;
        margin-bottom: 1px;
        text-decoration: none;
        display: inline-block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
    .page_header .breadcrumb .separator {
      margin: 0 5px;
      width: 4px;
      height: 6px;
      position: relative; }
      .page_header .breadcrumb .separator:before {
        content: '';
        width: 4px;
        height: 4px;
        border-top: 1px solid #ed1111;
        border-right: 1px solid #ed1111;
        transform: rotate(45deg);
        position: absolute;
        top: 1px;
        right: 1px; }

.catalog_categories {
  padding: 80px 0;
  background-color: #F1F6FE; }
  @media screen and (max-width: 767px) {
    .catalog_categories {
      padding: 40px 0; } }
  .catalog_categories_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; }
    .catalog_categories_wrapper.catalog_categories_odd {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: unset;
      column-gap: 10px;
      row-gap: 20px; }
      @media screen and (max-width: 1023px) {
        .catalog_categories_wrapper.catalog_categories_odd {
          display: flex; } }
      .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item {
        width: 100%; }
        .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
          flex-direction: column; }
          @media screen and (max-width: 1023px) {
            .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) {
              flex-direction: row; } }
          @media only screen and (max-width: 500px) {
            .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) {
              flex-direction: column; } }
          .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) .catalog_category_info {
            width: 100%; }
            @media screen and (max-width: 1023px) {
              .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) .catalog_category_info {
                width: calc(100% - 160px); } }
            @media only screen and (max-width: 500px) {
              .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) .catalog_category_info {
                width: 100%; } }
          .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) .catalog_category_image {
            width: 50%;
            align-self: center; }
            @media screen and (max-width: 1023px) {
              .catalog_categories_wrapper.catalog_categories_odd .catalog_category_item:nth-child(2) .catalog_category_image {
                width: 130px; } }

.catalog_category_item {
  width: 49%;
  display: flex;
  justify-content: space-between;
  padding: 30px 35px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #E8EDF5; }
  @media screen and (max-width: 1023px) {
    .catalog_category_item {
      width: 100%; } }
  @media only screen and (max-width: 500px) {
    .catalog_category_item {
      flex-direction: column; } }

.catalog_category_image {
  width: 130px;
  flex-shrink: 0; }
  @media only screen and (max-width: 500px) {
    .catalog_category_image {
      align-self: center;
      margin-bottom: 20px; } }

.catalog_category_info {
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media only screen and (max-width: 500px) {
    .catalog_category_info {
      width: 100%; } }

.catalog_category_title {
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.3;
  width: 90%; }
  @media only screen and (max-width: 500px) {
    .catalog_category_title {
      width: 100%;
      font-size: 20px; } }

.catalog_category_desc {
  font-size: 14px;
  margin-bottom: 15px; }

.catalog_category_link {
  font-size: 14px;
  color: #ED1112;
  text-underline-offset: 2px; }

.page_content {
  padding-top: 50px;
  padding-bottom: 60px; }
  @media screen and (max-width: 1023px) {
    .page_content {
      padding-top: 40px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 767px) {
    .page_content {
      padding-top: 30px;
      padding-bottom: 40px; } }

.page_content_title {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 35px; }

.page_content_subtitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px; }

.page_content_columns {
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .page_content_columns {
      flex-wrap: wrap; } }

.page_content_two_columns > * {
  width: 49%; }

.page_content_two_columns.big_right > *:first-child {
  width: 300px; }
  @media screen and (max-width: 1023px) {
    .page_content_two_columns.big_right > *:first-child {
      width: 260px; } }
  @media screen and (max-width: 767px) {
    .page_content_two_columns.big_right > *:first-child {
      width: 100%;
      margin-bottom: 20px; } }

.page_content_two_columns.big_right > *:last-child {
  width: calc(100% - 400px); }
  @media screen and (max-width: 1023px) {
    .page_content_two_columns.big_right > *:last-child {
      width: calc(100% - 300px); } }
  @media screen and (max-width: 767px) {
    .page_content_two_columns.big_right > *:last-child {
      width: 100%; } }

.check_list {
  list-style-type: none; }
  .check_list li {
    position: relative;
    padding-left: 30px;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 17px; }
    @media screen and (max-width: 1023px) {
      .check_list li {
        font-size: 16px; } }
    .check_list li:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      background-image: url(../img/checked_1.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat; }
  .check_list_info {
    font-size: 18px; }
    @media screen and (max-width: 1023px) {
      .check_list_info {
        font-size: 14px; } }
    .check_list_info p {
      margin-bottom: 10px; }

.block_underlined {
  padding-bottom: 70px;
  border-bottom: 1px solid #F1F6FF;
  margin-bottom: 30px; }

.table_wrapper {
  overflow: auto; }

.styled_table {
  float: none;
  width: 100%; }
  .styled_table th {
    background-color: #F1F6FF;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #F1F6FF;
    padding: 25px; }
    .styled_table th:not(:last-child) {
      border-right: 1px solid #ffffff; }
  .styled_table td {
    border: 1px solid #F1F6FF;
    text-align: center; }
    .styled_table td p {
      margin: 0; }
    .styled_table td img {
      height: 72px; }
    .styled_table td.flex_col {
      display: flex; }
    .styled_table td.flex_ac {
      align-items: center; }
    .styled_table td.tal {
      text-align: left; }
  .styled_table .maxw {
    max-width: 452px; }

.pagination {
  padding-top: 10px;
  color: #8997c0;
  font-size: 14px; }
  .pagination_ul {
    list-style: none; }
    .pagination_ul .page-item {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px; }
      @media screen and (max-width: 767px) {
        .pagination_ul .page-item {
          margin-right: 5px; } }
      .pagination_ul .page-item.control, .pagination_ul .page-item.disabled {
        margin-right: 15px; }
        @media screen and (max-width: 767px) {
          .pagination_ul .page-item.control, .pagination_ul .page-item.disabled {
            margin-right: 10px; } }
        .pagination_ul .page-item.control a, .pagination_ul .page-item.control .control_link, .pagination_ul .page-item.disabled a, .pagination_ul .page-item.disabled .control_link {
          border: 1px solid #dae3f4; }
      .pagination_ul .page-item.active a {
        background-color: #002061;
        color: #fff; }
      .pagination_ul .page-item:last-child {
        margin-right: 0;
        margin-left: 5px; }
      .pagination_ul .page-item a, .pagination_ul .page-item .control_link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 5px;
        background-color: #f1f6ff;
        text-decoration: none;
        color: #8997c0; }
      .pagination_ul .page-item .control_link:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-top: 1px solid #8997c0;
        border-right: 1px solid #8997c0; }
      .pagination_ul .page-item .control_link.next {
        padding-right: 2px; }
        .pagination_ul .page-item .control_link.next:before {
          transform: rotate(45deg); }
      .pagination_ul .page-item .control_link.prev {
        padding-left: 2px; }
        .pagination_ul .page-item .control_link.prev:before {
          transform: rotate(-135deg); }

.popup_screen {
  background-color: rgba(0, 11, 34, 0.75);
  position: fixed;
  left: -9999px;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  transition: opacity 0.5s;
  opacity: 0; }
  .popup_screen.active {
    opacity: 1;
    left: 0; }
  .popup_screen.thanks {
    text-align: center; }
    .popup_screen.thanks .thanks_img {
      width: 70px;
      height: 70px;
      background-color: #1ea86c;
      margin-bottom: 14px;
      display: inline-block;
      border-radius: 50%;
      position: relative; }
      .popup_screen.thanks .thanks_img:before {
        content: '';
        position: absolute;
        width: 24px;
        height: 34px;
        transform: rotate(45deg) translateX(-50%);
        top: 23px;
        left: 47%;
        border-right: 8px solid #fff;
        border-bottom: 8px solid #fff; }
    .popup_screen.thanks .popup {
      padding: 30px 60px; }
      .popup_screen.thanks .popup_title {
        margin-bottom: 12px; }
  .popup_screen .popup {
    border-radius: 5px;
    background-color: #f1f6ff;
    padding: 24px 30px 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 93%; }
    @media screen and (max-width: 767px) {
      .popup_screen .popup {
        padding: 15px 10px; } }
    .popup_screen .popup_close {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer; }
      .popup_screen .popup_close:before, .popup_screen .popup_close:after {
        content: '';
        position: absolute;
        top: 6px;
        left: -2px;
        width: 18px;
        height: 2px;
        background-color: #abbad5; }
      .popup_screen .popup_close:before {
        transform: rotate(45deg); }
      .popup_screen .popup_close:after {
        transform: rotate(-45deg); }

.popup_form {
  width: 320px;
  max-width: 100%; }
  @media screen and (max-width: 767px) {
    .popup_form {
      font-size: 10px; } }
  .popup_form_text {
    text-align: center;
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .popup_form_text {
        font-size: 10px; } }

.popup_title {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 20px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .popup_title {
      font-size: 18px;
      margin-bottom: 14px; } }

.popup .catalog_btn, .popup .add_btn {
  width: 100%; }
  @media screen and (max-width: 767px) {
    .popup .catalog_btn, .popup .add_btn {
      font-size: 12px; } }

.popup .catalog_btn_blue:before {
  background-color: black; }

.popup .catalog_btn_blue .catalog_btn_text {
  background-color: #002062; }

.popup .catalog_btn_phone {
  margin-bottom: 0;
  margin-top: 20px;
  display: none; }
  @media screen and (max-width: 767px) {
    .popup .catalog_btn_phone {
      display: block; } }

.principles_block {
  padding-top: 35px;
  padding-bottom: 40px; }
  @media screen and (max-width: 1023px) {
    .principles_block {
      padding-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .principles_block {
      padding-top: 25px; } }
  .principles_block .h2 {
    margin-bottom: 20px;
    text-align: center; }
  .principles_block .text {
    margin-bottom: 30px;
    padding: 0 200px; }
    @media screen and (max-width: 1023px) {
      .principles_block .text {
        padding: 0 120px; } }
    @media screen and (max-width: 767px) {
      .principles_block .text {
        padding: 0; } }

.employees_block {
  padding-top: 50px;
  padding-bottom: 55px;
  background-color: #002062; }
  @media screen and (max-width: 1023px) {
    .employees_block {
      padding-top: 35px;
      padding-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    .employees_block {
      padding-top: 15px;
      padding-bottom: 20px; } }
  .employees_block .h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .employees_block .h2 {
        margin-bottom: 15px; } }

.employees {
  flex-wrap: wrap;
  color: #fefeff; }
  @media screen and (max-width: 1023px) {
    .employees.owl-carousel .employees_item {
      width: 100%;
      min-height: 420px;
      margin-bottom: 0;
      margin-right: 0; }
      .employees.owl-carousel .employees_item_img {
        width: auto; } }
  .employees_item {
    width: 18.8%;
    min-height: 490px;
    margin-right: 1.5%;
    margin-bottom: 20px;
    border-radius: 3px 20px;
    background-color: #002c86; }
    .employees_item:nth-child(5n) {
      margin-right: 0; }
    @media screen and (max-width: 1023px) {
      .employees_item {
        width: 32%;
        margin-right: 2%; }
        .employees_item:nth-child(5n) {
          margin-right: 2%; }
        .employees_item:nth-child(3n) {
          margin-right: 0; } }
    @media screen and (max-width: 767px) {
      .employees_item {
        width: 49%; }
        .employees_item:nth-child(3n) {
          margin-right: 2%; }
        .employees_item:nth-child(2n) {
          margin-right: 0; } }
    .employees_item_img {
      height: 100%;
      display: block;
      max-width: none; }
      .employees_item_img_block {
        background-color: #fff;
        border-radius: 3px 20px;
        overflow: hidden; }
    .employees_item_text {
      padding: 15px 15px 25px; }
      @media screen and (max-width: 767px) {
        .employees_item_text {
          padding: 12px 10px; } }
    .employees_item_fio {
      font-weight: 700;
      margin-bottom: 14px; }
      @media screen and (max-width: 767px) {
        .employees_item_fio {
          margin-bottom: 8px; } }
    .employees_item_contact {
      color: #fefeff;
      text-decoration: none;
      font-size: 14px;
      margin-bottom: 4px; }
      .employees_item_contact:last-child {
        margin-bottom: 0; }
      @media screen and (max-width: 767px) {
        .employees_item_contact {
          font-size: 10px; } }
      .employees_item_contact .svg_icon {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        fill: #ed1111; }

.certificate {
  padding-top: 44px;
  padding-bottom: 60px;
  background-color: #f1f6ff; }
  @media screen and (max-width: 1023px) {
    .certificate {
      padding-top: 35px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 767px) {
    .certificate {
      padding-top: 25px;
      padding-bottom: 40px; } }
  .certificate .h2 {
    margin-bottom: 34px; }
    @media screen and (max-width: 767px) {
      .certificate .h2 {
        margin-bottom: 18px; } }

.gallery {
  position: relative; }
  .gallery_item {
    display: block;
    position: relative; }
    .gallery_item:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: rgba(255, 255, 255, 0.8);
      transition: opacity 0.5s; }
    .gallery_item:after {
      content: '';
      width: 45px;
      height: 45px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../img/zoom.svg);
      position: absolute;
      opacity: 0;
      transition: opacity 0.5s;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .gallery_item:hover:before, .gallery_item:hover:after {
      opacity: 1; }
    .gallery_item_img {
      border-radius: 5px;
      display: block; }
  .gallery .owl-nav {
    position: absolute;
    height: 0;
    width: 100%;
    top: 50%;
    left: 0; }
    .gallery .owl-nav .owl-prev, .gallery .owl-nav .owl-next {
      top: 0;
      transform: translateY(-50%); }
    .gallery .owl-nav .owl-prev {
      left: 0; }
    .gallery .owl-nav .owl-next {
      right: 0; }

.certificate .gallery_item {
  border: 10px solid #fff;
  border-radius: 3px; }
  .certificate .gallery_item_img {
    border-radius: 3px; }

.delivery_block {
  padding-top: 50px;
  padding-bottom: 130px; }
  @media screen and (max-width: 840px) {
    .delivery_block {
      padding: 40px 10px; } }
  @media screen and (max-width: 767px) {
    .delivery_block {
      padding: 20px 10px 30px; } }

.delivery {
  flex-wrap: wrap;
  position: relative;
  transition: height 0.4s; }
  .delivery.active .delivery_item {
    opacity: 0; }
    .delivery.active .delivery_item.active {
      transition: all 0.5s, opacity 0.2s;
      opacity: 1;
      background-color: #fff;
      z-index: 1;
      position: absolute; }
      .delivery.active .delivery_item.active .delivery_item_btn {
        color: #ed1111; }
        .delivery.active .delivery_item.active .delivery_item_btn .svg_icon {
          fill: #ed1111;
          transform: rotate(180deg); }
        .delivery.active .delivery_item.active .delivery_item_btn .circle {
          border-color: #ed1111; }
      .delivery.active .delivery_item.active .delivery_item_desc {
        opacity: 1; }
      .delivery.active .delivery_item.active .delivery_item_img {
        opacity: 0; }
  .delivery.loaded .delivery_item:not(.active) {
    display: none; }
  .delivery_item {
    width: 32.5%;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #e8edf6;
    background-color: #f1f6ff;
    position: relative;
    overflow: hidden;
    padding: 36px 30px 30px;
    flex-direction: column; }
    @media screen and (max-width: 1023px) {
      .delivery_item {
        padding: 25px 20px 20px; } }
    @media screen and (max-width: 767px) {
      .delivery_item {
        padding: 20px;
        width: 100%;
        margin-bottom: 10px; } }
    .delivery_item:hover {
      background-color: #fff; }
      .delivery_item:hover .delivery_item_img {
        opacity: 0; }
      .delivery_item:hover .delivery_item_desc {
        opacity: 1; }
      .delivery_item:hover .delivery_item_btn {
        color: #ed1111; }
        .delivery_item:hover .delivery_item_btn .svg_icon {
          fill: #ed1111; }
        .delivery_item:hover .delivery_item_btn .circle {
          border-color: #ed1111; }
    .delivery_item_title {
      font-size: 18px;
      margin-bottom: 14px;
      font-weight: 700;
      padding-right: 100px;
      flex: 0 0 auto;
      transition: opacity 0.5s; }
      @media screen and (max-width: 1023px) {
        .delivery_item_title {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .delivery_item_title {
          font-size: 12px; } }
    .delivery_item_desc {
      margin-bottom: 20px;
      opacity: 0;
      flex: 1 0 auto;
      position: relative;
      z-index: 1;
      transition: opacity 0.5s; }
      @media screen and (max-width: 1023px) {
        .delivery_item_desc {
          margin-bottom: 16px; } }
      @media screen and (max-width: 767px) {
        .delivery_item_desc {
          margin-bottom: 14px; } }
    .delivery_item_text {
      display: none;
      width: 100% !important;
      margin-bottom: 20px; }
    .delivery_item_img {
      position: absolute;
      right: 0;
      bottom: 0;
      max-height: 100%;
      max-width: 100%;
      transition: opacity 0.5s; }
      @media screen and (max-width: 1023px) {
        .delivery_item_img {
          max-width: 160px; } }
      @media screen and (max-width: 767px) {
        .delivery_item_img {
          max-width: 120px; } }
    .delivery_item_btn {
      flex: 0 0 auto;
      cursor: pointer;
      transition: all 0.5s; }
      .delivery_item_btn .circle {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 16px;
        border: 1px solid #00143e;
        transition: all 0.5s; }
        @media screen and (max-width: 767px) {
          .delivery_item_btn .circle {
            width: 30px;
            height: 30px;
            margin-right: 10px; } }
        .delivery_item_btn .circle .svg_icon {
          width: 15px;
          height: 7px;
          fill: #00143e;
          transition: all 0.5s; }

@media screen and (max-width: 767px) {
  .article {
    flex-wrap: wrap; } }

.article_img {
  display: block;
  border-radius: 5px;
  border: 1px solid #dae3f4;
  width: 32.2%;
  margin-right: 4.2%;
  margin-bottom: 16px;
  float: left; }
  @media screen and (max-width: 1023px) {
    .article_img {
      width: 31.7%;
      margin-right: 2.7%; } }
  @media screen and (max-width: 767px) {
    .article_img {
      width: 100%;
      margin-bottom: 14px; } }

.article_text {
  padding-top: 20px;
  font-size: 14px; }
  @media screen and (max-width: 1023px) {
    .article_text {
      padding-top: 6px; } }
  @media screen and (max-width: 767px) {
    .article_text {
      width: 100%;
      font-size: 10px; } }

.article .articles_item_date {
  margin-bottom: 20px; }
  @media screen and (max-width: 1023px) {
    .article .articles_item_date {
      margin-bottom: 12px; } }
  @media screen and (max-width: 767px) {
    .article .articles_item_date {
      margin-bottom: 10px;
      font-size: 12px; } }

.article_title {
  margin-bottom: 15px;
  font-size: 18px; }
  @media screen and (max-width: 1023px) {
    .article_title {
      margin-bottom: 12px; } }
  @media screen and (max-width: 767px) {
    .article_title {
      margin-bottom: 10px;
      font-size: 12px; } }

.model_block {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f1f6ff; }
  @media screen and (max-width: 1023px) {
    .model_block {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    .model_block {
      padding-top: 30px;
      padding-bottom: 20px; } }
  .model_block .warning {
    background-color: #f1f6ff; }
    .model_block .warning_add {
      background-color: #f1f6ff; }

.models {
  flex-wrap: wrap;
  padding-bottom: 20px; }
  .models_item {
    margin-bottom: 20px;
    width: 32.2%;
    padding: 23px 40px 23px 25px;
    background-color: #fff;
    border-radius: 5px;
    text-decoration: none; }
    @media screen and (max-width: 1023px) {
      .models_item {
        width: 48.6%;
        font-size: 16px; }
        .models_item:last-child {
          width: 100%; } }
    @media screen and (max-width: 767px) {
      .models_item {
        width: 100%;
        margin-bottom: 5px;
        font-size: 12px;
        padding: 14px; } }
    .models_item:hover .svg_icon {
      stroke: #ed1111; }
    .models_item:hover .text {
      color: #ed1111;
      border-color: #ed1111; }
    .models_item:focus .svg_icon, .models_item:active .svg_icon {
      stroke: #ed1111; }
    .models_item:focus .text, .models_item:active .text {
      color: #ed1111;
      border-color: transparent; }
    .models_item_img {
      width: 52px;
      min-width: 52px;
      height: 64px;
      margin-right: 25px; }
      @media screen and (max-width: 767px) {
        .models_item_img {
          height: 40px;
          width: 32px;
          min-width: 32px;
          margin-right: 14px; } }
    .models_item .svg_icon {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: #002062;
      transition: all 0.4s; }
    .models_item_title .text {
      color: #00143e;
      border-bottom: 1px dashed #00143e;
      transition: all 0.4s; }
    .models_item_title b {
      text-transform: uppercase; }

.model_left {
  margin-bottom: 46px; }
  @media screen and (max-width: 767px) {
    .model_left {
      flex-wrap: wrap;
      margin-bottom: 22px; } }
  .model_left_img {
    display: block;
    border-radius: 5px;
    width: 100%; }
    .model_left_img_block {
      width: 37.3%; }
      @media screen and (max-width: 1023px) {
        .model_left_img_block {
          width: 31.5%; } }
      @media screen and (max-width: 767px) {
        .model_left_img_block {
          width: 100%;
          margin-bottom: 12px; } }
  .model_left_text {
    width: 60%; }
    @media screen and (max-width: 1023px) {
      .model_left_text {
        width: 66%; } }
    @media screen and (max-width: 767px) {
      .model_left_text {
        width: 100%; } }
  .model_left_title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
    width: 420px;
    max-width: 100%; }
    @media screen and (max-width: 1023px) {
      .model_left_title {
        font-size: 14px; } }

.number_block {
  counter-reset: number_block; }
  @media screen and (max-width: 767px) {
    .number_block {
      font-size: 10px; } }
  .number_block_item {
    position: relative;
    padding-left: 40px;
    counter-increment: number_block;
    margin-bottom: 28px; }
    @media screen and (max-width: 1023px) {
      .number_block_item {
        padding-left: 30px;
        margin-bottom: 18px; } }
    @media screen and (max-width: 767px) {
      .number_block_item {
        margin-bottom: 12px;
        padding-left: 20px; } }
    .number_block_item:before {
      font-family: 'Bebas Neue', sans-serif;
      content: "0" counter(number_block) ".";
      position: absolute;
      font-size: 26px;
      font-weight: 700;
      left: 0;
      top: -4px; }
      @media screen and (max-width: 1023px) {
        .number_block_item:before {
          font-size: 22px; } }
      @media screen and (max-width: 767px) {
        .number_block_item:before {
          font-size: 16px;
          top: -2px; } }
  .number_block_add {
    border-radius: 3px;
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #e8edf6;
    cursor: pointer;
    width: 100% !important; }
    @media screen and (max-width: 1023px) {
      .number_block_add {
        padding: 12px; } }
    .number_block_add_text {
      font-size: 18px;
      font-weight: 700;
      display: inline;
      border-bottom: 1px dashed #00143e; }
      @media screen and (max-width: 1023px) {
        .number_block_add_text {
          font-size: 14px; } }
      @media screen and (max-width: 767px) {
        .number_block_add_text {
          font-size: 12px; } }
  .number_block_hide {
    display: none;
    width: 100% !important; }

.design_container {
  padding: 44px calc(50% - 430px) 60px;
  background-color: #002062;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-image: url(../img/design_bg.png); }
  @media screen and (max-width: 880px) {
    .design_container {
      padding: 35px 10px 50px; } }
  @media screen and (max-width: 767px) {
    .design_container {
      padding: 16px 10px 40px; } }
  .design_container .section_title {
    margin-bottom: 45px;
    color: #fff; }
    @media screen and (max-width: 1023px) {
      .design_container .section_title {
        margin-bottom: 30px; } }
    @media screen and (max-width: 767px) {
      .design_container .section_title {
        margin-bottom: 16px; } }

.design_title {
  padding: 20px;
  width: 50%;
  text-align: center;
  background-color: rgba(68, 111, 196, 0.4);
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  transition: all 0.3s; }
  .design_title.active {
    font-weight: 700;
    background-color: #fff;
    color: #00143e; }
  .design_title:first-child {
    border-radius: 5px 0 0; }
  .design_title:last-child {
    border-radius: 0 5px 0 0; }
  @media screen and (max-width: 1023px) {
    .design_title {
      padding: 14px;
      font-size: 16px; } }
  @media screen and (max-width: 767px) {
    .design_title {
      font-size: 12px; } }

.design_block {
  padding: 40px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  transition: height 0.3s; }
  @media screen and (max-width: 1023px) {
    .design_block {
      padding: 20px; } }
  @media screen and (max-width: 767px) {
    .design_block {
      padding: 15px 10px; }
      .design_block td:first-child, .design_block th:first-child {
        display: none; }
      .design_block td:nth-child(2n), .design_block th:nth-child(2n) {
        border-left: 1px solid #e8edf6;
        text-align: left; } }
  .design_block_item {
    display: none;
    opacity: 0;
    transition: opacity 0.6s; }
    .design_block_item.active {
      opacity: 1; }

.design td:last-child {
  width: 170px;
  font-size: 16px; }
  @media screen and (max-width: 767px) {
    .design td:last-child {
      font-size: 10px;
      width: 70px; } }

.portfolio_content {
  margin-bottom: 25px; }

.portfolio_filter {
  margin-bottom: 20px; }

.select {
  position: relative;
  width: 354px;
  max-width: 100%; }
  .select_title {
    height: 44px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #dae3f4;
    padding: 0 20px;
    cursor: pointer;
    position: relative;
    z-index: 5; }
    .select_title:after {
      content: '';
      width: 7px;
      height: 7px;
      border-right: 1px solid #00143e;
      border-bottom: 1px solid #00143e;
      transform: rotate(45deg);
      margin-left: 10px;
      margin-bottom: 2px; }
    .select_title.active {
      border-radius: 5px 5px 0 0; }
  .select_block {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100% !important;
    height: 114px;
    overflow: hidden;
    z-index: 4;
    font-size: 14px;
    border-radius: 0 0 3px 3px;
    background-color: #fff;
    padding: 4px 14px 4px 10px !important;
    display: none;
    box-shadow: 0px 0px 46px 7px rgba(7, 7, 7, 0.2); }
  .select_item {
    display: block;
    color: #00143e;
    transition: color 0.4s;
    text-decoration: none;
    padding: 8px 0; }
    .select_item:hover {
      color: #ed1111; }

.portfolio_filter {
  background-color: #f1f6ff;
  border-radius: 5px;
  padding: 10px; }
  @media screen and (max-width: 1023px) {
    .portfolio_filter {
      justify-content: space-between; } }
  @media screen and (max-width: 767px) {
    .portfolio_filter {
      flex-wrap: wrap; } }
  .portfolio_filter .select {
    margin-right: 20px; }
    .portfolio_filter .select_title {
      width: 100%; }
    @media screen and (max-width: 1023px) {
      .portfolio_filter .select {
        margin-right: 0;
        width: 48.6%; } }
    @media screen and (max-width: 767px) {
      .portfolio_filter .select {
        width: 100%;
        margin-bottom: 5px; } }
    .portfolio_filter .select:last-child {
      margin-right: 0; }
      @media screen and (max-width: 767px) {
        .portfolio_filter .select:last-child {
          margin-bottom: 0; } }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_draggerRail {
  width: 4px; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #e8edf6; }

.mCSB_scrollTools {
  opacity: 1;
  width: 4px; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover, .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #ed1111; }

.catalog_page {
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #f1f6ff; }
  @media screen and (max-width: 1023px) {
    .catalog_page {
      padding-top: 40px;
      padding-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .catalog_page {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .catalog_page .catalog.active .catalog_item {
    display: none; }
    .catalog_page .catalog.active .catalog_item.active {
      display: block; }
      .catalog_page .catalog.active .catalog_item.active.flex {
        display: flex; }
        @media screen and (max-width: 1200px) {
          .catalog_page .catalog.active .catalog_item.active.flex {
            display: block; } }

.catalog_filter {
  margin-bottom: 20px; }
  @media screen and (max-width: 1023px) {
    .catalog_filter {
      flex-wrap: wrap;
      margin-bottom: 10px; }
      .catalog_filter.flex {
        justify-content: space-between; } }
  .catalog_filter_item {
    position: relative;
    cursor: pointer;
    margin-right: 10px; }
    .catalog_filter_item:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1023px) {
      .catalog_filter_item {
        margin-right: 0;
        width: 31.5%;
        text-align: center;
        margin-bottom: 10px; } }
    @media screen and (max-width: 767px) {
      .catalog_filter_item {
        width: 100%; } }
    .catalog_filter_item_active {
      display: none; }
  .catalog_filter_radio {
    position: absolute;
    width: 2px;
    height: 2px;
    left: 10px;
    top: 10px;
    opacity: 0; }
    .catalog_filter_radio:checked + .catalog_filter_text {
      background-color: #00143e;
      color: #fff; }
  .catalog_filter_text {
    display: block;
    padding: 12px 40px;
    background-color: #dce7fb;
    border-radius: 22px;
    position: relative;
    z-index: 1;
    transition: all 0.5s; }
    @media screen and (max-width: 1200px) {
      .catalog_filter_text {
        padding: 12px 30px; } }

.accessories {
  background-color: #f1f6ff;
  padding-top: 50px;
  padding-bottom: 40px;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .accessories {
      padding-top: 30px;
      padding-bottom: 30px; } }
  .accessories_item {
    border-radius: 5px;
    border: 1px solid #e8edf6;
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 20px 30px 30px 20px;
    width: 49%;
    flex-direction: column; }
    @media screen and (max-width: 1023px) {
      .accessories_item {
        padding: 10px 10px 18px; } }
    @media screen and (max-width: 767px) {
      .accessories_item {
        width: 100%;
        margin-bottom: 10px;
        padding: 10px; } }
    .accessories_item_text {
      margin-bottom: 14px; }
      @media screen and (max-width: 1200px) {
        .accessories_item_text {
          min-height: 150px;
          width: calc(100% - 115px); } }
      @media screen and (max-width: 767px) {
        .accessories_item_text {
          width: calc(100% - 70px);
          min-height: 90px; } }
    .accessories_item_block_img {
      margin-right: 20px; }
      @media screen and (max-width: 1200px) {
        .accessories_item_block_img {
          margin-right: 0;
          position: absolute;
          left: 0;
          top: 0; } }
    .accessories_item_img {
      display: block;
      border-radius: 5px;
      min-width: 130px;
      width: 130px; }
      @media screen and (max-width: 1200px) {
        .accessories_item_img {
          width: 100px;
          min-width: auto; } }
      @media screen and (max-width: 767px) {
        .accessories_item_img {
          width: 60px; } }
    .accessories_item_right {
      padding-top: 12px;
      flex-direction: column;
      width: 100%; }
      @media screen and (max-width: 1200px) {
        .accessories_item_right {
          padding-top: 0; }
          .accessories_item_right.flex {
            align-items: flex-end; } }
    .accessories_item_title {
      display: block;
      text-decoration: none;
      font-weight: 700;
      color: #00143e;
      margin-bottom: 30px; }
      @media screen and (max-width: 1023px) {
        .accessories_item_title {
          margin-bottom: 12px;
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .accessories_item_title {
          font-size: 12px;
          margin-bottom: 8px; } }
    .accessories_item_desc {
      font-size: 14px; }
      @media screen and (max-width: 767px) {
        .accessories_item_desc {
          font-size: 10px; } }
    .accessories_item_products {
      margin-top: auto; }
      @media screen and (max-width: 1200px) {
        .accessories_item_products {
          width: 100%; } }
    @media screen and (max-width: 1023px) {
      .accessories_item_select.jqselect {
        width: calc(100% - 130px); } }
    @media screen and (max-width: 767px) {
      .accessories_item_select {
        font-size: 10px; } }
    .accessories_item_select.opened .jq-selectbox__select {
      border-radius: 5px 5px 0 0; }
    .accessories_item_select.dropup .jq-selectbox__select {
      border-radius: 0 0 5px 5px; }
    .accessories_item_select.dropup .jq-selectbox__dropdown {
      border-radius: 3px 3px 0 0; }
    .accessories_item_select .jq-selectbox__select {
      width: 240px;
      max-width: 100%;
      border-radius: 5px;
      border: 1px solid #dae3f4;
      height: 44px;
      padding: 0 16px;
      position: relative;
      z-index: 5;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 1023px) {
        .accessories_item_select .jq-selectbox__select {
          width: 100%; } }
      @media screen and (max-width: 767px) {
        .accessories_item_select .jq-selectbox__select {
          padding: 0 14px; } }
      .accessories_item_select .jq-selectbox__select .jq-selectbox__trigger {
        width: 7px;
        height: 7px;
        border-right: 1px solid #00143e;
        border-bottom: 1px solid #00143e;
        transform: rotate(45deg);
        margin-left: 10px;
        margin-bottom: 2px; }
      .accessories_item_select .jq-selectbox__select .jq-selectbox__select-text {
        width: auto !important; }
    .accessories_item_select .jq-selectbox__dropdown {
      border-radius: 0 0 3px 3px;
      background-color: #fff;
      width: 100%;
      max-height: 114px;
      overflow: hidden;
      z-index: 4;
      font-size: 14px;
      padding: 4px 14px 4px 10px;
      box-shadow: 0px 0px 46px 7px rgba(7, 7, 7, 0.2); }
      @media screen and (max-width: 767px) {
        .accessories_item_select .jq-selectbox__dropdown {
          font-size: 10px;
          max-height: 98px; } }
      .accessories_item_select .jq-selectbox__dropdown ul {
        list-style: none; }
      .accessories_item_select .jq-selectbox__dropdown li {
        padding: 8px 0 !important;
        margin: 0 !important; }
        .accessories_item_select .jq-selectbox__dropdown li:before {
          content: none !important; }
        .accessories_item_select .jq-selectbox__dropdown li.disabled {
          display: none; }
    .accessories_item_top {
      margin-bottom: 22px;
      position: relative; }
    .accessories_item_price {
      font-size: 20px;
      font-weight: 700;
      margin-right: 20px; }
      @media screen and (max-width: 767px) {
        .accessories_item_price {
          font-size: 14px; } }

.jq-selectbox__select {
  cursor: pointer; }

.jq-selectbox__dropdown li {
  cursor: pointer; }

.product_neighbors {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0; }
  .product_neighbors_item {
    position: relative;
    top: -28px;
    width: 50px;
    height: 30px; }
    @media screen and (max-width: 1023px) {
      .product_neighbors_item {
        top: -18px; } }
    @media screen and (max-width: 767px) {
      .product_neighbors_item {
        top: -13px;
        width: 30px;
        height: 20px; } }
    .product_neighbors_item .svg_icon {
      display: block;
      width: 100%;
      height: 100%;
      fill: #ed1111; }
    .product_neighbors_item.link_prev {
      right: 66px; }
      @media screen and (max-width: 767px) {
        .product_neighbors_item.link_prev {
          right: 40px; } }
      .product_neighbors_item.link_prev .svg_icon {
        transform: rotate(180deg); }
    .product_neighbors_item.link_next {
      left: 66px; }
      @media screen and (max-width: 767px) {
        .product_neighbors_item.link_next {
          left: 40px; } }

.product_img {
  max-height: 100%;
  max-width: 83%; }
  @media screen and (max-width: 1023px) {
    .product_img {
      max-width: 228px;
      max-height: 390px; } }
  @media screen and (max-width: 767px) {
    .product_img {
      max-width: 200px;
      max-height: 248px; } }

.product_top {
  background-color: #f1f6ff;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative; }
  @media screen and (max-width: 1023px) {
    .product_top {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    .product_top {
      padding-top: 20px;
      padding-bottom: 30px; } }

.product_left {
  min-height: 578px;
  position: relative;
  min-width: 90px;
  margin-right: 10px; }
  @media screen and (max-width: 1023px) {
    .product_left {
      width: 60px;
      min-width: 60px;
      min-height: 454px; } }
  @media screen and (max-width: 767px) {
    .product_left {
      min-height: auto;
      position: absolute;
      left: 10px;
      top: 20px; } }

.product_right {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 50px 50px 30px; }
  @media screen and (max-width: 1023px) {
    .product_right {
      padding: 15px 15px 28px 10px; } }
  @media screen and (max-width: 767px) {
    .product_right {
      flex-wrap: wrap;
      background-color: transparent;
      padding: 0; } }

.product_owl {
  width: 478px;
  transform-origin: left;
  transform: rotate(90deg);
  position: absolute;
  left: 45px;
  top: 5px; }
  @media screen and (max-width: 1023px) {
    .product_owl {
      width: 350px;
      left: 32px;
      top: 18px; } }
  @media screen and (max-width: 767px) {
    .product_owl {
      left: 30px;
      top: 10px;
      width: 260px; } }
  .product_owl.mini {
    top: -49px; }
    @media screen and (max-width: 1023px) {
      .product_owl.mini {
        top: -36px; } }
    @media screen and (max-width: 767px) {
      .product_owl.mini {
        top: -34px; } }
  .product_owl .owl-stage-outer {
    z-index: 2; }
  .product_owl_link {
    width: 112px;
    height: 90px;
    border-radius: 3px;
    background-color: #fff; }
    @media screen and (max-width: 1023px) {
      .product_owl_link {
        height: 64px;
        width: 80px; } }
    @media screen and (max-width: 767px) {
      .product_owl_link {
        height: 60px;
        width: 78px; } }
    .product_owl_link.active {
      border: 1px solid #00143e; }
  .product_owl_img {
    transform: rotate(-90deg);
    width: auto !important;
    display: block;
    border-radius: 3px; }
    @media screen and (max-width: 1023px) {
      .product_owl_img {
        width: 54px !important; } }
    @media screen and (max-width: 767px) {
      .product_owl_img {
        width: 50px !important; } }
  .product_owl .owl-item {
    display: flex;
    justify-content: center;
    align-items: center; }
  .product_owl .owl-nav {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%; }
    .product_owl .owl-nav .svg_icon {
      width: 20px;
      height: 10px; }
    .product_owl .owl-nav .owl-next, .product_owl .owl-nav .owl-prev {
      width: 50px;
      height: 100%;
      position: absolute;
      top: 0; }
      @media screen and (max-width: 767px) {
        .product_owl .owl-nav .owl-next, .product_owl .owl-nav .owl-prev {
          width: 40px; } }
    .product_owl .owl-nav .owl-prev {
      right: 100%; }
    .product_owl .owl-nav .owl-next {
      left: 100%; }

.product_image_block {
  width: 42%;
  height: 100%;
  position: relative; }
  @media screen and (max-width: 767px) {
    .product_image_block {
      width: calc(100% - 65px);
      margin-left: 65px;
      background-color: #fff;
      border-radius: 3px;
      height: 340px;
      margin-bottom: 5px; } }
  .product_image_block .catalog_item_tag {
    left: -22px;
    top: -22px; }
    @media screen and (max-width: 1023px) {
      .product_image_block .catalog_item_tag {
        top: -5px; } }
    @media screen and (max-width: 767px) {
      .product_image_block .catalog_item_tag {
        left: 10px;
        top: 10px; } }
  .product_image_block .product_stamp {
    transform: rotate(15deg); }

.product_stamp {
  width: 90px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(../img/stamp.svg);
  position: absolute;
  top: -18px;
  right: 0; }
  @media screen and (max-width: 1023px) {
    .product_stamp {
      top: -5px;
      width: 67px;
      height: 45px; } }
  @media screen and (max-width: 767px) {
    .product_stamp {
      display: none; } }

.product_info {
  width: 53%; }
  @media screen and (max-width: 1023px) {
    .product_info {
      width: 54%; } }
  @media screen and (max-width: 767px) {
    .product_info {
      width: 100%;
      background-color: #fff;
      border-radius: 3px;
      padding: 15px 10px 20px; } }
  .product_info_title {
    font-size: 24px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8edf6;
    margin-bottom: 28px; }
    @media screen and (max-width: 1023px) {
      .product_info_title {
        font-size: 18px;
        margin-bottom: 20px; } }
  .product_info_feature {
    font-size: 14px;
    margin-bottom: 40px; }
    @media screen and (max-width: 1023px) {
      .product_info_feature {
        margin-bottom: 25px;
        font-size: 12px; } }
    @media screen and (max-width: 767px) {
      .product_info_feature {
        font-size: 10px; } }
    .product_info_feature_item {
      position: relative;
      margin-bottom: 10px; }
      .product_info_feature_item:last-child {
        margin-bottom: 0; }
      .product_info_feature_item:before {
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        width: 100%;
        height: 0;
        border-bottom: 2px dotted #95b0e7; }
        @media screen and (max-width: 1023px) {
          .product_info_feature_item:before {
            top: 11px; } }
        @media screen and (max-width: 767px) {
          .product_info_feature_item:before {
            top: 9px; } }
    .product_info_feature_left {
      margin-right: 10px;
      font-weight: 700; }
    .product_info_feature_right {
      text-align: right; }
    .product_info_feature_left, .product_info_feature_right {
      background-color: #fff;
      position: relative;
      z-index: 1; }
  .product_info .catalog_item_radio {
    width: 50%; }
    @media screen and (max-width: 1023px) {
      .product_info .catalog_item_radio {
        font-size: 14px; }
        .product_info .catalog_item_radio .checked {
          top: 1px; } }
    @media screen and (max-width: 767px) {
      .product_info .catalog_item_radio {
        font-size: 12px; }
        .product_info .catalog_item_radio .checked {
          top: -1px; } }
  .product_info_prices {
    margin-bottom: 25px; }
    @media screen and (max-width: 1023px) {
      .product_info_prices {
        margin-bottom: 20px; } }
  .product_info .catalog_btn, .product_info .add_btn {
    margin-left: 30px; }
    @media screen and (max-width: 767px) {
      .product_info .catalog_btn, .product_info .add_btn {
        margin-left: 10px;
        width: calc(100% - 130px);
        min-width: auto; } }
  .product_info .old_price {
    font-size: 18px; }
    @media screen and (max-width: 1023px) {
      .product_info .old_price {
        font-size: 14px; } }
    @media screen and (max-width: 767px) {
      .product_info .old_price {
        font-size: 12px; } }
  .product_info .price {
    font-size: 30px; }
    @media screen and (max-width: 1023px) {
      .product_info .price {
        font-size: 20px; } }
    @media screen and (max-width: 767px) {
      .product_info .price {
        font-size: 18px; } }

.product_middle {
  padding-top: 34px;
  padding-bottom: 44px; }
  @media screen and (max-width: 767px) {
    .product_middle {
      padding-top: 15px;
      padding-bottom: 20px;
      flex-wrap: wrap; } }
  .product_middle_right {
    width: 28.5%; }
    @media screen and (max-width: 1023px) {
      .product_middle_right {
        width: 32%; } }
    @media screen and (max-width: 767px) {
      .product_middle_right {
        width: 100%; } }
  .product_middle_block {
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f1f6ff;
    padding: 25px 28px 35px; }
    .product_middle_block:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 1023px) {
      .product_middle_block {
        padding: 15px 20px 20px;
        margin-bottom: 10px; } }
    .product_middle_block_title {
      text-align: center;
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 700; }
      @media screen and (max-width: 1023px) {
        .product_middle_block_title {
          font-size: 16px; } }
    .product_middle_block_link {
      color: #12aadc;
      margin-bottom: 20px; }
      .product_middle_block_link:active {
        text-decoration: underline; }
      .product_middle_block_link:focus {
        text-decoration: underline; }
      .product_middle_block_link:last-child {
        margin-bottom: 0; }
      .product_middle_block_link:before {
        content: '';
        width: 14px;
        height: 18px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url(../img/pdf.svg); }
      .product_middle_block_link.calculator:before {
        background-image: url(../img/calculator.svg); }

.product_content {
  width: 66.1%; }
  @media screen and (max-width: 767px) {
    .product_content {
      width: 100%;
      font-size: 10px;
      margin-bottom: 20px; } }
  .product_content h5 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #ed1111; }
    @media screen and (max-width: 1023px) {
      .product_content h5 {
        font-size: 16px;
        margin-bottom: 14px; } }
    @media screen and (max-width: 767px) {
      .product_content h5 {
        font-size: 10px; } }

.product_gallery_item_img {
  display: block;
  border-radius: 5px; }

.product_accessories_block {
  background-color: #f1f6ff;
  padding-top: 34px;
  padding-bottom: 60px; }
  @media screen and (max-width: 1023px) {
    .product_accessories_block {
      padding-bottom: 40px; } }
  @media screen and (max-width: 767px) {
    .product_accessories_block {
      padding-top: 25px; } }
  .product_accessories_block .section_title {
    font-size: 30px;
    text-align: left;
    margin-bottom: 28px; }
    @media screen and (max-width: 1023px) {
      .product_accessories_block .section_title {
        font-size: 26px; } }
    @media screen and (max-width: 767px) {
      .product_accessories_block .section_title {
        text-align: center;
        font-size: 18px;
        margin-bottom: 15px; } }

.product_accessories {
  padding: 0 100px; }
  @media screen and (max-width: 1023px) {
    .product_accessories {
      padding: 0; } }
  .product_accessories .product_accessories_item {
    max-width: 49%; }
  .product_accessories.owl-carousel .product_accessories_item {
    max-width: unset; }
  .product_accessories_bottom {
    padding-right: 10px; }
    @media screen and (max-width: 1023px) {
      .product_accessories_bottom {
        padding-right: 0; }
        .product_accessories_bottom.flex {
          align-items: flex-end; } }
  .product_accessories_item {
    padding: 20px;
    background-color: #fff;
    border-radius: 3px;
    position: relative;
    height: 100%; }
    @media screen and (max-width: 1023px) {
      .product_accessories_item {
        padding: 10px; } }
    .product_accessories_item_img {
      display: block;
      width: 90px !important;
      min-width: 90px;
      margin-right: 20px;
      border-radius: 3px; }
      @media screen and (max-width: 767px) {
        .product_accessories_item_img {
          width: 60px !important;
          min-width: 60px;
          margin-right: 10px; } }
    .product_accessories_item_right {
      width: 100%;
      flex-direction: column;
      padding: 5px 0; }
      @media screen and (max-width: 1023px) {
        .product_accessories_item_right {
          padding: 0; } }
    .product_accessories_item_price {
      font-size: 20px;
      font-weight: 700; }
      @media screen and (max-width: 1023px) {
        .product_accessories_item_price {
          font-size: 18px; } }
      @media screen and (max-width: 767px) {
        .product_accessories_item_price {
          font-size: 16px; } }
    .product_accessories_item_btn {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: #ed1111;
      padding: 0;
      box-shadow: 1px 3px 0 0 #af0404; }
      @media screen and (max-width: 767px) {
        .product_accessories_item_btn {
          width: 40px;
          height: 40px;
          box-shadow: 0 2px 0 0 #af0404; } }
      .product_accessories_item_btn .svg_icon {
        width: 20px;
        height: 17px;
        fill: #fff; }
  .product_accessories .owl-nav {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .product_accessories .owl-nav .owl-prev, .product_accessories .owl-nav .owl-next {
      position: absolute;
      top: 0;
      height: 100%; }
    .product_accessories .owl-nav .owl-prev {
      left: 0; }
    .product_accessories .owl-nav .owl-next {
      right: 0; }
  .product_accessories .owl-stage-outer {
    z-index: 2; }
  .product_accessories .owl-stage {
    display: flex; }

.product_option {
  width: auto !important;
  display: inline-flex;
  align-items: center;
  font-size: 18px; }
  .product_option:not(:last-child) {
    margin-right: 10px; }
  .product_option_title {
    font-size: 20px;
    margin-bottom: 6px; }

.cart {
  padding-top: 50px;
  padding-bottom: 30px; }
  @media screen and (max-width: 1023px) {
    .cart {
      padding-top: 40px; } }

.msCart_header {
  padding: 14px 30px;
  background-color: #002061;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 10px; }
  @media screen and (max-width: 1023px) {
    .msCart_header {
      padding: 15px; } }
  @media screen and (max-width: 767px) {
    .msCart_header {
      display: none; } }

.msCart .text_center {
  text-align: center; }

.msCart_number {
  width: 6%; }
  @media screen and (max-width: 1023px) {
    .msCart_number {
      display: none; } }

.msCart_title {
  width: 36%; }
  @media screen and (max-width: 1023px) {
    .msCart_title {
      text-align: left !important;
      width: 42%; } }

.msCart_price {
  width: 23%; }
  @media screen and (max-width: 1023px) {
    .msCart_price {
      width: 21%; } }

.msCart_count {
  width: 15%; }
  @media screen and (max-width: 1023px) {
    .msCart_count {
      width: 17%; } }

.msCart_total {
  width: 20%; }

.msCart_item {
  border-radius: 5px;
  border: 1px solid #e8edf6;
  background-color: #fff;
  padding: 0 30px;
  margin-bottom: 20px; }
  @media screen and (max-width: 1023px) {
    .msCart_item {
      padding: 0 15px;
      margin-bottom: 10px; } }
  @media screen and (max-width: 767px) {
    .msCart_item {
      padding: 0 10px;
      position: relative; } }
  .msCart_item_top {
    padding: 15px 0;
    border-bottom: 1px solid #e8edf6; }
    .msCart_item_top.accessory {
      border: none; }
    @media screen and (max-width: 767px) {
      .msCart_item_top {
        padding: 10px 0;
        flex-wrap: wrap; } }
  .msCart_item_bottom {
    padding: 15px 0; }
    @media screen and (max-width: 767px) {
      .msCart_item_bottom {
        padding: 12px 0; } }
    .msCart_item_bottom .link {
      color: #00bff3;
      font-size: 14px; }
      @media screen and (max-width: 767px) {
        .msCart_item_bottom .link {
          font-size: 12px; } }
      .msCart_item_bottom .link .plus {
        position: relative;
        width: 12px;
        height: 12px;
        margin-right: 8px; }
        .msCart_item_bottom .link .plus:before, .msCart_item_bottom .link .plus:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 3px;
          background-color: #00bff3;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%; }
        .msCart_item_bottom .link .plus:after {
          height: 12px;
          width: 3px; }
  .msCart_item_link {
    border: 1px solid #e8edf6;
    border-radius: 5px;
    padding: 12px 20px;
    width: 9%;
    margin-right: 2.2%;
    height: 136px; }
    @media screen and (max-width: 1023px) {
      .msCart_item_link {
        padding: 10px;
        width: 12%; } }
    @media screen and (max-width: 767px) {
      .msCart_item_link {
        width: 21.5%;
        margin-right: 3.5%; } }
  .msCart_item_img {
    max-height: 100%; }
  .msCart_item_info {
    width: 47.8%; }
    @media screen and (max-width: 1023px) {
      .msCart_item_info {
        width: 50.8%; } }
    @media screen and (max-width: 767px) {
      .msCart_item_info {
        width: 75%;
        font-size: 10px; }
        .msCart_item_info.flex {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; } }
    .msCart_item_info_left {
      width: 54%; }
      @media screen and (max-width: 767px) {
        .msCart_item_info_left {
          width: 100%; } }
    .msCart_item_info_right {
      width: 46%; }
      @media screen and (max-width: 767px) {
        .msCart_item_info_right {
          width: 100%; } }
      @media screen and (max-width: 767px) {
        .msCart_item_info_right {
          text-align: left;
          display: flex;
          align-items: center;
          padding-top: 4px; } }
  .msCart_item_right {
    width: 35%; }
    @media screen and (max-width: 767px) {
      .msCart_item_right {
        width: 100%;
        padding-top: 15px;
        justify-content: space-between; } }
  .msCart_item_count {
    width: 43%; }
    @media screen and (max-width: 767px) {
      .msCart_item_count {
        width: auto; } }
  .msCart_item_total {
    width: 55.5%;
    font-weight: 700;
    font-size: 20px;
    color: #ed1111; }
    @media screen and (max-width: 1023px) {
      .msCart_item_total {
        font-size: 16px; } }
    @media screen and (max-width: 767px) {
      .msCart_item_total {
        width: auto;
        font-size: 12px; } }
  .msCart_item_remove {
    width: 1.5%; }
    @media screen and (max-width: 767px) {
      .msCart_item_remove {
        position: absolute;
        width: auto;
        top: 10px;
        right: 10px; } }
    .msCart_item_remove .remove_btn {
      position: relative;
      width: 15px;
      height: 15px;
      padding: 0;
      border: 0;
      display: block;
      border-radius: 0; }
      .msCart_item_remove .remove_btn:before, .msCart_item_remove .remove_btn:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: #b9c6de;
        top: 50%;
        left: 50%;
        transform-origin: center; }
        @media screen and (max-width: 767px) {
          .msCart_item_remove .remove_btn:before, .msCart_item_remove .remove_btn:after {
            width: 12px; } }
      .msCart_item_remove .remove_btn:before {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .msCart_item_remove .remove_btn:after {
        transform: translate(-50%, -50%) rotate(45deg); }
  .msCart_item_title {
    font-weight: 700;
    margin-bottom: 12px; }
    @media screen and (max-width: 1023px) {
      .msCart_item_title {
        font-size: 16px; } }
    @media screen and (max-width: 767px) {
      .msCart_item_title {
        font-size: 12px;
        margin-bottom: 0; } }
  .msCart_item_price {
    font-weight: 700;
    font-size: 20px; }
    @media screen and (max-width: 1023px) {
      .msCart_item_price {
        font-size: 16px; } }
    @media screen and (max-width: 767px) {
      .msCart_item_price {
        font-size: 12px; } }

@media screen and (max-width: 767px) {
  .msCart_footer {
    flex-direction: column; } }

.msCart_footer .catalog_btn, .msCart_footer .add_btn {
  margin-left: 10px; }
  @media screen and (max-width: 767px) {
    .msCart_footer .catalog_btn, .msCart_footer .add_btn {
      margin-left: 0;
      width: 100%;
      margin-bottom: 8px; } }

.msCart_footer .add_btn {
  margin-left: 20px; }
  @media screen and (max-width: 767px) {
    .msCart_footer .add_btn {
      width: 100%;
      margin-left: 0; } }

.msCart_footer_total {
  font-size: 18px; }
  @media screen and (max-width: 1023px) {
    .msCart_footer_total {
      font-size: 16px; } }
  @media screen and (max-width: 767px) {
    .msCart_footer_total {
      margin-bottom: 10px; } }

.msCart_footer_count {
  font-weight: 700;
  color: #ed1111; }

.msCart_footer_price {
  font-size: 20px;
  font-weight: 700;
  color: #ed1111; }
  @media screen and (max-width: 1023px) {
    .msCart_footer_price {
      font-size: 16px; } }

.msCart_clean {
  text-align: right;
  padding-right: 32px;
  margin-bottom: 20px; }
  @media screen and (max-width: 1023px) {
    .msCart_clean {
      padding-top: 10px; } }
  @media screen and (max-width: 767px) {
    .msCart_clean {
      padding-top: 0;
      padding-right: 10px;
      margin-bottom: 12px; } }
  .msCart_clean .btn_clean {
    height: auto;
    padding: 0;
    color: #ed1111;
    text-decoration: underline;
    border: none;
    border-radius: 0;
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .msCart_clean .btn_clean {
        font-size: 12px; } }
    .msCart_clean .btn_clean:focus {
      outline: none; }
    .msCart_clean .btn_clean_add {
      width: 10px;
      height: 10px;
      position: relative;
      margin-left: 6px; }
      .msCart_clean .btn_clean_add:before, .msCart_clean .btn_clean_add:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 1px;
        background-color: #ed1111;
        top: 50%;
        left: 50%;
        transform-origin: center; }
      .msCart_clean .btn_clean_add:before {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .msCart_clean .btn_clean_add:after {
        transform: translate(-50%, -50%) rotate(45deg); }

.cart_accessories {
  width: 100%;
  max-height: 656px;
  height: auto;
  padding: 0;
  background-color: #e8edf6; }
  @media screen and (max-width: 1200px) {
    .cart_accessories {
      max-height: 500px; } }
  @media screen and (max-width: 767px) {
    .cart_accessories {
      max-height: 400px; } }
  .cart_accessories_wrapper {
    max-width: 90%;
    width: 800px;
    padding: 0;
    overflow: hidden;
    border-radius: 5px;
    background-color: #fff; }
    .cart_accessories_wrapper [data-fancybox-close] {
      display: none; }
  .cart_accessories .accessories_item {
    width: 100%;
    margin: 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #e8edf6;
    position: relative;
    flex-direction: row; }
    .cart_accessories .accessories_item_bottom {
      width: 100%; }
      @media screen and (max-width: 767px) {
        .cart_accessories .accessories_item_bottom {
          flex-wrap: wrap; }
          .cart_accessories .accessories_item_bottom .catalog_btn, .cart_accessories .accessories_item_bottom .add_btn {
            width: 100%;
            margin-top: 12px;
            margin-left: 0 !important; } }
    .cart_accessories .accessories_item_title {
      margin-bottom: 16px; }
      @media screen and (max-width: 767px) {
        .cart_accessories .accessories_item_title {
          margin-bottom: 2px; } }
    .cart_accessories .accessories_item_desc {
      flex: 1 0 auto; }
    @media screen and (max-width: 1200px) {
      .cart_accessories .accessories_item_block_img {
        left: 10px;
        top: 10px;
        margin-right: 15px; } }
    .cart_accessories .accessories_item .catalog_btn, .cart_accessories .accessories_item .add_btn {
      margin-left: 20px; }
  .cart_accessories .mCSB_container {
    margin-right: 20px; }
  .cart_accessories .mCSB_scrollTools {
    width: 20px; }
  .cart_accessories .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .cart_accessories .mCSB_scrollTools .mCSB_draggerRail {
    width: 6px; }
  .cart_accessories .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .cart_accessories .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover, .cart_accessories .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
  .cart_accessories .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .cart_accessories .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
    background-color: #002061; }

#msOrder {
  margin-top: 0; }

.order {
  padding-top: 50px;
  padding-bottom: 60px;
  counter-reset: order; }
  @media screen and (max-width: 1023px) {
    .order {
      padding-top: 40px;
      padding-bottom: 50px; }
      .order_form {
        flex-wrap: wrap; } }
  @media screen and (max-width: 767px) {
    .order {
      padding-top: 24px; } }
  .order_left {
    width: 66.1%; }
    @media screen and (max-width: 1023px) {
      .order_left {
        width: 100%; } }
  .order_title {
    padding: 12px 20px;
    background-color: #f1f6ff;
    border-radius: 5px;
    margin-bottom: 25px;
    font-weight: 700;
    counter-increment: order; }
    @media screen and (max-width: 1023px) {
      .order_title {
        font-size: 16px; } }
    @media screen and (max-width: 767px) {
      .order_title {
        font-size: 12px;
        margin-bottom: 10px;
        padding: 9px 15px; } }
    .order_title:before {
      content: "0" counter(order) ".";
      font-family: 'Bebas Neue', sans-serif;
      font-weight: 700;
      font-size: 26px;
      margin-right: 15px;
      line-height: 1;
      margin-top: 5px; }
      @media screen and (max-width: 767px) {
        .order_title:before {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .order_title:before {
          margin-right: 8px;
          margin-top: 2px; } }
  .order_block {
    padding: 0 20px 15px;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      .order_block {
        padding: 0 0 10px; } }
    .order_block .input_label {
      margin-bottom: 15px;
      width: 48.5%; }
      @media screen and (max-width: 767px) {
        .order_block .input_label {
          margin-bottom: 10px;
          width: 100%; } }
  .order .input_label select {
    margin-top: 0; }
  .order .accessories_item_select .jq-selectbox__select {
    width: 100%;
    border: 1px solid #b1bfda; }
  .order .input.jq-selectbox {
    height: auto;
    border-radius: 0;
    border: none;
    padding: 0;
    background-color: transparent;
    width: 100%; }
  .order .required-star {
    color: #ed1111 !important; }
  .order_right {
    width: 32.2%;
    background-color: #f1f6ff;
    border-radius: 5px;
    flex-direction: column;
    padding: 16px 20px 32px; }
    @media screen and (max-width: 1023px) {
      .order_right {
        width: 100%; } }
    @media screen and (max-width: 767px) {
      .order_right {
        padding: 16px 10px; } }
    @media screen and (max-width: 1023px) {
      .order_right_bottom {
        text-align: right; } }
    .order_right_bottom .order_total_block {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px; }
      @media screen and (max-width: 1023px) {
        .order_right_bottom .order_total_block {
          font-size: 18px; } }
      .order_right_bottom .order_total_block:before {
        bottom: 9px; }
        @media screen and (max-width: 1023px) {
          .order_right_bottom .order_total_block:before {
            bottom: 7px; } }
      .order_right_bottom .order_total_block_item.left {
        text-transform: uppercase; }
      .order_right_bottom .order_total_block_item.red {
        color: #ed1111; }
    .order_right_bottom .catalog_btn, .order_right_bottom .add_btn {
      width: 100%; }
      @media screen and (max-width: 1023px) {
        .order_right_bottom .catalog_btn, .order_right_bottom .add_btn {
          width: 236px; } }
      @media screen and (max-width: 767px) {
        .order_right_bottom .catalog_btn, .order_right_bottom .add_btn {
          width: 100%; } }
  .order_cart_title {
    font-weight: 700;
    margin-bottom: 24px; }
    @media screen and (max-width: 1023px) {
      .order_cart_title {
        font-size: 16px; } }
    @media screen and (max-width: 767px) {
      .order_cart_title {
        margin-bottom: 10px; } }
  .order_cart_count {
    margin-bottom: 10px; }
    @media screen and (max-width: 767px) {
      .order_cart_count {
        font-size: 10px; } }
  .order_cart .msCart {
    font-size: 14px; }
    @media screen and (max-width: 767px) {
      .order_cart .msCart {
        font-size: 12px; } }
  .order_cart .miniCart_item {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dae3f4; }
    @media screen and (max-width: 767px) {
      .order_cart .miniCart_item {
        margin-bottom: 10px;
        padding-bottom: 10px; }
        .order_cart .miniCart_item.flex {
          align-items: center; } }
    .order_cart .miniCart_item:last-child {
      margin-bottom: 0; }
    .order_cart .miniCart_item_link {
      background-color: #fff; }
      @media screen and (max-width: 767px) {
        .order_cart .miniCart_item_link {
          margin-right: 10px; } }
  .order_total {
    padding: 22px 0;
    border-bottom: 1px solid #dae3f4;
    margin-bottom: 25px; }
    @media screen and (max-width: 767px) {
      .order_total {
        padding: 12px 0;
        margin-bottom: 12px; } }
    .order_total_block {
      position: relative;
      margin-bottom: 10px; }
      @media screen and (max-width: 1023px) {
        .order_total_block b {
          font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .order_total_block {
          font-size: 10px;
          margin-bottom: 4px; }
          .order_total_block b {
            font-size: 12px; } }
      .order_total_block:last-child {
        margin-bottom: 0; }
      .order_total_block:before {
        content: '';
        width: 100%;
        height: 0;
        left: 0;
        bottom: 6px;
        position: absolute;
        border-bottom: 2px dotted #a3b7db; }
        @media screen and (max-width: 1023px) {
          .order_total_block:before {
            bottom: 5px; } }
        @media screen and (max-width: 767px) {
          .order_total_block:before {
            bottom: 4px; } }
      .order_total_block_item {
        background-color: #f1f6ff;
        position: relative;
        z-index: 1; }
  .order_get {
    text-align: center; }
    .order_get:before {
      content: '';
      display: inline-block;
      width: 80px;
      height: 80px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-bottom: 34px;
      background-image: url(../img/checked.svg); }
    .order_get_title {
      margin-bottom: 24px;
      font-size: 30px;
      font-weight: 700; }
      .order_get_title .red {
        color: #ed1111; }
    .order_get_desc {
      margin-bottom: 50px; }
    .order_get .link {
      color: #00bff3; }
      .order_get .link .svg_icon {
        width: 21px;
        height: 10px;
        fill: #00bff3;
        margin-left: 10px; }

@media screen and (max-width: 767px) {
  .catalog_filter_wrapper {
    position: relative; }
  .catalog_filter_item_active {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #00143e;
    height: 40px;
    color: #fff;
    cursor: pointer;
    margin-bottom: 10px; }
    .catalog_filter_item_active:after {
      content: '';
      width: 6px;
      height: 6px;
      margin-left: 4px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg); }
  .catalog_filter_block {
    display: none;
    position: absolute;
    left: 0;
    top: 45px;
    width: 100% !important;
    border-radius: 10px;
    background-color: #dce7fb;
    z-index: 5;
    padding: 5px 0; }
    .catalog_filter_block .catalog_filter_item {
      margin-bottom: 0; }
    .catalog_filter_block .catalog_filter_text {
      background-color: transparent !important;
      color: #00143e !important;
      padding: 10px 0; } }

.content_text {
  padding: 50px 0;
  background-color: #f1f6ff; }
  @media screen and (max-width: 1023px) {
    .content_text {
      padding: 30px 0 40px; } }
  @media screen and (max-width: 767px) {
    .content_text {
      padding: 20px 0 30px; } }

.error_page {
  padding: 40px 0;
  text-align: center; }
  @media screen and (max-width: 1023px) {
    .error_page {
      padding: 20px 0; } }
  .error_page:before {
    content: '';
    display: inline-block;
    width: 530px;
    height: 188px;
    max-width: 90%;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../img/404.png); }
    @media screen and (max-width: 767px) {
      .error_page:before {
        height: 140px;
        margin-bottom: 20px; } }
  .error_page .h2 {
    margin-bottom: 18px; }
  .error_page .text {
    margin-bottom: 24px; }
  .error_page .link {
    color: #00bff3;
    font-size: 14px; }

.error_file {
  color: #ed1111;
  padding-top: 6px; }

.conf_block {
  text-align: left;
  font-size: 14px;
  margin-bottom: 14px;
  position: relative;
  padding-left: 24px;
  display: block;
  cursor: pointer; }

.conf_checkbox {
  position: absolute;
  width: 2px;
  height: 2px;
  top: 5px;
  left: 5px;
  opacity: 0;
  text-align: center; }
  .conf_checkbox:checked + .conf_check:before {
    content: '\2714';
    color: #00143e;
    font: 16px/15px Arial; }

.conf_check {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 2px solid #00143e;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 1px; }
  .conf_check:before {
    content: ''; }

.mCSB_container ul li:hover {
  color: #ed1111; }

.ms2_delivery_cost.no_currency + span {
  display: none; }

.normalize-styles ol {
  padding-left: 16px; }
