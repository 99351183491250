$text_color: #00143e;
$red: #ed1111;
$blue: #002062;

@font-face {
  font-family: 'ProximaNova';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ProximaNova-Regular.eot');
  src: local('ProximaNova'),
       url('../fonts/ProximaNova-Regular.woff') format('woff'),
       url('../fonts/ProximaNova-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/ProximaNova-Bold.eot');
  src: local('ProximaNova'),
       url('../fonts/ProximaNova-Bold.woff') format('woff'),
       url('../fonts/ProximaNova-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/ProximaNova-Bold.eot');
  src: local('ProximaNova'),
       url('../fonts/ProximaNova-Bold.woff') format('woff'),
       url('../fonts/ProximaNova-Bold.woff') format('woff'),
       url('../fonts/ProximaNova-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Bebas Neue';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: local('Bebas Neue Bold'), 
         local('BebasNeueBold'), 
         url('../fonts/bebasneuebold.woff2') format('woff2'), 
         url('../fonts/bebasneuebold.woff') format('woff'), 
         url('../fonts/bebasneuebold.ttf') format('truetype');
}

*, *:before, *:after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html,
body {
	height: 100%;
}
.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: 143px;
    @include media_laptop {
        padding-top: 141px;
    }
    @include media_tablet {
        padding-top: 67px;
    }
    @include media_phone {
        padding-top: 53px;
    }
}

body.active .mainPage .op {
    opacity: 0;
}

.animated {
    animation-duration: 0.7s
}

.header_block {
   flex: 0 0 auto;
   z-index: 15;
   position: relative;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
}
.content {
	flex: 1 0 auto;
}
.footer_block {
	flex: 0 0 auto;
}

body {
    font-family: 'ProximaNova', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.4;
    color: $text_color;
    @include media_tablet {
        font-size: 14px;
    }
    @include media_phone {
        font-size: 12px;
    }
}

.container {
    display: block;
    width: 100%;
    max-width: none;
    padding: 0 calc(50% - 590px);
    @include media_laptop {
        padding: 0 10px;
    }
}

.flex {
    display: flex;
    &.jsb {
        justify-content: space-between;
    }
    &.jfs {
        justify-content: flex-start;
    }
    &.jfe {
        justify-content: flex-end;
    }
    &.jc {
        justify-content: center;
    }
    &.ac {
        align-items: center;
    }
    &.afs {
        align-items: flex-start;
    }
    &.afe {
        align-items: flex-end;
    }
}

.inline_flex {
    display: inline-flex;
    &.jsb {
        justify-content: space-between;
    }
    &.jfs {
        justify-content: flex-start;
    }
    &.jfe {
        justify-content: flex-end;
    }
    &.jc {
        justify-content: center;
    }
    &.ac {
        align-items: center;
    }
    &.afs {
        align-items: flex-start;
    }
    &.afe {
        align-items: flex-end;
    }
}

p, ul:not([class]), ul:not([class]) li:not([class]) {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    @include media_tablet {
        margin-bottom: 16px;
    }
    @include media_phone {
        margin-bottom: 14px;
    }
}

ul:not([class]) {
    list-style: none;
    padding: 0;
    li:not([class]) {
        padding-left: 16px;
        position: relative;
        &:before {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            background-color: $text_color;
            left: 0;
            top: 8px;
            @include media_tablet {
                top: 7px;
            }
            @include media_phone {
                top: 4px;
            }
        }
    }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .section_title {
    font-weight: 700;
    line-height: 1.4;
    color: $text_color;
}

img {
    max-width: 100%;
}

.section_title {
    margin-bottom: 35px;
    line-height: 1.15;
    text-align: center;
    @include media_phone {
        margin-bottom: 20px;
    }
}

h1, .h1, .section_title {
    font-size: 40px;
    @include media_tablet {
        font-size: 26px;
    }
    @include media_phone {
        font-size: 18px;
    }
}

.h1, h1 {
    margin: 0;
}

h2, .h2 {
    font-size: 30px;
    @include media_tablet {
        font-size: 26px;
    }
    @include media_phone {
        font-size: 18px;
    }
}

h3, .h3 {
    font-size: 24px;
    text-transform: uppercase;
    @include media_tablet {
        font-size: 22px;
    }
}

h4, .h4 {
    font-size: 18px;
    @include media_tablet {
        font-size: 16px;
    }
}

h5, h6,
.h5, .h6 {
    font-size: 16px;
    @include media_tablet {
        font-size: 14px;
    }
}

.text_center {
    text-align: center;
}

button {
    cursor: pointer;
    text-align: center;
    height: 44px;
    border-radius: 22px;
    border: 1px solid $red;
    display: inline-block;
    padding: 0 20px;
    background-color: transparent;
}

.jGrowl-close {
    height: auto;
    width: auto;
    padding: 0;
}

.standard_btn {
    @include inline-flex;
    height: 44px;
    border-radius: 22px;
    border: 1px solid $red;
    min-width: 158px;
    max-width: 100%;
    font-weight: 400;
    color: $red;
    background-color: transparent;
    transition: all 0.4s;
    padding: 0 14px;
    &:hover, &:focus, &:active {
        background-color: $red;
        color: #fff;
    }
    &:focus, &:active {
        box-shadow: inset 0 2px 7px rgba(111, 13, 13, 0.77);
        outline: none;
    }
}

a.standard_btn {
    text-decoration: none;
}

.catalog_btn {
    position: relative;
    background-color: transparent;
    border: none;
    border-radius: 22px;
    text-decoration: none;
    display: inline-block;
    transition: all 0.4s;
    padding: 0;
    min-width: 200px;
    max-width: 100%;
    @include media_phone {
        min-width: 160px;
    }
    &:before {
        content: '';
        position: absolute;
        width: calc(100% - 2px);
        height: 100%;
        top: 3px;
        left: 1px;
        background-color: #af0404;
        border-radius: 22px;
        transition: all 0.4s;
    }
    &_text {
        position: relative;
        @include inline-flex;
        padding: 0 20px;
        background-color: $red;
        z-index: 1;
        width: 100%;
        height: 44px;
        border-radius: 22px;
        color: #fff;
        font-weight: 700;
        transition: all 0.4s;
    }
    &:hover, &:active, &:focus {
        &:before {
            background-color: #cf2f10;
        }
        .catalog_btn_text {
            background-color: #ff5534;
        }
    }
    &:active, &:focus {
        outline: none;
        .catalog_btn_text {
            box-shadow: inset 0 2px 7px rgba(152, 43, 22, 0.77);
        }
    }
}

.square_btn {
    border-radius: 5px;
    border: 1px solid $blue;
    padding: 0 20px;
    height: 44px;
    color: $blue;
    min-width: 190px;
    max-width: 100%;
    text-decoration: none;
    @include inline-flex;
    transition: all 0.5s;
    @include media_tablet {
        min-width: auto;
    }
    &:hover, &:focus, &:active {
        background-color: $blue;
        color: #fff;
        outline: none;
    }
    &:hover {
        
    }
}

.add_btn {
    @extend .catalog_btn;
    min-width: 230px;
    max-width: 100%;
    &:before {
        background-color: #e8edf6;
    }
    &_text {
        position: relative;
        @include inline-flex;
        padding: 0 20px;
        background-color: #f4f8ff;
        z-index: 1;
        width: 100%;
        height: 44px;
        border-radius: 22px;
        color: $text_color;
        font-weight: 700;
        transition: all 0.4s;
        border: 1px solid #e8edf6;
    }
    &:hover, &:active, &:focus {
        &:before {
            background-color: #bfcee3;
        }
        .add_btn_text {
            background-color: #e5edf9;
            border: 1px solid #dae2ef;
        }
    }
    &:active, &:focus {
        outline: none;
        .add_btn_text {
            box-shadow: inset 0 2px 7px rgba(189, 189, 214, 0.65);
        }
    }
}

a {
    text-decoration: underline;
    color: $red;
    text-decoration: underline;
    transition: all 0.4s;
    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}

.input {
    height: 44px;
    border-radius: 5px;
    border: 1px solid #dae3f4;
    background-color: #fff;
    width: 100%;
    padding: 0 20px;
    color: $text_color;
    transition: all 0.4s;
    @include media_phone {
        padding: 0 15px;
        height: 40px;
    }
    &::placeholder {
        color: #8a99ba;
    }
    &:focus, &:valid {
        border: 1px solid #002061;
        outline: none;
    }
}

.input_label {
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-bottom: 16px;
    position: relative;
    @include media_phone {
        font-size: 10px;
    }
    .done {
        position: absolute;
        width: 9px;
        height: 5px;
        transform: rotate(-45deg);
        border-bottom: 2px solid #0dac50;
        border-left: 2px solid #0dac50;
        bottom: 20px;
        right: 20px;
        opacity: 0;
        transition: opacity 0.4s;
    }
    .input {
        margin-top: 6px;
        @include media_phone {
            font-size: 12px;
        }
        &:valid + .done {
            opacity: 1;
        }
    }
    &.done {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 9px;
            height: 5px;
            transform: rotate(-45deg);
            border-bottom: 2px solid #0dac50;
            border-left: 2px solid #0dac50;
            bottom: 20px;
            right: 20px;
        }
    }
}

.jq-file.input_file {
    @include flex;
    border: 1px dashed #002061;
    background-color: #ebf2ff;
    height: 44px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 6px;
    &:before {
        content: '';
        @include bg;
        background-image: url(../img/plus_circle.svg);
        width: 13px;
        height: 13px;
        margin-right: 10px;
    }
    .input_file {
        cursor: pointer;
    }
    .jq-file__browse {
        display: none;
    }
    .jq-file__name {
        cursor: pointer;
        text-decoration: underline;
        font-size: 14px;
    }
}

.close_x {
    width: 15px;
    height: 15px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:before, &:after {
        content: '';
        width: 20px;
        height: 2px;
        background-color: #b9c6de;
        transition: all 0.4s;
        position: absolute;
        top: 6px;
        left: -2px;
        transform: rotate(45deg);
    }
    &:before {
        transform: rotate(-45deg);
    }
    &:hover, &:focus {
        &:before, &:after {
            background-color: $red;
        }
    }
}

.warning {
    border: 1px solid #c2d0ea;
    border-radius: 5px;
    padding: 44px 50px 40px;
    background-color: #fff;
    position: relative;
    @include media_tablet {
        padding: 40px 18px 20px;
    }
    @include media_phone {
        padding: 20px 10px 10px;
    }
    &_add {
        background-color: #fff;
        color: $red;
        padding: 0 14px;
        font-weight: 700;
        position: absolute;
        top: -16px;
        left: 50px;
        @include media_phone {
            top: -10px;
            padding: 0 7px;
            left: 42px;
        }
        .svg_img {
            width: 40px;
            height: 40px;
            margin-right: 15px;
            @include media_phone {
                width: 25px;
                height: 25px;
            }
        }
        &_text {
            padding-bottom: 8px;
            @include media_phone {
                padding-bottom: 3px;
            }
        }
    }
}

.jq-number:not(.miniCart_item_count) {
    display: inline-flex;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 1px;
        height: 100%;
        width: calc(100% - 2px);
        background-color: #e8edf6;
        border-radius: 22px;
    }
    .input_number {
        width: 48px;
        height: 44px;
        border: none;
        border-top: 1px solid #e8edf6;
        border-bottom: 1px solid #e8edf6;
        text-align: center;
        font-weight: 700;
        -moz-appearance: textfield;
        position: relative;
        transition: all 0.4s;
        font-size: 16px;
        @include media_phone {
            font-size: 14px;
        }
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
        	margin: 0;
        	-webkit-appearance: none;
        }
        &:focus {
            outline: none;
        }
    }
    .jq-number__spin {
        border: 1px solid #e8edf6;
        background-color: #f4f8ff;
        font-weight: 700;
        cursor: pointer;
        position: relative;
        @include inline-flex;
        width: 36px;
        transition: all 0.4s;
        &:before {
            color: $text_color;
            font-size: 16px;
            font-weight: 700;
        }
        &.minus {
            &:before {
                content: '-';
            }
            order: -1;
            border-radius: 22px 0 0 22px;
        }
        &.plus {
            &:before {
                content: '+';
            }
            border-radius: 0 22px 22px 0;
        }
        &:hover {
            background-color: #ffffff;
        }
    }
}

table {
    border-spacing: 0;
    font-size: 14px;
    th {
        padding: 16px;
        background-color: #f1f6ff;
        border: 1px solid #e8edf6;
        border-left: 0;
        @include media_tablet {
            padding: 13px;
        }
        &:first-child {
            border-left: 1px solid #e8edf6;
        }
        &:last-child {
            text-align: left;
        }
    }
    td {
        border-right: 1px solid #e8edf6;
        border-bottom: 1px solid #e8edf6;
        text-align: left;
        padding: 12px 16px;
        &:first-child {
            border-left: 1px solid #e8edf6;
            text-align: center;
        }
        &:last-child {
            text-align: center;
        }
        @include media_phone {
            font-size: 10px;
            padding: 10px;
        }
    }
}

@keyframes burgerSpan {
    0% {
        background-color: $blue;
    }
    99% {
        background-color: $blue;
    }
    100% {
        background-color: transparent;
    }
}

@keyframes burgerSpanBefore {
    0% {
        top: -12px;
        transform: rotate(0);
    }

    50% {
        top: 0;
        transform: rotate(0);
    }

    100% {
        top: 0;
        transform: rotate(45deg);
    }
}

@keyframes burgerSpanAfter {
    0% {
        bottom: -12px;
        transform: rotate(0);
    }

    50% {
        bottom: 0;
        transform: rotate(0);
    }

    100% {
        bottom: 0;
        transform: rotate(-45deg);
    }
}

@keyframes closeBurgerSpan {
    0% {
        background-color: transparent;
    }
    99% {
        background-color: $blue;
    }
    100% {
        background-color: $blue;
    }
}

@keyframes closeBurgerSpanBefore {
    0% {
        top: 0;
        transform: rotate(45deg);
    }

    50% {
        top: 0;
        transform: rotate(0);
    }

    100% {
        top: -12px;
        transform: rotate(0);
    }
}

@keyframes closeBurgerSpanAfter {
    0% {
        bottom: 0;
        transform: rotate(-45deg);
    }

    50% {
        bottom: 0;
        transform: rotate(0);
    }

    100% {
        bottom: -12px;
        transform: rotate(0);
    }
}

.burger_btn {
    display: none;
    width: 44px;
    height: 27px;
    position: relative;
    @include media_tablet {
        order: 2;
        display: flex;
    }
    @include media_phone {
        width: 34px;
        height: 25px;
    }
    .span {
        position: relative;

        width: 100%;
        height: 3px;
        background-color: $blue;

        transition: 0.5s;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: $blue;
            left: 0;

            transition: 0.5s;
        }
        &:before {
            top: -12px;
        }
        &:after {
            bottom: -12px;
        }
    }
    &.active {
        .span {
            animation: burgerSpan 0.2s forwards;

            &:before {
                animation: burgerSpanBefore 0.4s forwards;
            }

            &:after {
                animation: burgerSpanAfter 0.4s forwards;
            }
        }
    }

    &.closeMenu {
        .span {
            animation: closeBurgerSpan 0.2s forwards;

            &:before {
                animation: closeBurgerSpanBefore 0.4s forwards;
            }

            &:after {
                animation: closeBurgerSpanAfter 0.4s forwards;
            }
        }
    }
}

@keyframes dots {
    0% {
        opacity: 0;
    }
    
    50% {
        opacity: 0;
    }
    
    100% {
        opacity: 1;
    }
}

@keyframes circle {
    0% {
        bottom: 100%;
        right: -90%;
    }
    
    100% {
        right: -50.8%;
        bottom: -25.2%;
    }
}

.cssload-thecube {
	width: 73px;
	height: 73px;
	margin: 0 auto;
	margin-top: 49px;
	position: relative;
	transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	position: relative;
	transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(237,17,17);
	animation: cssload-fold-thecube 2.76s infinite linear both;
	transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
	transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
	transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
	transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
	animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
	animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
	animation-delay: 1.04s;
}

input[name="clear"], input[name="second_name"] {
    display: none;
}



@keyframes cssload-fold-thecube {
	0%, 10% {
		transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}
