@mixin ib() {
    vertical-align: top;
    display: inline-block;
    letter-spacing: normal;
}

@mixin flex($justify: center, $align: center) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin inline-flex($justify: center, $align: center) {
    display: inline-flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin bg($size: contain, $position: center, $repeat: no-repeat) {
    background-repeat: $repeat;
    background-position: $position;
    background-size: $size;
}

@mixin media_laptop {
    @media screen and (max-width: 1200px) {
        @content;
    }
}
@mixin media_tablet {
    @media screen and (max-width: 1023px) {
        @content;
    }
}
@mixin media_phone {
    @media screen and (max-width: 767px) {
        @content;
    }
}